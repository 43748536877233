import React, { Component, Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';

import { Button, Form, FormGroup } from 'reactstrap';
import InputField from '../InputField/InputField';
import Avatar from '../Avatar/Avatar';
import Loading from '../Loading/Loading';
import Typography from '../Typography/Typography';
import Lang from '../../api/Lang';

class CommentsInput extends Component {
    render(){
        const { value, message, requesting, error, handleChange, inputId, feedback, valid, placeholder } = this.props;
        return (
            <AuthConsumer>
                {({isAuth, user})=> (
                    <Form onSubmit={this.props.handleSubmit}>
                        <div className="ci-item">
                            <div className='ci-avatar'>
                                {user && <Avatar size="md" name={user.name} url={user.img_url} variant="primary" />}
                            </div>
                            <div className='ci-content'>
                                <FormGroup>
                                    <InputField value={value} handleChangeInput={handleChange} inputId={inputId} id={inputId} type="A" placeholder={placeholder} valid={valid} message={message} feedback={feedback} disabled={requesting}/>
                                </FormGroup>
                                <FormGroup>
                                    {error !== '' && <Typography variant="strong" color="danger">{error}</Typography>}
                                </FormGroup>
                                <FormGroup>
                                    <Button type="submit" color="danger" disabled={requesting}>
                                    {requesting ? <Fragment>
                                      <Loading variant="component" color="white"/> {Lang.get("COMMENT.PUBLISHING")}
                                      </Fragment> : <Fragment>{Lang.get("COMMENT.PUBLISH")}</Fragment>}
                                    </Button>
                                </FormGroup>
                            </div>
                        </div>
                    </Form>
                )}
            </AuthConsumer>

        );
    };
};

export default CommentsInput;
