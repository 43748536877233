import React, { Component } from 'react';

import XServices from '../../api/XServices';
import Loading from '../Loading/Loading';
import ContentNotFound from './ContentNotFound';
import { Constants } from '../../config/Constants';

class DynamicContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            content: null,
            style: null,
            script: null,
            type: null,
        };
        this.getContent = this.getContent.bind(this);
    }

    componentDidMount() {
        let {type} = this.props;
        if (this.isValidPage(type)) {
            this.getContent(type);
        }
    }

    componentDidUpdate() {
        if (this.state.type !== this.props.type) {
            this.getContent(this.props.type);
        }
    }

    getContent(type) {
        this.setState({
            loading: true,
            error: null,
            type: type
        });
        XServices.get('content', {
            type: type
        },
        response => {
            this.setState({
                loading: false,
                content: response.content,
                style: response.style,
                script: response.script,
                error: null
            }, () => {
                let element = document.getElementById("content-" + this.props.type);
                let first = element.firstElementChild; 

                while (first) { 
                    first.remove(); 
                    first = element.firstElementChild; 
                } 

                if (this.state.style !== null && this.state.style !== '') {
                    let elementStyle = document.createElement("STYLE");
                    elementStyle.type = 'text/css';
                    elementStyle.appendChild(document.createTextNode(this.state.style));
                    element.append(elementStyle);
                }

                if (this.state.script !== null && this.state.script !== '') {
                    var elementScript = document.createElement("SCRIPT");
                    elementScript.text = this.state.script;
                    element.append(elementScript);
                }

                var elementContent = document.createElement("div");
                elementContent.innerHTML = response.content;
                element.append(elementContent);
            });

        }, error => {
            this.setState({
                loading: false,
                content: '',
                error: { code: error.code, message: error.message }
            });
        });
    }

    isValidPage(type) {
        return Constants.PAGES.indexOf(type) !== -1;
    }

    render() {   
        let { loading, error } = this.state;
        let { type } = this.props;
        return (
            <div  className="content-body">
                {this.isValidPage(type) ? 
                (loading ? 
                    <Loading variant="fullscreen" /> :
                    ( error == null ?
                        <div id={"content-" + type}></div> 
                        : <ContentNotFound/>)
                    ) : 
                <ContentNotFound/>
                }
            </div>
        );
    }
};

export default DynamicContent;