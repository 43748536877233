import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

class SimpleMap extends Component {

    render() {
        const { center, zoom, nameNotary } = this.props;
        const style = {
            width: '96%',
            height: '300px'
        }
        return (
            <div className='simple-maps-wrapper'>
                <Map
                    google={this.props.google}
                    zoom={zoom}
                    initialCenter={{
                        lat: center.lat,
                        lng: center.lng
                    }}
                    style={style}>
                    <Marker position={{ lat: center.lat, lng: center.lng }} title={nameNotary} />
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({ apiKey: ('AIzaSyA4ptR81yvUTdGe81B_j-Vnqh4x7oVjho8'), version: 3.31, language: 'es' })(SimpleMap);