export const messages = {
	"es": {
		"HOME.SLOGAN": "Somos la revolución en servicios jurídicos",
		"HOME.FOOT.1": "Precios Bajos",
		"HOME.FOOT.2": "Ahorra tiempo",
		"HOME.FOOT.3": "Experiencia",
		"HOME.MENU.PRINCIPAL.US": "Nosotros",
		"HOME.MENU.PRINCIPAL.CATEGORY": "Categorías",
		"HOME.MENU.PRINCIPAL.CONTACT": "Contacto",
		"HOME.MENU.PRINCIPAL.CONTRACT": "Trámites",
		"HOME.STEPS.1": "Elige el contrato que necesitas.",
		"HOME.STEPS.2": "Completa el formulario, realiza la compra y te será asignado un abogado.",
		"HOME.STEPS.3": "Sigue las instrucciones para su ejecución.",
		"HOME.MESSAGESLIDER": "Oportunidades que te podrían interesar",
		"FOOTER.PEOPLE": "Personas",
		"FOOTER.US": "Nosotros",
		"FOOTER.US_ITEM": "Quienes somos",
		"FOOTER.REGISTER": "Regístrate",
		"FOOTER.SPEEDUP": "Agiliza tus trámites",
		"FOOTER.JOINUS": "Únete a nosotros",
		"FOOTER.MONETIZE": "Monetiza tu tiempo",
		"FOOTER.PRESSROOM": "Sala de prensa",
		"FOOTER.OPORTUNITIES": "Oportunidades",
		"FOOTER.PRIVACY": "Privacidad",
		"FOOTER.TERMS": "Términos y Condiciones",
		"FOOTER.COUNTRY.CL": "Chile",
		"FOOTER.FAQ": "Preguntas frecuentes",
		"FOOTER.LANGUAGE.ES": "Español (Internacional)",
		"COMMON.LOADING_PLEASE_WAIT": "Cargando por favor espere...",
		"SOCIAL.REDIRECT": "Por favor espere mientras lo redireccionamos de vuelta al inicio",
		"SOCIAL.ERROR.GOHOME.LINK": "Volver al inicio!",
		"SOCIAL.ERROR.200.TITLE": "Ha ocurrido un error inesperado",
		"SOCIAL.ERROR.201.TITLE": "Error de autorización",
		"SOCIAL.ERROR.202.TITLE": "Error de inicio de sesión",
		"SOCIAL.ERROR.500.TITLE": "Ha ocurrido un error inesperado",
		"SOCIAL.ERROR.200.MESSAGE": "Se ha registrado un error al intentar comunicar con el servicio. Por favor, intente de nuevo más tarde.",
		"SOCIAL.ERROR.201.MESSAGE": "El usuario no ha autorizado los permisos requeridos para iniciar sesión",
		"SOCIAL.ERROR.202.MESSAGE": "El usuario no se encuentra habilitado para iniciar sesión",
		"SOCIAL.ERROR.500.MESSAGE": "Se ha registrado un error inesperado al iniciar sesión. Por favor, intente de nuevo más tarde",
		"FILEUPLOAD.SELECT": "Seleccionar archivos...",
		"FILEUPLOAD.UPLOADING": "Subiendo archivo...",
		"CATEGORY.TAGS": "Etiquetas: ",
		"CATEGORY.REQUEST": "Solicitar",
		"CATEGORY.TITLE.MENU": "Categorías",
		"CATEGORY.ERROR": "Por favor, seleccione una de las categorías disponibles en el menú.",
		"CATEGORY.TITLE.HEADER": "Trámites",
		"CATEGORY.DESCRIPTION.HEADER.ALL": "Mostrando trámites de todas las categorías",
		"CATEGORY.DESCRIPTION.HEADER.SELECTED": "Mostrando trámites de las categorías:",
		"CATEGORY.TOTALFOUND": "Se han encontrado un total de {0} trámites distintos.",
		"CATEGORY.CATEGORY.NOTFOUND": "No se ha encontrado la categoría seleccionada.",
		"CATEGORY.TASK.NOTFOUND": "No se han encontrador trámites para la categoría seleccionada.",
		"CATEGORY.TASKITEM.PRICE": "Tarifa {0}: ",
		"CATEGORY.TASKITEM.PRICE.ALT": "Precio: ",
		//"CATEGORY.TASKITEM.PRICE": "Tarifa {0}: {1}.",
		"CATEGORY.MOST.REQUESTED": "Más Utilizadas",
		"CATEGORY.MOST.REQUESTED.DESCRIPTION": "Éstas son las categorías preferidas por nuestros clientes para realizar sus trámites",
		"CATEGORY.FEATURED": "Destacadas",
		"CATEGORY.FEATURED.DESCRIPTION": "Éstas son las categorías que consideramos podrían ser de tu interés",
		"CATEGORY.ALLCONTRACT": "Ir a ver todos los trámites",
		"SIDEMENU.REQUEST": "Solicitudes",
		"SIDEMENU.REQUESTED": "Mis Solicitudes",
		"SIDEMENU.AVAILABLE": "Disponibles",
		"SIDEMENU.ASSIGN": "Asignadas",
		"SIDEMENU.SETTING": "Configuración",
		"SIDEMENU.MYACCOUNT": "Mi Cuenta",
		"SIDEMENU.NOTIFICACION": "Notificaciones",
		"SIDEMENU.LOGOUT": "Salir",
		"LOGIN.TITLE": "Ingresar a mi cuenta",
		"LOGIN.SUBTITLE": "Ingresar",
		"LOGIN.EMAIL": "Email",
		"LOGIN.SOCIAL": "O ingresa con:",
		"LOGIN.PASSWORD": "Contraseña",
		"LOGIN.REMEMBERME": "Recordarme",
		"LOGIN.FORGOTPASSWORD": "Olvidé mi contraseña",
		"LOGIN.ENTER": "Ingresar!",
		"LOGIN.VALIDATING": "Validando",
		"LOGIN.DONTHAVEACCOUNT": "¿Todavía no tienes una cuenta?",
		"REGISTER.TITLE": "Regístrate",
		"REGISTER.REGISTERING": "Registrando",
		"REGISTER.ENTER": "Comienza Ahora!",
		"REGISTER.NAME": "Nombre",
		"REGISTER.ALREADYHAVEACCOUNT": "¿Ya tienes una cuenta?",
		"FORGOT.TITLE": "Olvidé mi contraseña",
		"FORGOT.RECOVER": "Recuperar!",
		"FORGOT.SUBTITLE": "Ingresa tu email para recuperar la contraseña",
		"FORGOT.DONTHAVEACCOUNT": "¿Todavía no tienes una cuenta?",
		"FORGOT.REGISTER": "Regístrate",
		"FORGOT.ENTER": "Ingresar",
		"FORGOT.ALREADYHAVEACCOUNT": "¿Ya tienes una cuenta?",
		"FORGOT.SUCESS.A": "Se ha enviado un correo electrónico a ",
		"FORGOT.SUCESS.B": " con el enlace para restablecer su contraseña. Si no aparece dentro de unos minutos, por favor, revise la carpeta de spam.",
		"FORGOT.BUTTON.SAVE": "Guardar",
		"FORGOT.REPEAT.PASS": "Repetir Contraseña",
		"FORGOT.NEW.PASS": "Nueva Contraseña",
		"FORGOT.INGRESS.NEW.PASS": "Ingresa una nueva contraseña para tu cuenta.",
		"FORGOT.RECOVER.TITLE": "Recuperar contraseña",
		"ACOOUNT.TITLE": "La solicitud ha expirado",
		"ACOOUNT.MESSAGE": "Por favor intente nuevamente restablecer su contraseña",
		"ACOOUNT.LINK": "Inicio",
		"ACOOUNT.CLOSE": "Cerrar",
		"ACOOUNT.UPGRADE.SUCESS": "Se ha actualizado con éxito su contraseña",
		"ACOOUNT.UPGRADE.NOOK": "Ha ocurrido un error inesperado",
		"ACOOUNT.UPGRADE.NOOK.MESSAGE": "Se ha registrado un error al intentar comunicar con el servicio. Por favor, intente de nuevo más tarde.",
        "ACOOUNT.ERR.RESPONSE": "Error en respuesta del servicio. Por favor, intente de nuevo",
		"REQUEST.NOTE": "Nota",
		"REQUEST.NOTE.INPUT": "Responde las siguientes preguntas del cuestionario.",
		"REQUEST.NOTE.FILE": "La solicitud requiere los siguientes antecedentes. Puede subir estos documentos ahora o una vez creada la solicitud. ",
		"REQUEST.NOTE.FILE.REQUIRELOGIN": "Para subir archivos debe haber ingresado con su cuenta (más abajo en el formulario)",
		"ACCOUNTALLREQUEST.SEARCH": "buscar por...",
		"ACCOUNTALLREQUEST.CHECK.NORMAL": "Normal",
		"ACCOUNTALLREQUEST.CHECK.PLUS": "Plus",
		"ACCOUNTALLNOTIFICATIONS.CHECK.EVENT": "Eventos",
		"ACCOUNTALLNOTIFICATIONS.CHECK.ACTIVITY": "Actividad",
		"PROFILE.REQUESTFINISHED":"Cantidad de trabajos realizados.",
		"PROFILE.PUBLISHED":"Publicado el: ",
		"PROFILE.ASSESSMENTS.LEFT":"Más de ",
		"PROFILE.ASSESSMENTS.RIGTH":" valoraciones",
		"PROFILE.MORECOMMENTS":"Más comentarios",
		"REQUEST.SELECTPRICEMODE": "Selecciona tipo de trámite.",
		"REQUEST.SELECTPRICEMODE.DESCRIPTION": "Cada tipo de trámite tiene distintas características y precios asociados. Elige el que mejor se acomode a tus necesidades.",
		"REQUEST.DISCLAIMER.GUARANTEE.TITLE": "Garantía REVU:",
		"REQUEST.DISCLAIMER.GUARANTEE.DESCRIPTION": "El dinero que estás pagando se libera para el abogado 7 días después de que te entregue el contrato terminado.",
		"REQUEST.DISCLAIMER.GUARANTEE.FLOW": "Flow es nuestro partner tecnológico a través del cual recibimos tu pago, es 100% seguro. Para mayor información ingresa a ",
		"REQUEST.DISCLAIMER.GUARANTEE.FLOW.URL.TEXT": "www.flow.cl",
		"REQUEST.DISCLAIMER.GUARANTEE.FLOW.URL": "https://www.flow.cl",
		"REQUEST.PAYREQUEST": "Solicitud",
		"REQUEST.PAYTOTAL": "total a pagar:",
		"REQUEST.LASTUPDATE": "Actualizado",
		"REQUEST.PAY": "Pagar",
		"REQUEST.ACCEPTTERMS": "Acepto los términos y condiciones de servicio",
		"REQUEST.PAYPROGRESS": "Creando solicitud",
		"REQUEST.ONLYCLIENTS": "Solo Clientes",
		"REQUEST_NOUSER_ASSIGN": "Ningún abogado ha tomado aún la solicitud",
		"REQUEST.LOADING": "Cargando solicitud #{0}",
		"REQUEST.DOCUMENT.READYTOSEND": "Documento subido:",
		"REQUEST.DOCUMENT.LASTUPLOADED": "Último documento adjunto:",
		"REQUEST.DOCUMENT.SENT": "Enviar",
		"REQUEST.DOCUMENT.SENDING": "Enviando",
		"REQUEST.DOCUMENT.GENERATE": "Generar documento",
		"REQUEST.DOCUMENT.GENERATE.DESCRIPTION": "El sistema generará un borrador del documento solicitado",
		"REQUEST.DOCUMENT.GENERATE.DOWNLOAD": "Descargar último documento generado...",
		"REQUEST.DOCUMENT.UPLOAD": "Subir documento",
		"REQUEST.DOCUMENT.UPLOAD.DESCRIPTION": "Se subirá al sistema el documento solicitado",
		"REQUEST.FIELD.LASTUPDATE": "actualizado hace: ",
		"REQUEST.FIELD.NEWVALUE": "Ingresar valor",
		"REQUEST.FIELD.EDITVALUE": "Editar",
		"REQUEST.FIELD.EDITVALUE.DESCRIPTION": "Ingrese el nuevo valor del campo",
		"REQUEST.FIELD.REQUESTMODIFICATION": "Solicitar modificación",
		"REQUEST.FIELD.REQUESTMODIFICATION.DESCRIPTION": "Ingrese el comentario asociado a la modificación",
		"REQUEST.FIELD.REGION.TITLE": "Región",
		"REQUEST.FIELD.REGION.DESCRIPTION": "Seleccione la región en que se encuentra la notaría",
		"REQUEST.FIELD.COMUNES.TITLE": "Comuna",
		"REQUEST.FIELD.COMUNES.DESCRIPTION": "Seleccione la comuina en que se encuentra la notaría",
		"REQUEST.FIELD.NOTARIES.TITLE": "Notarías",
		"REQUEST.FIELD.NOTARIES.DESCRIPTION": "Seleccione la notaría en que desea llevar a cabo su trámite",
		"REQUEST.NOTARY.SELECT": "¿Desea seleccionar una notaría para llevar a cabo su solicitud?",
		"REQUEST.NOTARY.SELECT.NONEEDED": "Su solicitud no requiere de una notaría. Puede realizar una firma digital simple sobre su documento y este será válido legalmente",
		"REQUEST.NOTARY.SELECT.NO": "No",
		"REQUEST.NOTARY.SELECT.YES": "Si",
		"REQUEST.NOTARY.SELECT.TOOLTIP.TITLE": "Notaría",
		"REQUEST.NOTARY.SELECT.TOOLTIP.DESCRIPTION": "Indica si desea llevar a cabo su solicitud en alguna notaría específica",
		"REQUEST.SIGN.TITLE": "Información",
		"REQUEST.PAY.SUCCESS.CONGRATS": "Excelente {0}",
		"REQUEST.PAY.SUCCESS.YOURPAYMENT": "tu pago ha sido ",
		"REQUEST.PAY.SUCCESS.YOURPAYMENTSUCCESS": "exitoso :)",
		"REQUEST.PAY.SUCCESS.CHECKLINK": "Revisar mi trámite #{0}",
		"REQUEST.PAY.SUCCESS.ID": "Solicitud #{0}",
		"REQUEST.PAY.SUCCESS.THANKS": "Gracias {0} por preferir ",
		"REQUEST.PAY.SUCCESS.GOHOME.TITLE": "Verifica el estado de tu trámite en la sección Mi Cuenta o puedes volver al inicio presionando el siguiente botón.",
		"REQUEST.PAY.SUCCESS.GOHOME.LINK": "Volver al Inicio!",
		"REQUEST.PAY.ERROR.CONGRATS": "Estimado {0}",
		"REQUEST.PAY.ERROR.YOURPAYMENT": "tu pago ha sido ",
		"REQUEST.PAY.ERROR.YOURPAYMENTERROR": "erróneo :(",
		"REQUEST.PAY.ERROR.MESSAGE": "Gracias {0} por preferir ",
		"REQUEST.PAY.ERROR.PAYAGAIN": "De todas formas su solicitud ha sido almacenada correctamente, puede intentar el pago nuevamente en la sección Solicitadas.",
        "REQUEST.PAY.ERROR.GOHOME.TITLE": "Verifica el estado de tu trámite en la sección Mi Cuenta o puedes volver al inicio presionando el siguiente botón.",
        "REQUEST.UNSUBSCRIBE.CONFIRMATION": "¿Está seguro que desea dejar de recibir e-mails de la solicitud #{0}? ",
        "REQUEST.UNSUBSCRIBE.INVALIDTOKEN": "El token es inválido",
        "REQUEST.UNSUBSCRIBE.TRYAGAIN": "Por favor, intentelo de nuevo con otro enlace",
        "REQUEST.UNSUBSCRIBE.SUCCESS": "Se ha desuscrito exitosamente de la solicitud",
        "REQUEST.UNSUBSCRIBE.SUCCESSEXTRA": "No volverá a recibir notificaciones por e-mail de la misma",
        "REQUEST.UNSUBSCRIBE.CANCELED": "Ha cancelado la desuscripción",
        "REQUEST.UNSUBSCRIBE.CANCELEDEXTRA": "Seguirá recibiendo notificaciones por e-mail",
		"TOCLOSE.DOCUMENT.UPLOAD.DESCRIPTION": "Se subirá al sistema el documento requerido",
		"TOCLOSE.DESCRIPTION": "Descripción:",
		"TOCLOSE.INPUT.PLACEHOLDER": "Escriba una descripción de su solicitud.",
		"TOCLOSE.TITLE": "Petición de cierre de solicitud finalizada",
		"TOCLOSE.BTN.CLOSE": "Cerrar",
		"TOCLOSE.BTN.CANCEL": "Cancelar",
		"TOCLOSE.BTN.ACCEPT": "Aceptar",
		"TOCLOSE.BTN.FILEUPLOADED": "Documento adjunto:",
		"HELP.TITLE": "Estamos aquí para ayudarte",
		"HELP.DESCRIPTION1": "Obtén soporte con sólo un par de clicks.",
		"HELP.CONTACT": "También puedes llamarnos por teléfono, chatear con nosotros por whastapp o contactarnos vía email",
		"HELP.PHONE": "Teléfono",
		"HELP.WHATSAPP": "WhatsApp",
		"HELP.EMAIL": "Email",
		"COMMENT.NOMESSAGE": "No se ha enviado ningún mensaje",
		"COMMENT.PLACEHOLDER": "Deja un comentario...",
		"COMMENT.RANKREQUIRED": "La puntuación es requerida",
		"COMMENT.PUBLISHING": "Publicando",
		"COMMENT.PUBLISH": "Publicar",
		"SEARCH.HELP": "¿No sabes cómo se llama tu trámite?",
		"SEARCH.PLACEHOLDER": "Buscar trámite...",
		"SEARCH.ENTER": "Buscar",
		"SEARCH.VIEWALL": "Ver todos",
		"SEARCH.SHOWALL": "Mostrar todos los resultados",
		"SEARCH.NORESULTS": "No hay resultados con esta búsqueda :(",
		"SEARCH.MINSEARCHLENGTH": "Debe ingresar al menos {0} caracteres",
		"RANKMODAL.QUESTION": "¿Cómo calificarías a ",
		"RANKMODAL.ERROR.FORM": "Ocurrió un error al enviar el formulario, por favor, intente nuevamente",
		"LISTREQUESTITEM.FINALIZED": "Finalizado",
		"LISTREQUESTITEM.TIME.FINALIZED": "Tiempo finalizado",
		"LISTREQUESTITEM.DAYS": "días",
		"LISTREQUESTITEM.CREATED": "Creado el ",
		"LISTREQUESTITEM.ESTIMATED.TIME": "Tiempo estimado: ",
		"LISTREQUESTITEM.ESTATE": "Estado: ",
		"LISTREQUESTITEM.TIME.REMAINING": "Tiempo restante.",
		"LISTREQUESTITEM.RANK": "Calificación",
		"LISTREQUESTITEM.BUTTON.TOASSIGN": "Asignar!",
		"LISTREQUESTITEM.BUTTON.TOPAID": "Pagar!",
		"LISTREQUESTITEM.BUTTON.TOCHECK": "Verificar pago!",
		"LISTREQUESTITEM.BUTTON.TORANK": "Calificar!",
		"LISTREQUESTITEM.BUTTON.CLOSE": "Cerrar solicitud",
		"LISTREQUESTITEM.BUTTON.TOCLOSE": "Solicitar cierre",
		"LISTREQUESTITEM.AVAILABLE": "No asignado.",
		"LISTREQUESTITEM.UNINICIATED": "No iniciado",
		"LISTREQUESTITEM.WAIT.RANK": "En espera de calificación",
		"LISTREQUESTITEM.CLOSE.REQUEST": "Petición cierre realizada",
		"LISTREQUESTITEM.CHECKREQUEST": "Revisar solicitud",
		"REQUESTSINPROGRESS.TITLE": "Solicitudes en progreso:",
		"REQUESTSINPROGRESS.ATENTION": "Requiere tu atención",
		"REQUESTSINPROGRESS.WAIT.CLIENT": "A la espera de cliente",
		"TIMER.TITLE1": "Tiempo restante:",
		"TIMER.TITLE2": "Tiempo adicional:",
		"TIMERWARRANTY.TITLE": "Tiempo máximo de revisión:",
		"NOTIFY.TITLE": "Notificaciones:",
		"NOTIFY.MORE": "Más...",
		"NOTIFY.NOFOUND": "No hay notificaciones",
		"NOTIFICATION.ID": "Notificación: ",
		"NOTIFICATION.TITLE": "{0}",
		"NOTIFICATION.REQUESTID": "Solicitud #",
		"NOTIFICATION.ACCTION": "  ha realizado la siguiente acción.",
		"NOTIFICATION.TYPE.COM": "Nuevos comentarios",
		"NOTIFICATION.TYPE.RFD": "Solicitud de antecedente",
		"NOTIFICATION.TYPE.RFC": "Solicitud de cambio de dato",
		"NOTIFICATION.TYPE.RFE": "Antecedente modificado",
		"NOTIFICATION.TYPE.RAS": "Solicitud asignada",
		"NOTIFICATION.TYPE.RST": "Cambio de estado",
		"NOTIFICATION.TYPE.SYS": "Notificación de sistema",
		"NOTIFICATION.TYPE.RPQ": "Solicitud de revisión de documento",
		"NOTIFICATION.TYPE.RVC": "Revisión realizada",
		"NOTIFICATION.TYPE.RCD": "Solicitud cerrada",
		"NOTIFICATION.YOUHAVE": "Tiene ",
		"NOTIFICATION.NOTIFICATION": " nueva notificación",
		"NOTIFICATION.NOTIFICATIONS": " nuevas notificaciones",
		"REQUESTSINPROGRESS.NOFOUND": "Sin otras solicitudes.",
		"SEARCHBACKGROUND.NOFOUND": "No encontramos ningún antecedente con: ",
		"SEARCHBACKGROUND.NOFIELD": "No hay más antecedentes disponibles",
		"SEARCHBACKGROUND.CREATECUSTOM": "¿Pedir un antecedente nuevo?",
		"SEARCHBACKGROUND.CREATED": "Antecedentes que solicitaré",
		"SEARCHBACKGROUND.DELETE": "Para eliminar pinche el icono de borrar antes de guardar sus cambios.",
		"SEARCHBACKGROUND.NOELEMENTS": "Debe tener elementos en la lista antes de guardar sus cambios.",
		"SEARCHBACKGROUND.BUTTON.BACK": "Volver",
		"SEARCHBACKGROUND.BUTTON.CANCEL": "Cancelar",
		"SEARCHBACKGROUND.BUTTON.SAVE": "Guardar",
		"MYACCOUNT.UPDATE.USER.TITLE": "Nombre",
		"MYACCOUNT.UPDATE.USER.LABEL": "Nombre que se mostrará hacia los demás usuarios.",
		"MYACCOUNT.UPDATE.AVATAR.TITLE": "Subir Avatar",
		"MYACCOUNT.UPDATE.AVATAR.LABEL": "Imagen que se mostrará en tu perfil.",
		"MYACCOUNT.UPDATE.AVATAR.ERROR": "El archivo debe ser una imagen.",
		"MYACCOUNT.UPDATE.AVATAR.ERROR_MAX": "El tamaño del archivo excede el máximo permitido.",
        "MYACCOUNT.UPDATE.CHANGEPASS.LABEL": "¿Desea cambiar su contraseña?",
        "MYACCOUNT.UPDATE.TOGGLESUBSCRIPTION.LABEL": "Deseo recibir notificaciones mediante Telegram",
		"MYACCOUNT.UPDATE.CURRENTPASS.TITLE": "Contraseña actual",
		"MYACCOUNT.UPDATE.CURRENTPASS.LABEL": "Ingresar la contraseña actual",
		"MYACCOUNT.UPDATE.NEWPASS.TITLE": "Nueva contraseña",
        "MYACCOUNT.UPDATE.NEWPASS.LABEL": "Ingresar una nueva contraseña",
        "MYACCOUNT.UPDATE.TELEGRAMALIAS.TITLE": "Alias de Telegram",
        "MYACCOUNT.UPDATE.TELEGRAMALIAS.LABEL": "Ingrese su alias de Telegram",
		"FORM.GLOBAL.SAVE": "Guardar",
		"FORM.GLOBAL.REQUIRED": "Este campo es obligatorio",
		"ERROR.GLOBAL.TITLE": "Oooops!",
		"ERROR.GLOBAL.NODATA": "Lo sentimos no hemos encontrado nada bajo esta dirección!",
		"ERROR.GLOBAL.DEFAULT": "Ha ocurrido un error inesperado",
		"CONFIRM.BUTTON.QUESTION": "¿Estás Seguro(a)?",
		"CONFIRM.BUTTON.CONFIRM": "Si, Continuar",
		"CONFIRM.BUTTON.CANCEL": "No, Cancelar",
		"CONFIRM.TEXT.CONTINUE": "Debes confirmar para continuar",
		"COMMENT.BY": "Comentario por: ",
		"NEWREQUESTMODAL.BTNSHOW": "Mostrar",
		"NEWREQUESTMODAL.BOTTOM.TITLE": "Solicitud nueva",
		"NEWREQUESTMODAL.TITLE": "Existe una solicitud nueva disponible",
		"NEWREQUESTMODAL.BTN.ACCEPT": "Aceptar",
		"NEWREQUESTMODAL.BTN.DECLINED": "Declinar",
		"REVISION.PLACEHOLDER": "Describa el motivo de su revisión.",
		"REVISION.BUTTON": "Revisión",
		"REVISION.TITLE": "Solicitar revisión",
		"REVISION.SUBTITLE": " (Tiempo garantia cumplido)",
		"REVISION.BTN.CLOSE": "Cerrar",
		"REVISION.BTN.NEW": "Nuevo",
		"REVISION.BTN.CANCEL": "Cancelar",
		"REVISION.BTN.SAVE": "Guardar",
		"REVISION.DESCRIPTION.TITLE": "Observación del solicitante:",
		"NOTARIES.TITLE": "Seleccione región y comuna, para mostrar disponibles",
		"NOTARIES.NOTARY": "Notaría:",
		"NOTARIES.NOTARIES": "Notarías",
		"NOTARIES.EMPTY": "No se encontraron notarías en la comuna seleccionada",
		"DISPONIBILITY.TEXT1": "Su Trámite estará disponible",
		"DISPONIBILITY.DAY": "A partir del: ",
		"DISPONIBILITY.REQUEST.CONTACT": "¿Desea que lo pongamos en contacto con la notaría?",
		"DISPONIBILITY.REQUEST.ACTION": "Contactar",
		"DISPONIBILITY.REQUEST.SUCCESS": "Se han enviado la solicitud de contacto a los administradores. Estos se pondrán en contacto con la notaría para designar a una persona. Una vez que tengan los datos de contacto podrá verlos en esta misma sección.",
		"DISPONIBILITY.REQUEST.FAIL": "Ha ocurrido un error al enviar la solicitud de contacto a los administradores. Intente de nuevo más tarde",
		"DISPONIBILITY.TITLE": "Información",
		"DISPONIBILITY.PHONE": "Teléfono",
		"DISPONIBILITY.CONTACT": "La persona de contacto en la notaría es <b>{0}</b> (<a href=\"mailto:{1}\">{1}</a>)",
		"VALIDATE.ERROR.DEFAULT":  "Hay errores al validar el campo",
		"VALIDATE.ERROR.REQUIRED":  "Este campo es obligatorio.",
		"VALIDATE.ERROR.EMAIL":  "Por favor, escribe una dirección de correo válida.",
		"VALIDATE.ERROR.URL":  "Por favor, escribe una URL válida.",
		"VALIDATE.ERROR.MAXLENGTH":  "Por favor, no escribas más de {0} caracteres.",
		"VALIDATE.ERROR.MINLENGTH":  "Por favor, no escribas menos de {0} caracteres.",
		"VALIDATE.ERROR.RANGELENGTH":  "Por favor, escribe un valor entre {0} y {1} caracteres.",
		"VALIDATE.ERROR.NUMBER":  "Por favor, escribe un número válido.",
		"VALIDATE.ERROR.NUMBERWITHDASH":  "Por favor, escribe un número de rubro válido.",
		"VALIDATE.ERROR.PASSPORTFORMAT":  "Por favor, escribe un sólo dígitos y/o letras.",
		"VALIDATE.ERROR.DECIMAL":  "Por favor, escribe un número válido.",
		"VALIDATE.ERROR.CURRENCYCLP":  "Por favor, escribe un monto válido.",
		"VALIDATE.ERROR.DIGITS":  "Por favor, escribe sólo dígitos.",
		"VALIDATE.ERROR.RUT":  "Por favor, escribe un rut válido",
		"VALIDATE.ERROR.DATE":  "Por favor, escribe una fecha válida (dd-mm-yyyy)",
		"VALIDATE.ERROR.EQUAL":  "La constraseña no coincide",
        "VALIDATE.ERROR.PASSWORD":  "Por favor, ingrese al menos una mayúscula, una minúscula, un número y entre {0} y {1} caracteres.",
        "VALIDATE.ERROR.TELEGRAM_ALIAS": "El alias puede ir con letras, números o _",
		"BREADCRUMB.MYREQUEST": "Volver a Mis solicitudes",
		"BREADCRUMB.MYASSIGN": "Volver a Asignadas",
		"BREADCRUMB.REQUEST": "Solicitud #{0}",
		"ASSIGN.CONGRATS": "Felicitaciones!",
		"ASSIGN.CONGRATS.TEXT": "Para comenzar a trabajar en la solicitud ",
		"ASSIGN.CONGRATS.LINK": "haga click aquí",
		"CHAT.TITLE": "Deja un mensaje a tu ",
		"CHAT.LAWYER": "Abogado",
		"CHAT.CLIENT": "Cliente",
		"ACTION_BUTTON_ACCEPT": "Aceptar",
		"ACTION_BUTTON_CANCEL": "Cancelar",
		"ACTION_BUTTON_CANCEL_ANTECEDENT": "Eliminar antecedente",
		"ACTION_BUTTON_RESPONSE_ANTECEDENT": "Responder",
		"ACTION_BUTTON_EDIT": "Editar",
		"ACTION_BUTTON_COMMENT": "Comentar",
		"HELPTOOLTIP.WORKS": "¿Te ha servido esta ayuda?",
		"HELPTOOLTIP.MOREINFO": "¿Necesitas más información?",
		"PAGE.NOTFOUND": "La página a la que intenta acceder no existe.",
		"CONTACT.FORM.TITLE": "Contacta a nuestro equipo",
		"CONTACT.FORM.SUBTITLE": "Nos complace responder a sus preguntas.",
		"CONTACT.FORM.NAME": "Nombre",
		"CONTACT.FORM.EMAIL": "Email",
		"CONTACT.FORM.PHONE": "Teléfono",
		"CONTACT.FORM.TYPE": "Asunto",
		"CONTACT.FORM.MESSAGE": "Mensage",
		"CONTACT.FORM.SUBMIT": "ENVIAR",
		"CONTACT.FORM.WSP": "WhatsApp",
		"CONTACT.BOX.TITLE": "¿Necesitas Ayuda?",
		"CONTACT.BOX.MESSAGE": "Para ayuda acerca de la plataforma. Puedes revisar nuestra sección de",
		"CONTACT.BOX.MESSAGE_ALT": "Si tienes otros tipos de dudas, nos puedes contactar por medio de nuestros canales de comunicación",
		"CONTACT.SUCCESS.TITLE": "Mensaje enviado correctamente",
		"CONTACT.SUCCESS.SUBTITLE": "Tu solicitud de información ha sido enviada.",
		"CONTACT.SUCCESS.INFO": "Nos pondremos en contacto contigo lo antes posible.",
		"CONTACT.ERROR.TITLE": "Lo Sentimos",
		"CONTACT.ERROR.SUBTITLE": "No podemos procesar su solicitud, Inténtelo de nuevo más tarde",
		"AREA.SAVETIME.TITLE": "Ahorra tiempo",
		"AREA.SAVETIME.SUBTITLE": "No más demora.",
		"AREA.SAVETIME.CONTENT": "El tiempo hoy en día es un bien escaso. Nuestros abogados buscan satisfacer tus necesidades jurídicas en el menor tiempo posible, sin trabas ni demoras innecesarias. Es por esto que como Revu nos comprometemos a finalizar tus requerimientos legales en 5 días. Sí, leíste bien ¡5 días!",
		"AREA.LOWPRICE.TITLE": "Precios bajos",
		"AREA.LOWPRICE.SUBTITLE": "Queremos que pagues lo justo.",
		"AREA.LOWPRICE.CONTENT": "Nuestra tecnología permite que nuestros abogados enfoquen su trabajo en agregar valor. De este modo, pagas el valor real de un servicio jurídico ¡a un precio 50% menor que el de mercado!",
		"AREA.LAWYERS.TITLE": "Abogados con experiencia",
		"AREA.LAWYERS.SUBTITLE": "Estás en las mejores manos.",
		"AREA.LAWYERS.CONTENT": "En Revu estamos comprometidos con la excelencia. Sabemos que la calidad es importante para ti; por ello nuestros abogados han sido seleccionados con los más altos estándares, para brinda el mejor servicio.",
		"REQUEST.NOTE.NOTARY":"Revu no es una notaría. Es posible que requieras la firma de un notario para su validez.",
		"REQUEST.NOTE.NONOTARY":"Revu no es una notaría. Recuerda que este documento no requiere de un notario para su validez.",
		"REQUEST.NOTE.SIMPLE":"Se recomienda consultar en la institución en la cual se presentará si el poder debe ser suscrito ante notario.",
		"REQUEST.NOTE.DEFAULT": "Responde las siguientes preguntas del cuestionario.",
		"ACCOUNT.SUCCESS.GO.HOME.LINK": "Volver al Inicio!",
	},
	"en": {
	}
};
