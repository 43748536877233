import React, { Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';

//Components
import { Button, CustomInput } from 'reactstrap';
import Avatar from '../Avatar/Avatar';
import Loading from '../Loading/Loading';
import Typography from '../Typography/Typography';
import NewRequestPaymentItem from './NewRequestPaymentItem';
import Lang from '../../api/Lang';

const NewRequestPayment = props => {
    const { data, choice, selectedPrice, handlePay, error, inProgress, handleTerms, acceptTerms } = props;
    let selected = null;

    for (const item of data.prices) {
      if (item.id === selectedPrice) {
        selected = item;
      }
    }

    let prices = data.prices.map((item) =>
      <NewRequestPaymentItem key={item.id} data={item} choice={choice} active={selectedPrice} disabled={props.inProgress}/>
    );

    let rolRQT = '';
    return (
        <AuthConsumer>
            {({user})=> {
                if (user !== null){
                    rolRQT = user.roles.find(element => {
                        return element === 'RQT'
                    });
                }
                return (
                <div className="request-payment-wrapper">
                    <div className="request-paymen-heading">
                        <Typography variant="h5">{Lang.get("REQUEST.SELECTPRICEMODE")}</Typography>
                        <Typography variant="p">{Lang.get("REQUEST.SELECTPRICEMODE.DESCRIPTION")}</Typography>
                        <br />
                        <Typography variant="h5">{Lang.get("REQUEST.DISCLAIMER.GUARANTEE.TITLE")}</Typography>
                        <Typography variant="p">{Lang.get("REQUEST.DISCLAIMER.GUARANTEE.DESCRIPTION")}</Typography>
                    </div>
                    <div className="request-payment-area">
                        <div className="request-payment-selector">
                            {prices}
                        </div>
                        <div className="request-payment-box">
                            <div className="request-info">
                                <div>
                                    <Typography variant="h4">{Lang.get("REQUEST.PAYREQUEST")}&nbsp;{selected.name}</Typography>
                                    <Typography variant="p">{Lang.get("REQUEST.PAYTOTAL")}</Typography>
                                    <Typography variant="h2">
                                        {selected.price}
                                    </Typography>
                                </div>
                                <div>
                                    {user && <Avatar size="md" url={user.img_url} alt={user.name} name={user.name}/>}
                                </div>
                            </div>
                            {rolRQT === 'RQT' && <div className="mb-3">
                                <div>
                                    <CustomInput type="checkbox" id={data.id} onClick={handleTerms} label={Lang.get("REQUEST.ACCEPTTERMS")} readOnly={true}/>
                                </div>
                            </div>}
                            <div className="disclaimer-flow mb-2">
                                <Typography variant="strong">{Lang.get("REQUEST.DISCLAIMER.GUARANTEE.FLOW")}</Typography>
                                <a href={Lang.get("REQUEST.DISCLAIMER.GUARANTEE.FLOW.URL")} class="text-primary" target="_blank">
                                    <Typography variant="strong" color="white">{Lang.get("REQUEST.DISCLAIMER.GUARANTEE.FLOW.URL.TEXT")}</Typography>
                                </a>
                            </div>
                            <Button color="danger" size="lg" onClick={handlePay} disabled={user === null || inProgress || rolRQT !== 'RQT' || !acceptTerms}>
                                {inProgress ? <Fragment>
                                  <Loading variant="button" color="white"/>&nbsp;{Lang.get("REQUEST.PAYPROGRESS")}
                                </Fragment>:
                                rolRQT !== 'RQT' ? Lang.get("REQUEST.ONLYCLIENTS"): Lang.get("REQUEST.PAY")}
                            </Button>
                            {error && <Typography variant="p" color="danger">{props.error}</Typography>}
                        </div>
                    </div>
                </div>
            )}}
        </AuthConsumer>
    );
};

export default NewRequestPayment;
