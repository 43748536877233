import React from 'react';
import PropTypes from 'prop-types';
import Lang from '../../api/Lang';

//Ui
import Typography from '../Typography/Typography';

const Timer = props => {
    const { rest, more, hasAdditionalTime } = props;
    return (
        <div className="timer">
            <div>
                <Typography variant="small" color="muted">{Lang.get('TIMER.TITLE1')}</Typography>
                <Typography variant="h3" color="primary">{rest}</Typography>
            </div>
            {hasAdditionalTime === "true" &&
                <div>
                    <Typography variant="small" color="muted">{Lang.get('TIMER.TITLE2')}</Typography>
                    <Typography variant="h3" color="danger">{more}</Typography>
                </div>
            }
        </div>
    );
};

Timer.propTypes = {
    rest: PropTypes.string.isRequired,
    more: PropTypes.string,
    hasAdditionalTime: PropTypes.string.isRequired,
};

export default Timer;
