import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '../../components/Typography/Typography';
import { Link } from 'react-router-dom';
import Lang from '../../api/Lang';
import { Button } from 'reactstrap';

class AccountMessages extends Component {
    render() {
        return (
            <div className="account-recover-area">
                <Typography variant="h4">
                    {this.props.title}
                </Typography>
                <Typography variant="p">
                    {this.props.message}
                </Typography>
                <Button className="mt-4" color="danger" size="md" tag={Link} to={"/" + Lang.lancode}>{Lang.get("SOCIAL.ERROR.GOHOME.LINK")}</Button>
            </div>
        );
    }
};

AccountMessages.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
};

export default AccountMessages;