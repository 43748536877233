import React , { Component } from 'react';

import { FormGroup, InputGroup, Input, FormFeedback } from 'reactstrap';
import { InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const Types = [
  {
    type: 'T',
    name: 'Texto'
  },
  {
    type: 'F',
    name: 'Archivo'
  }
]

class CreateBackground extends Component {
  constructor(props) {
      super(props);

      this.state = {
        dropdownOpen: false,
        splitButtonOpen: false,
        selectedType: Types[0]
      };

      this.toggleDropDown = this.toggleDropDown.bind(this);
      this.toggleSplit = this.toggleSplit.bind(this);
      this.handleItemClick = this.handleItemClick.bind(this);
    }

    componentDidMount() {
      this.handleItemClick(this.state.selectedType);
    }

    toggleDropDown() {
      this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    toggleSplit() {
      this.setState({splitButtonOpen: !this.state.splitButtonOpen});
    }

    handleItemClick(type) {
      this.setState({selectedType: type});
      this.props.handleChangeType(type.type);
    }

    render(){
      const { selectedType } = this.state;
      const { value, valid, message } = this.props;

      let isValid = valid !== undefined ? valid : true;
      let style = {};

      if (!isValid) {
        style.display = "block";
      }

      return (
            <div className="cbitem">
                <div className="cbinput">
                <FormGroup >
                    <InputGroup>
                    <Input type="text" name="newField" onChange={this.props.handleChange} value={value} invalid={Boolean(!isValid)}/>
                    <InputGroupButtonDropdown addonType="append"
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleDropDown}>
                        <DropdownToggle caret outline color="secondary">
                            {selectedType.name}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Types.map((item,i) =>
                            <DropdownItem key={i} onClick={() => this.handleItemClick(item)}>{item.name}</DropdownItem>
                          )}
                        </DropdownMenu>
                    </InputGroupButtonDropdown>
                    </InputGroup>
                    <FormFeedback invalid={!isValid ? "invalid": null} style={style}>{message}</FormFeedback>
                </FormGroup>
                </div>
            </div>
        );
    }
};

export default CreateBackground;
