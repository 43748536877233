import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/css/swiper.css';

//resources
import { Container } from 'reactstrap';
import AreaItems from './AreaItems';

//Resources
import lawyersBestImage from '../../../resources/images/lawyers-best-2.jpeg';
import ahorraTiempo from '../../../resources/images/ahorratiempo-2.jpg';
import lowPrice from '../../../resources/images/low-price-2.jpeg';
import Lang from '../../../api/Lang';

const Areas = () => {
    const [gallerySwiper, getGallerySwiper] = useState(null);
    const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
    const gallerySwiperParams = {
        getSwiper: getGallerySwiper,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
      };
    const thumbnailSwiperParams = {
        getSwiper: getThumbnailSwiper,
        spaceBetween: 5,
        centeredSlides: true,
        slidesPerView: 4,
        slideToClickedSlide: true,
    };
    useEffect(() => {
        if (
          gallerySwiper !== null &&
          gallerySwiper.controller &&
          thumbnailSwiper !== null &&
          thumbnailSwiper.controller
        ) {
          gallerySwiper.controller.control = thumbnailSwiper;
          thumbnailSwiper.controller.control = gallerySwiper;
        }
    }, [gallerySwiper, thumbnailSwiper]);

    const DATA_AREA = [
        {
            title: Lang.get('AREA.LAWYERS.TITLE'),
            subtitle: Lang.get('AREA.LAWYERS.SUBTITLE'),
            content: Lang.get('AREA.LAWYERS.CONTENT'),
            image: lawyersBestImage,
        },
        {
            title: Lang.get('AREA.SAVETIME.TITLE'),
            subtitle: Lang.get('AREA.SAVETIME.SUBTITLE'),
            content: Lang.get('AREA.SAVETIME.CONTENT'),
            image: ahorraTiempo,
        },
        {
            title: Lang.get('AREA.LOWPRICE.TITLE'),
            subtitle: Lang.get('AREA.LOWPRICE.SUBTITLE'),
            content: Lang.get('AREA.LOWPRICE.CONTENT'),
            image: lowPrice,
        },
    ];

    return(
        <div className="area-wrapper-content">
            <div className="swiper-slides-full">
                <Swiper {...gallerySwiperParams}>
                    {DATA_AREA.map((item, i) => (
                        <div key={i} className="wrapper-content-slide">
                            <Container>
                                <AreaItems 
                                    key={i} 
                                    data={item}/>
                            </Container>
                        </div>
                    ))}
                </Swiper>
            </div>
            <div className="swiper-slides-thumbs">
                <Swiper {...thumbnailSwiperParams}>
                    {DATA_AREA.map((item, i) => (
                        <div key={i} className="wrapper-content-slide">
                            <Container>
                                <AreaItems
                                    thumbs
                                    key={i} 
                                    data={item}/>
                            </Container>
                            
                        </div>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default Areas;