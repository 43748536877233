function CustomEvents() {
  this.triggerEvent = function (element, eventName) {
    var elemento = null;
    if (typeof element === 'string') {
      elemento = document.getElementById(element);
    } else if (typeof element === 'object') {
      elemento = element;
    }

    if (elemento !== null) {
      if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent(eventName, false, true);
        elemento.dispatchEvent(evt);
      }
      else {
        elemento.fireEvent("on" + eventName);
      }
    }
  }

  this.bindEvent = function (element, eventName, eventHandler) {
    let elemento = null;
    if (typeof element === 'string') {
      elemento = document.getElementById(element);
    } else if (typeof element === 'object') {
      elemento = element;
    }

    if (elemento !== null) {
      if (elemento.addEventListener) {
        elemento.addEventListener(eventName, eventHandler, false);
      } else if (elemento.attachEvent) {
        elemento.attachEvent('on' + eventName, eventHandler);
      }
    }
  }

  this.unBindEvent = function (element, eventName, eventHandler ){
    let elemento = null;
    if (typeof element === 'string') {
      elemento = document.getElementById(element);
    } else if (typeof element === 'object') {
      elemento = element;
    }

    if (elemento !== null) {
      if (elemento.removeEventListener) {
        elemento.removeEventListener(eventName, eventHandler, false);
      } else if (elemento.detachEvent){
        element.detachEvent('on' + eventName, eventHandler);
      }
    }
  }
}

const Events = new CustomEvents();

export default Events;