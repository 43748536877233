import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import XServices from '../../api/XServices';
import Lang from '../../api/Lang';
import { AuthContext } from '../../config/AuthContext';
import Cookie from '../../api/Cookies';

//Ui
import Logo from '../../components/Logo/Logo';
import Typography from '../../components/Typography/Typography';
import Loading from '../../components/Loading/Loading';

class AccountSocial extends Component {
    constructor() {
        super();
        this.state = {
            loading: true
        }
        this.redirectHome = this.redirectHome.bind(this);
    };

    componentDidMount() {
        const getParams = this.props.location.search;
        const params = queryString.parse(getParams);

        if (params.token !== undefined && params.token !== null) {
            Cookie.set('token', params.token);

            XServices.get('/account/valid', {}, response => {
                this.context.isLogin(response);
                this.redirectHome();
            }, err => {
                this.context.logout();
                this.redirectHome();
            });
        } else {
            this.redirectHome();
        }
    }

    redirectHome() {
        // this.props.history.push('/' + Lang.lancode);
        this.props.history.push('/' + Lang.lancode + '/welcome');
    }

    render() {
        return (
            <div className="account-recover-wrapper">
                <div className="account-recover-logo">
                    <Logo variant="full" size="lg"/>
                    <div className="mt-4">
                        <Typography>{Lang.get('SOCIAL.REDIRECT')}</Typography>
                    </div>
                    <Loading variant="component" />
                </div>
            </div>
        );
    };
};
AccountSocial.contextType = AuthContext;
export default withRouter(AccountSocial);
