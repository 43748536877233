import React, { Component } from 'react';
import XServices from '../../api/XServices';

import { AuthContext } from '../../config/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const REFRESH_TIME = 60000;
var timeout;

class AssistanceBell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assitence: false
        };
        this.handleAssistence = this.handleAssistence.bind(this);
    };

    componentDidMount() {
        this.handleAssistence();
    }

    componentWillUnmount() {
        if (!this.props.isAuth || this.props.user === null){
            clearTimeout(timeout);
        }
    }

    handleAssistence() {
        let that = this;
        XServices.get('account/notifications/unseen', {
        }, function (response) {
            if (that.context.isAuth && that.context.user !== null){
                timeout = setTimeout(that.handleAssistence, REFRESH_TIME);
            }
            that.setState({
                assitence: response.hasNotificationsUnseen
            });
        }, function (error) {
            that.setState({
                assitence: "muted"
            });
        });
    }

    render() {
        const {assitence} = this.state
        return (
            <div className="bell-main">
                <FontAwesomeIcon icon={faBell} />
                 <div className={`circle ${assitence === 'S' ? 'assintence' : 'noassintence'}`}></div>
            </div>
        );
    };
};

AssistanceBell.contextType = AuthContext;
export default AssistanceBell;