import React, { Component } from 'react';
import XServices from '../../api/XServices';
import Loading from '../Loading/Loading';
import Lang from '../../api/Lang';

class TabSign extends Component {
    constructor() {
        super();
        this.state = {
            error: null,
            data: null,
            loading: false
        };
        this.handleLoadSign = this.handleLoadSign.bind(this);
    };

    componentDidMount() {
        this.handleLoadSign();
    }

    handleLoadSign = () => {
        this.setState({ loading: true });
        XServices.get('request/sign', {
            id: this.props.id,
        }, response => {
            this.setState({
                data: response,
                loading: false,
                error: null
            });
        }, error => {
            this.setState({
                data: null,
                error: error.message,
                loading: false
            });
        });
    }

    render() {
        const { data, loading} = this.state;
        return (
            <div className="sign-wrapper">
                {loading ? <Loading variant="component" color="white" /> :
                    data !== null &&
                    <div>
                        <div className="sign-title">
                            {Lang.get('REQUEST.SIGN.TITLE')}
                        </div>
                        {data.content !== undefined && data.content !== null && <div className="sign-content" dangerouslySetInnerHTML={{__html: data.content}}></div>}
                    </div>
                }
            </div>
        );
    }
}

export default TabSign;