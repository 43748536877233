import React, { Component } from 'react';
import XServices from '../../api/XServices';
import Events from '../../api/Events';
import { AuthContext } from '../../config/AuthContext';

//Component
import ShowNew from './ShowNew';
import ShowNewBottom from './ShowNewBottom';

const REFRESH_TIME = 60000;
const IDDECLINED = "IDDECLINED";
var timeout;

class SearchNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            lastShowId: null,
            modal: false,
            inBottom: false,
            isActive: false,
            requesting: false,
            message: null,
            error: false
        };
        this.handleSearchNew = this.handleSearchNew.bind(this);
        this.hasRol = this.hasRol.bind(this);
        this.handleAcceptNew = this.handleAcceptNew.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleStoreData = this.handleStoreData.bind(this);
        this.handleAShowModal = this.handleAShowModal.bind(this);
        this.closeModalAll = this.closeModalAll.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.createListeners = this.createListeners.bind(this);
        this.notifySticky = this.notifySticky.bind(this);
    };

    componentDidMount() {
        if (this.context.isAuth && this.hasRol(["WKR"], this.context.user.roles)) {
            this.handleSearchNew();
        }
        this.createListeners();
    }

    componentWillUnmount() {
        this.stopTimer();
        this.destroyListeners();
    }

    notifySticky (){
        this.handleSearchNew();
    }

    createListeners(){
        Events.bindEvent(document.getElementById('root'), 'notifySticky', this.notifySticky);
    }

    destroyListeners(){
        Events.unBindEvent(document.getElementById('root'), 'refreshList', this.refreshList);
    }

    stopTimer (){
        if (!this.props.isAuth || this.props.user === null) {
            clearTimeout(timeout);
        }
    }

    startTimer(){
        if (this.context.isAuth && this.hasRol(["WKR"], this.context.user.roles)) {
            timeout = setTimeout(this.handleSearchNew, REFRESH_TIME);
        }
    }

    handleStoreData(idNew) {
        let strIdDeclined = localStorage.getItem(IDDECLINED);
        if (strIdDeclined === null) {
            localStorage.setItem(IDDECLINED, idNew)
        } else {
            if (strIdDeclined !== idNew) {
                localStorage.setItem(IDDECLINED, idNew)
            }
        }
    }

    handleSearchNew() {
        this.setState({ isActive: true });
        XServices.get('request/search/new', {
        }, (response) => {
            let storeId = localStorage.getItem(IDDECLINED);
            if (response.rqt_id !== undefined) {
                if (storeId === null) {
                    this.setState({
                        data: response,
                        lastShowId: response.rqt_id,
                        modal: !this.state.modal ? storeId === null ? true : false : true,
                        inBottom: true
                    });
                    if (this.state.modal){ this.stopTimer();}
                } else if (storeId !== response.rqt_id.toString()) {
                    this.handleStoreData(response.rqt_id);
                    this.setState({
                        data: response,
                        lastShowId: response.rqt_id,
                        modal: false,
                        inBottom: true
                    });
                    Events.triggerEvent(document.getElementById('root'), 'refreshList');
                }else if(storeId === response.rqt_id.toString()){
                    this.setState({
                        data: response,
                        lastShowId: response.rqt_id,
                        modal: false,
                        inBottom: true
                    });
                }
            }else{
                this.setState({
                    lastShowId: '',
                    modal: false,
                    inBottom: false,
                    isActive: false
                });
            }
        }, (error) => {
            let storeId = localStorage.getItem(IDDECLINED);
            if (storeId !== null) {
                localStorage.removeItem(IDDECLINED);
            }
            this.setState({
                lastShowId: '',
                modal: false,
                isActive: false,
                inBottom: false,
            });
            Events.triggerEvent(document.getElementById('root'), 'refreshList');
        });
        this.startTimer();
    }

    hasRol(requiredRol, userRol) {
        let containsRol = false;
        for (const rol of requiredRol) {
            if (userRol.indexOf(rol) !== -1) {
                containsRol = true;
                break;
            }
        }
        return containsRol;
    };

    handleAcceptNew(idNew) {
        this.setState({ requesting: true });
        XServices.post('request/assign', {
            requestId: idNew
        }, (response) => {
            this.setState({
                inBottom: false,
                modal: true,
                requesting: false,
                lastShowId: idNew,
                error: false,
                message: response.message
            });
            localStorage.removeItem(IDDECLINED);
            this.startTimer();
            Events.triggerEvent(document.getElementById('root'), 'refreshList');
        }, (error) => {
            this.setState({ requesting: false, error: true, message: error.message });
        });
    }

    closeModal(idNew) {
        this.handleStoreData(idNew);
        this.setState({ modal: false, lastShowId: '', isActive: false, inBottom: true })
        this.startTimer();
    }

    closeModalAll() {
        this.setState({
            modal: false,
            lastShowId: '',
            isActive: false,
            inBottom: false,
            requesting: false,
            message: null,
            error: false
        });
        this.startTimer();
    }

    handleAShowModal() {
        this.setState({ inBottom: false, modal: true });
        this.stopTimer();
    }

    render() {
        const { data, modal, isActive, inBottom, error, message, requesting } = this.state
        return (
            modal ? <ShowNew
                error={error}
                message={message}
                open={modal}
                closeModalAll={this.closeModalAll}
                toggle={this.closeModal}
                handleAcceptNew={this.handleAcceptNew}
                data={data}
                isActive={isActive}
                requesting={requesting}
            />
                : inBottom && <ShowNewBottom handleAShowModal={this.handleAShowModal} data={data} />
        );
    };
};

SearchNew.contextType = AuthContext;
export default SearchNew;