import React from 'react';
import {FormGroup, Label, CustomInput } from 'reactstrap';

const BackgroundItem = props => {
    const {id, name, onClick} = props;
    return (
        <div className="bg-item" onClick={onClick}>
            <FormGroup check inline>
                <Label check>
                    <div className="bg-text">
                        <CustomInput type="checkbox" id={id} label={name} />
                    </div>
                </Label>
            </FormGroup>
        </div>
    );
};

export default BackgroundItem;
