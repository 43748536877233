import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import Typography from '../Typography/Typography';
import { Link } from 'react-router-dom';
import Lang from '../../api/Lang';

class RequestAlert extends Component {
    constructor(props) {
    super(props);
        this.state = {
            visible: true
        };
        this.onDismiss = this.onDismiss.bind(this);
    };
    onDismiss() {
        this.setState({ visible: false });
        if (this.props.onDismiss !== undefined) {
          this.props.onDismiss(this.props.id);
        }
    };
    render(){
        const {type, message, close, variant, id, handleClick} = this.props;
        let color = type == 'success' ? 'success' : 'danger';
        let link = type === 'success' ? <Fragment>{Lang.get('ASSIGN.CONGRATS.TEXT')}<Link to={"/" + Lang.lancode + "/request/" + id } onClick={handleClick !== undefined ? handleClick : () => {}}>{Lang.get('ASSIGN.CONGRATS.LINK')}</Link></Fragment> : '';
        return (
            <Fragment>
                {variant == 'inline' ? 
                <Typography variant="p" color={color}>{type == 'success' ? <span><b>{Lang.get('ASSIGN.CONGRATS')}</b><br></br>{message}<br></br>{link}</span> : <span>{message}</span>}</Typography> :
                <Alert color={color} className="alert-custom" isOpen={this.state.visible} toggle={close === 'none' ? null : this.onDismiss}>
                    <div className="alert-message">
                        <Typography variant="p">{type == 'success' ? <Fragment><b>{Lang.get('ASSIGN.CONGRATS')}</b>&nbsp;{message}&nbsp;{link}</Fragment> : {message}}</Typography>
                    </div>
                </Alert>
                }
            </Fragment>    
        );
    };
};

RequestAlert.propTypes = {
    type:  PropTypes.oneOf(["danger", "success", "info"]).isRequired,
    close: PropTypes.oneOf(["none"]),
    message: PropTypes.string.isRequired,
    asHTML: PropTypes.bool
}

export default RequestAlert;
