import React, { Component, Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';
import XServices from '../../api/XServices';
import Lang from '../../api/Lang';
import Validate from '../../api/Validate';

//Ui
import { Button, Form, FormGroup, Label, Col, CustomInput } from 'reactstrap';
import Typography from '../Typography/Typography';
import Loading from '../Loading/Loading';
import InputField from '../InputField/InputField';

class Login extends Component {
    constructor(){
        super();
        this.state = {
            requesting: false,
            issubmited: false,
            error: '',
            fields: [
              {
                id: 'signin-email',
                value: '',
                valid: true,
                message: '',
                validations: [
                  'required',
                  'email'
                ]
              },
              {
                id: 'signin-password',
                value: '',
                valid: true,
                message: '',
                validations: [
                  'required'
                ]
              }
            ]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hasRol = this.hasRol.bind(this);
        this.getLink = this.getLink.bind(this);
    };

    handleChange = (value, id) => {
        const fieldIndex = this.state.fields.findIndex(f => {
          return f.id === id;
        });

        let fields = [...this.state.fields];
        fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);

        this.setState({fields: fields});
        this.props.handleChange();
    };

    hasRol (requiredRol, userRol) {
        let containsRol = false;
        for (const rol of requiredRol) {
          if (userRol.indexOf(rol) !== -1) {
            containsRol = true;
            break;
          }
        }
       return containsRol;
    }

    getLink(userRol) {
        if (this.hasRol(["WKR"], userRol)) {
            window.location = "/es/assigned";
        }
    };
      

    handleSubmit = e => {
        e.preventDefault();
        let fields = this.validateForm();

        if (!this.state.issubmited) {
          this.setState({issubmited: true});
        }

        if (!Validate.validForm(fields)) {
          return;
        }

        this.setState({ requesting: true, error: ''});

        XServices.post('/account/login',
            {email: this.state.fields[0].value, password: this.state.fields[1].value},
            response => {
                this.setState({requesting: false, error: ''});
                this.context.isLogin(response);
                this.context.dispatch({action: 'close-login'});
                this.getLink(this.context.user.roles)
            },
            error => {
                this.setState({
                  requesting: false,
                  error: error.message
                });
            });
    };

    validateForm = () => {
        const fields = Validate.validateForm(this.state.fields);
        this.setState({fields: fields});
        return fields;
    }

    render(){
        const { requesting, error, fields, issubmited } = this.state;
        const { showLinkFooter} = this.props
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Label for="signin-email">{Lang.get('LOGIN.EMAIL')}</Label>
                    <InputField handleChangeInput={this.handleChange} inputId="signin-email" id="signin-email" type="T" valid={fields[0].valid || !issubmited} size="lg" message={fields[0].message} feedback={issubmited} disabled={requesting}/>
                </FormGroup>
                <FormGroup>
                    <Label for="signin-password">{Lang.get('LOGIN.PASSWORD')}</Label>
                    <InputField handleChangeInput={this.handleChange} inputId="signin-password" id="signin-password" type="P" valid={fields[1].valid || !issubmited} size="lg" message={fields[1].message} feedback={issubmited} disabled={requesting}/>
                </FormGroup>
                <FormGroup row className="form-group-center">
                    <Col>
                        <CustomInput type="checkbox" id="signin-remember" label={Lang.get('LOGIN.REMEMBERME')} />
                    </Col>
                    <Col>
                        <Button color="link" className="link" type="button" onClick={()=> this.props.handleClick('recover')}>
                            {Lang.get('LOGIN.FORGOTPASSWORD')}
                        </Button>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Button type="submit" size="lg" block color="danger" disabled={requesting}>
                    {requesting ? <Fragment><Loading variant="component" color="white"/> {Lang.get('LOGIN.VALIDATING')}</Fragment> : <Fragment>{Lang.get('LOGIN.ENTER')}</Fragment>}
                    </Button>
                    {error !== '' && <Typography variant="span" color="danger">{error}</Typography>}
                </FormGroup>
                { showLinkFooter &&
                <div className="helper-text-register">
                    <Typography variant="p">{Lang.get('LOGIN.DONTHAVEACCOUNT')}</Typography>
                    <Button color="link" type="button" onClick={()=> this.props.handleClick('register')}>
                        {Lang.get('REGISTER.TITLE')}
                    </Button>
                </div>
                }
            </Form>
        );
    };
};
Login.contextType = AuthConsumer;
export default Login;
