import React, { Component } from 'react';
import XServices from '../../api/XServices';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, Badge, CustomInput } from 'reactstrap';
import Lang from '../../api/Lang';
import { Constants } from '../../config/Constants';


class CategoryAside extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            categories: [],
            ajaxRequest: null
        };
        this.getCategories = this.getCategories.bind(this);
        this.getMenu = this.getMenu.bind(this);
    };

    componentDidMount() {
        this.getCategories();
    }
    
    componentWillUnmount () {
        if (this.state.ajaxRequest !== null) {
            this.state.ajaxRequest.cancel();
        }
    }

    getCategories() {
        if (this.state.ajaxRequest !== null) {
            this.state.ajaxRequest.cancel();
        }

        this.setState({
            loading: true,
            error: null
        });
        let ajaxRequest = XServices.get('category', {
        },
        response => {
            this.setState({
                loading: false,
                categories: response,
                ajaxRequest: null
            });
        }, error => {
            this.setState({
                loading: false,
                error: error.message,
                ajaxRequest: null
            });
        });

        this.setState({ajaxRequest: ajaxRequest});
    }

    isActive(slug) {
        var ids = this.props.match.params.id;
        if (ids !== undefined) {
            return ids.split(Constants.URL_SEPARATOR).indexOf(slug) !== -1;
        } else {
            return false;
        }
    }

    clearSlug(items, slugs) {
        var tmp = [];

        for(let i = 0; i < slugs.length; i++) {
            let slug = slugs[i];
            let existSlug = false;
            for(let j = 0; j < items.length; j++) {
                if (items[j].slug === slug) {
                    existSlug = true;
                    break;
                }
            }

            if (existSlug) {
                tmp.push(slug);
            }
        }

        return tmp;
    }

    redirect(lang, slug) {
        var ids = this.props.match.params.id;
        let url;
        if (ids !== undefined) {
            var tmp = ids.split(Constants.URL_SEPARATOR);
            if (tmp.indexOf(slug) !== -1) {
                tmp = tmp.filter(item => item !== slug);
            } else {
                tmp.push(slug);
            }
            tmp = this.clearSlug(this.state.categories, tmp);
            url = "/" + lang + '/contract/' + tmp.join(Constants.URL_SEPARATOR);
        } else {
            url = "/" + lang + '/contract/' + slug;
        }
        this.props.history.push(url);
    }

    getMenu() {
        let menu = [];
        let lang = this.props.match.params.lang;
        let counter = 0;
        menu.push((<div key={counter++} className="nav-stacked-title">{Lang.get("CATEGORY.TITLE.MENU")}</div>));
        const subitems = this.state.categories.map((item, i) => {
            return (
                <NavItem key={i}>
                    <a className={"nav-link" + (this.isActive(item.slug) ? " active" : "")} onClick={() => this.redirect(lang, item.slug)}>
                    <div>
                           <CustomInput type="checkbox" id={item.slug} checked={this.isActive(item.slug)} readOnly={true}/>
                        </div>
                        <div>
                            {Lang.get(item.name)}
                        </div>
                        <div>
                            <Badge pill color="danger">{item.total}</Badge>
                        </div>
                    </a>
                </NavItem>);
        });
        menu.push((<Nav key={counter++} vertical>{subitems}</Nav>));
        return menu;
    }

    render() {
        return (
            <div className="nav-wrapper-stacked margin-top nav-filter">
                {this.getMenu()}
            </div>
        );
    }
};

export default withRouter(CategoryAside);