import {messages} from '../resources/lang/messages.js';

function Language () {
  this.messages = null;
  this.lancode = null;
  this.supported = ["es"];
  this.default = "es";
  this.loadFromUrl = function (pathname) {
    const tmp = pathname.split('/');
    this.lancode = tmp[1] !== undefined && this.supported.indexOf(tmp[1]) !== -1 ? tmp[1] : null;
    this.load(this.lancode);
  }
  this.load = function(lancode) {
    if (messages[lancode] !== undefined) {
      this.messages = messages[lancode];
    }
  }
  this.isSupported = function (pathname) {
    const tmp = pathname.split('/');
    return tmp[1] !== undefined && this.supported.indexOf(tmp[1]) !== -1;
  }
  this.get = function (key, params) {
    if (this.messages !== null && this.messages[key] !== undefined) {
      return replace(this.messages[key], params);
    } else {
      return key;
    }
  }
  function replace(str, params) {
    if (params !== undefined) {
      let i = 0;
      for (let param of params) {
        str = str.split('{' + i + '}').join(param);
        i++;
      }
    }
    return str;
  }
}

const Lang = new Language();

export default Lang;
