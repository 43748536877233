import React, { Component , Fragment} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faCommentDots, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

//Ui
import Typography from '../Typography/Typography';
import FileItem from '../FileUpload/FileItem';
import RequireField from './RequireField';
import ChangeField from './ChangeField';
import RequireFileAsWorker from "./RequireFileAsWorker";

import Validate from '../../api/Validate';
import DateUtils from '../../api/DateUtils';
import Lang from '../../api/Lang';

const dataComment = {
  type: 'I',
  id: '1',
  additional: {
    validations: [
      "required",
      {
        "name": "maxlength",
        "params": [255]
      }
    ]
  }
}

class RequestField extends Component {
    constructor(){
        super();
        this.state = {
           showActions: false,
           action: null,
           value: '',
           message: null,
           valid: true,
           feedback: false,
           files: [],
           serverFiles: null
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeModification = this.handleChangeModification.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
        this.handleDeleteFiles = this.handleDeleteFiles.bind(this);
        this.handleCancelAntecedent = this.handleCancelAntecedent.bind(this);
        this.validateField = this.validateField.bind(this);
    };

    handleClick(action) {
      if (action === 'H') {
        this.setState({ showActions: false, action: null});
      } else if (action === 'E') {
        this.setState({ showActions: true, action: action, value: '', message: '', valid: true});
      } else if (action === 'C') {
        this.setState({ showActions: true, action: action, value: '', message: '', valid: true});
      }
    }

    handleChange (value, id) {
      this.validateField(value, this.props.data.additional.validations);
    }

    handleChangeModification (value) {
      this.validateField(value, dataComment.additional.validations);
    }

    validateField(value, validations) {
      const message = Validate.validate(value, validations);
      const valid = message === "";
      this.setState({ value: value, message: message, valid: valid, feedback: true });
      return valid;
    }

    handleCancelAntecedent () {
      this.props.handleCancelAntecedent(this.props.data.id, () => {
          this.handleClick('H');
      });
    }

    handleSave () {
      const { value, action } = this.state;
      const { data } = this.props;
      const validations = action === 'C' ? dataComment.additional.validations : this.props.data.additional.validations;

      if (this.validateField(value, validations) || data.type === 'F') {
        if (action === 'E' || action === null) {
          if (data.type === 'F') {
            this.setState({message: '', valid: true, feedback: true});
            this.props.handleSaveFile(this.props.data.id, this.getDeletedFiles() , this.state.files, () => {
              this.setState({files: [], serverFiles: null});
            }, error => {
              this.setState({message: error.message, valid: false, feedback: true});
            });
          } else {
            this.props.handleSave(this.props.data.id, value, () => {
                this.handleClick('H');
            });
          }
        } else if (action === 'C') {
          this.props.handleChange(this.props.data.id, value, () => {
            this.handleClick('H');
          });
        }
      }
    }

    handleFiles (arg) {
        this.setState({
            files: arg.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }))
            , valid: true, message: null});
    };

    handleDeleteFiles(arg) {
      if(arg.action === 'DEL'){
          if (arg.type === 'tmp') {
            const newFiles = this.state.files.filter((item, i)=> i !== arg.id)
            this.setState({files: newFiles, valid: true, message: null});
            this.props.removeMessageError();
          } else {
            const newFiles = (this.state.serverFiles != null ? this.state.serverFiles : this.props.data.files).filter((item, i)=> i !== arg.id)
            this.setState({serverFiles: newFiles, valid: true, message: null});
          }
      };
    }

    getDeletedFiles() {
      if (this.state.serverFiles === null) {
        return [];
      }

      let { serverFiles } = this.state;
      let { data } = this.props;

      let deletedFiles = [];
      for (const file of data.files) {
        const fileIndex = serverFiles.findIndex(f => {
          return f.id === file.id;
        });

        if (fileIndex === -1) {
          deletedFiles.push(file.id);
        }
      }

      return deletedFiles;
    }

    getValue(data) {
      if (data.type === 'L') {
        const optionIndex = data.additional.options.findIndex(f => {
          return f.value === data.value;
        });
        return optionIndex !== -1 ? data.additional.options[optionIndex].key : '';
      } else if (data.type === 'D') {
        return data.value !== '' ? DateUtils.format(data.value) : '';
      } else if (data.type !== 'F') {
        return data.value;
      }
    }
    
    render() {

      const { requestId, data, role, handleChangeItem, handleRequestItem, readonly, loading, errorUpload } = this.props;
      const { showActions, action, value, message, valid, feedback, files } = this.state;
      let requestChange = null;
      let requireChange = null;
      let requireChangeFileAsWorker = null;
      let fileArea = null;
      let currentAction = action;
      let textColor = null;
      let fileEditable = false;
      let showChangeFileAsWorker = false;

      if (role === 'RQT' && data.state === 'V' && (data.requirechange === 'S' || ((!data.value && data.type !== 'F') || (data.type === 'F' && data.files.length === 0)))) {
        currentAction = 'E';
        textColor = data.type === 'F' && data.files.length === 0 && data.requirechange !== 'S' ? '' : 'danger';
        fileEditable = true;
        requireChange = (<RequireField
            handleSave={this.handleSave}
            handleChange={this.handleChange}
            handleFiles={this.handleFiles}
            data={data}
            value={value}
            message={message}
            valid={valid}
            feedback={feedback}
            />);
      }

      if (role === 'WKR' && data.state === 'V' && ( data.requirechange === 'S' && data.type === 'F' && data.files.length === 0)) {
            currentAction = 'E';
            textColor = data.type === 'F' && data.files.length === 0 && data.requirechange !== 'S' ? '' : 'danger';
            fileEditable = true;
            showChangeFileAsWorker = true;
            requireChangeFileAsWorker = (<RequireFileAsWorker
                handleSave={this.handleSave}
                handleFiles={this.handleFiles}
                handleCancelAntecedent={this.handleCancelAntecedent}
                data={data}
                message={message}
                valid={valid}
                feedback={feedback}
            />);
      }

      if (role === 'WKR' && data.state === 'V' && ( data.requirechange === 'S' || ((!data.value && data.type !== 'F') || (data.type === 'F' && data.files.length === 0))) && !showChangeFileAsWorker) {
            requestChange = (<ChangeField
                showActions={showActions}
                handleChange={this.handleChange}
                handleClick={this.handleClick}
                handleSave={this.handleSave}
                handleChangeModification={this.handleChangeModification}
                handleCancelAntecedent={this.handleCancelAntecedent}
                action={action}
                data={data}
                dataComment={dataComment}
                value={value}
                message={message}
                valid={valid}
                feedback={feedback}
            />);
      }

      if (role === 'WKR' && data.state === 'V' && ( data.requirechange !== 'S' && (data.value || (data.type === 'F' && data.files.length > 0)))) {
        requestChange = (<ChangeField
          showActions={showActions}
          handleChange={this.handleChange}
          handleClick={this.handleClick}
          handleSave={this.handleSave}
          handleChangeModification={this.handleChangeModification}
          handleCancelAntecedent={this.handleCancelAntecedent}
          action={action}
          data={data}
          dataComment={dataComment}
          value={value}
          message={message}
          valid={valid}
          feedback={feedback}
        />);
      }

      if (data.type === 'F' && (files.length > 0 || data.files.length > 0)) {
        fileArea = (<div className="col-md-12"><div className="file-preview margin-top">
            {(this.state.serverFiles !== null ? this.state.serverFiles : this.props.data.files).map((item, i) => (
                <FileItem data={item} key={i} id={i} dispatch={this.handleDeleteFiles} type="server" editable={fileEditable && data.loading !== true} requestId={requestId} fieldId={data.id}/>
            ))}
            {files.map((item, i) => (
                <FileItem data={item} key={i} id={i} dispatch={this.handleDeleteFiles} type="tmp" color={textColor} editable={fileEditable && data.loading !== true} />
            ))}
        </div></div>);
      }

      const classGroup = requestChange === null && requireChange === null ? "col-md-12" : (currentAction !== null ? "col-md-12" : "col-md-12");
      const labelType = data.type === "F" && textColor === 'danger' ? "h1" : "label";
      let requiredText = data.additional.validations[0] === 'required' ? (<Typography  color="danger">&nbsp;*</Typography>) : null;
      const classNameRequest = data.state === 'V' ? "form-group" : "form-group backgroundItemRemove";
      const showItem = (data.state === 'V' && role === 'RQT') || (role === 'WKR');

      return (
        (showItem && <div className="request-field -list">
          <div className={classNameRequest}>
            <div className={classGroup}>
              {labelType === 'label' ?
                <label className="label-title-field">{data.title}{requiredText}</label> :
                <Typography variant="h1" color={textColor}>{data.title}</Typography>
              }
              <Typography variant="h1" color={textColor}>{this.getValue(data)}</Typography>
              <span className="span-date">
                <Typography variant="p">{Lang.get('REQUEST.FIELD.LASTUPDATE')}<b>{data.lastupdate}</b></Typography>
              </span>
            </div>
            {!readonly && requireChange}
            {!readonly && requireChangeFileAsWorker}
            {!readonly && requestChange}
            {data.comment && <div className="col-md-12 request-field-comment">
              <Typography variant="p"> <Typography color="muted">Comentario:</Typography>{data.comment}</Typography>
            </div>}
            {fileArea}
            {errorUpload !== null && feedback &&
            <div className="col-md-12 request-field-comment">
             <Typography variant="small" color="danger"> <Typography color="muted">Error:</Typography> {errorUpload}</Typography>
             </div>
            }
          </div>
        </div>
        )
      );
    }
};

RequestField.propTypes = {
    data: PropTypes.object
};

export default RequestField;
