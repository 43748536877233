import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

const MODES = {
  phone: 576,
  medium: 576,
  tablet: 720,
  desktop: 992,
  large: 1200,
}

class Responsive extends Component {

  constructor(props) {
    super(props);

    this.state = {
      height: document.documentElement.clientHeight,
      width: document.documentElement.clientWidth
    }

    this.recalculate = this.recalculate.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.recalculate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recalculate);
  }

  recalculate() {
    this.setState({
      height: document.documentElement.clientHeight,
      width: document.documentElement.clientWidth
    });
  }

  getLimit(mode) {
    return MODES[mode] !== undefined ? MODES[mode] : null;
  }

  render() {
    let visible = false;
    const range = this.getLimit(this.props.mode);
    if(this.props.break === 'up'){
      visible = this.state.width < range;
    }else {
      visible = this.state.width > range;
    }
    /*if (this.props.mode === 'tablet' || this.props.mode === 'medium' || this.props.mode === 'phone') {
      visible = this.state.width < range;
    } else if (this.state.width > range) {
      visible = true;
    }*/
    return (
      <Fragment>
        {visible && this.props.children}
      </Fragment>
    );
  }
};

Responsive.propTypes = {
    mode: PropTypes.oneOf(["phone", "medium", "tablet", "desktop", "large"]),
    break: PropTypes.oneOf(["up", "down"]),
};

export default Responsive;
