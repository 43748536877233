import React , { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, InputGroup, InputGroupAddon, Input, Button, Form } from 'reactstrap';
import BackgroundItem from '../BackgroundItem/BackgroundItem'
import Typography from '../Typography/Typography';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import CreateBackground from '../CreateBackground/CreateBackground';
import Lang from '../../api/Lang';

class SearchBackground extends Component  {
    constructor(){
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCustom = this.handleChangeCustom.bind(this);
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    handleChange = e => {
      this.props.handleChange(e.target.value);
    }

    handleChangeCustom = e => {
      this.props.handleChangeCustomText(e.target.value);
    }

    render(){
        const { items, selected, total, handleClickCustom, handleFieldClick, handleDeleteClick, showList, showCreate, filterValue, newField, handleChangeCustomType } = this.props;
        const showFields = items !== null && items !== undefined && items.length > 0;
        const showEmpty = (items === null || items === undefined || items.length === 0) && selected.length === 0;
        return (
            <Form onSubmit={this.handleSubmit}>
            {showList &&
            <div className="sbitem">
                {!showCreate && total > 0 &&
                <div className="sbinput">
                <FormGroup className="mb-sm-0">
                    <InputGroup>
                    <Input name="inpSearch" onChange={this.handleChange} type="text" value={filterValue} placeholder="Filtrar resultados por..." />
                    <InputGroupAddon addonType="prepend">
                        <Button>
                            <FontAwesomeIcon icon={faSearch}/>
                        </Button>
                    </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                </div>}
                {showFields &&
                <div className="sblist">
                  {items.map(item =>
                      <BackgroundItem key={item.id} id={item.id} name={item.name} onClick={() => handleFieldClick(item)} />
                  )}
                </div>}
                {showEmpty && !showCreate &&
                <div className="sbnoresults">
                    <Typography variant="p">{total > 0 ? Lang.get('SEARCHBACKGROUND.NOFOUND') : Lang.get('SEARCHBACKGROUND.NOFIELD')}
                        <Typography variant="strong">{filterValue}</Typography>
                    </Typography>
                    <Button outline color="secondary" onClick={handleClickCustom}>{Lang.get('SEARCHBACKGROUND.CREATECUSTOM')}</Button>
                </div>}
                {showCreate &&
                    <CreateBackground
                        handleChange={this.handleChangeCustom}
                        handleChangeType={handleChangeCustomType}
                        value={newField.value}
                        message={newField.message}
                        valid={newField.valid}
                    />}
                {selected.length > 0 &&
                <div className="sbbuttonsarea">
                    <div>
                        <Typography variant="h6">{Lang.get('SEARCHBACKGROUND.CREATED')}</Typography>
                        <Typography variant="small" color="muted" >{Lang.get('SEARCHBACKGROUND.DELETE')}</Typography>
                    </div>
                    <div className="sblistbuttons row">
                        {selected.map(item =>
                              <ButtonIcon key={item.id} icon={faTimes} text={item.name} onClick={() => handleDeleteClick(item)}/>
                        )}
                    </div>
                </div>}
            </div>
            }
            </Form>
        );
    }
};

export default SearchBackground;
