import React from 'react';
import Typography from '../../components/Typography/Typography';
import { Link } from 'react-router-dom';
import Lang from '../../api/Lang';

const AccountExpired = props => {
    return (
        <div className="account-recover-area">
            <Typography variant="h4">
                {Lang.get('ACOOUNT.TITLE')}
            </Typography>
            <Typography variant="p">
                {Lang.get('ACOOUNT.MESSAGE')}
            </Typography>
            <Link to="/">{Lang.get('ACOOUNT.LINK')}</Link>
        </div>
    );
};

export default AccountExpired;