import React from 'react';
import Lang from '../../api/Lang';

//Ui
import Typography from '../Typography/Typography';

const Slogan = props => {
    return(
        <div className="item-slogan">
            <Typography variant="h1">
                {Lang.get('HOME.SLOGAN')}
            </Typography>
        </div>
    );
};
export default Slogan;