import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import Typography from '../Typography/Typography';
import Lang from '../../api/Lang';

import { Button } from 'reactstrap';

const TasksItem = props => {
    const { id, name, description, callModalContract, requesting, tags, prices } = props;
    const totalTags = tags.length;
    let etiquetas = tags.map((item, i) =>
      (<Fragment key={i}><span className="">{item}</span>&nbsp;{i < totalTags - 1 && <span>-&nbsp;</span>}</Fragment>)
    );
    return (
        <div className="card mb-3 p-2 card-hover">
            <div className="row">
                <div className="col d-flex flex-column justify-content-center">
                    <div className="row pl-1">
                        <div className="col-12 mb-2">
                            <Typography variant="h5">
                                {name} 
                            </Typography>
                        </div>
                        <div className="col-12 mb-2">
                            <Typography variant="p" asHTML={true}>{description}</Typography>
                        </div>
                        <div className="col-12">
                            {totalTags > 0 &&
                                <div className="row no-gutters"> 
                                    <div className="col-auto mr-2">
                                        <FontAwesomeIcon className="text-danger" icon={faTags} />
                                    </div>
                                    <div className="col">
                                        <span className="text-mutted">{etiquetas}</span>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 responsive-border-card-task">
                    <div className="row no-gutters">
                        <div className="col-md-12 pb-2 pt-2">
                            {prices.map((item, i) => (
                                <div className="row no-gutters flex-column mb-3" key={i}> 
                                    <span className="d-flex">
                                        <Typography variant="h6">
                                            <Typography variant="strong">
                                                <span className={item.priority > 0 ? 'text-plus': undefined}>{item.settings.time}</span>
                                                    {/*Lang.get('CATEGORY.TASKITEM.PRICE', [item.name, item.settings.time])*/}
                                            </Typography>
                                        </Typography>
                                        {item.priority > 0 && <div className="badge badge-plus ml-2">{item.name}</div>}
                                        
                                    </span>
                                    <Typography variant="p">
                                        {item.priority > 0 ? <b>{Lang.get('CATEGORY.TASKITEM.PRICE.ALT', [item.name])}</b> : Lang.get('CATEGORY.TASKITEM.PRICE', [item.name])}
                                        {item.priority > 0 ? <b className="text-plus">{item.price}</b> : item.price}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                        <div className="col-md-12 mt-2 mt-md-1">
                            <Button block color="danger" name={id} onClick={() => callModalContract(id)} disabled={Boolean(requesting)}>
                                {Lang.get('CATEGORY.REQUEST')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
           
            {/*<div className="ti-content">
                <div className="ti-content-ie">
                    <Typography variant="h6"><Typography variant="strong">{name}</Typography></Typography>
                    <Typography variant="p" asHTML={true}>{description}</Typography>
                    {totalTags > 0 &&
                    <div className="wrapper-tags"> 
                        <div className="icon-tag">
                            <FontAwesomeIcon className="icon-tags" icon={faTags} />
                        </div>
                        <div className="items-tag">
                            <span>{etiquetas}</span>
                        </div>
                    </div>
                    }
                    {prices.map((item, i) => (
                        <div className="wrapper-tags" key={i}> 
                            <div className="icon-tag">
                                {i === 0 && <FontAwesomeIcon className="icon-tags" icon={faDollarSign} />}
                            </div>
                            <div className="items-tag">
                                <span className="">
                                    {item.priority > 0 ? 
                                        <b>{Lang.get('CATEGORY.TASKITEM.PRICE', [item.name, item.settings.time, item.price])}</b>: 
                                        Lang.get('CATEGORY.TASKITEM.PRICE', [item.name, item.settings.time, item.price])}
                                </span>
                            </div>
                        </div>
                    ))}
                    
                </div>
            </div>
            <div className="ti-buttonarea">
                <Button color="danger" name={id} onClick={() => callModalContract(id)} disabled={Boolean(requesting)}>
                {//Lang.get('CATEGORY.REQUEST')}
                </Button>
                                    </div>*/}
        </div>
    );
};

export default TasksItem;