import React, { Component, Fragment } from 'react';

import iconFacebook from '../../resources/images/facebook-icon.png';
import iconGoogle from '../../resources/images/google-icon.png';
import iconTwitter from '../../resources/images/twitter-icon.png';
import iconLinkedin from '../../resources/images/linkedin-icon.png';

import Lang from '../../api/Lang';
import XServices from '../../api/XServices';

//Components
import Register from './Register';
import Login from './Login';
import Recover from './Recover';
import Typography from '../Typography/Typography';
import { Alert } from 'reactstrap';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSignIn: props.ctx === 'signin' ? true : false,
            showRegister: props.ctx === 'register' ? true : false,
            showRecover: false,
            showSocial: true,
            error: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClickSocial = this.handleClickSocial.bind(this);
    };
    handleChange = (arg) => {
        if (arg === 'signin') {
            this.setState({ showSignIn: true, showRegister: false, showRecover: false })
        };
        if (arg === 'recover') {
            this.setState({ showSignIn: false, showRegister: false, showRecover: true })
        };
        if (arg === 'register') {
            this.setState({ showSignIn: false, showRegister: true, showRecover: false })
        };
        this.setState({ error: null })
    };

    handleClickSocial = (type) => {
        if (this.props.handleExternalLogin !== undefined) {
            this.props.handleExternalLogin();
        }

        const service = 'account/' + type;
        XServices.get(service, {},
            response => {
                if (response.url !== undefined && response.url !== null) {
                    window.location = response.url;
                } else {
                    this.setState({ error: Lang.get('ACOOUNT.ERR.RESPONSE') });
                }
            },
            error => {
                this.setState({ error: error.message });
            });
    }
    
    render() {
        const { error, showRegister, showSignIn, showRecover, showSocial } = this.state;
        const {  showLinkFooter } = this.props;
        return (
            <div className="login-wrapper">
                <div className="login-title">
                    <Typography variant="h4">
                        {showRegister && Lang.get('REGISTER.TITLE')}
                        {showSignIn && Lang.get('LOGIN.TITLE')}
                        {showRecover && Lang.get('FORGOT.TITLE')}
                    </Typography>
                </div>
                {showRegister && <Register handleClick={this.handleChange} />}
                {showSignIn && <Login handleChange={this.handleChange} handleClick={this.handleChange}  showLinkFooter={showLinkFooter} />}
                {showRecover && <Recover handleClick={this.handleChange} />}
                {showSocial && <Fragment>
                    <div className="helper-text-register">
                        <Typography variant="p" color="muted">{Lang.get('LOGIN.SOCIAL')}</Typography>
                    </div>
                    <div className="login-social-wrapper">
                        <div className="login-social-item" onClick={() => this.handleClickSocial('google')}>
                            <img src={iconGoogle} alt="Ingresar con Google" />
                        </div>
                        <div className="login-social-item" onClick={() => this.handleClickSocial('twitter')}>
                            <img src={iconTwitter} alt="Ingresar con Twitter" />
                        </div>
                        <div className="login-social-item" onClick={() => this.handleClickSocial('linkedin')}>
                            <img src={iconLinkedin} alt="Ingresar con Linkedin" />
                        </div>
                    </div>
                    {error !== null &&
                        <Alert color="warning" className="alert-custom" close="none">
                            <div className="alert-message">
                                <Typography variant="p">{error}</Typography>
                            </div>
                        </Alert>
                    }
                </Fragment>}
            </div>
        );
    };
};

export default Account;
