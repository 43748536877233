import React from 'react';

import Typography from '../Typography/Typography';
import Avatar from '../Avatar/Avatar';
import Rank from '../Rank/Rank';
import PropTypes from 'prop-types';

const RankItem = props => {
    const {url, name, publish_date, content, placeholder, readonly} = props;
    return (
        <div className='rankitem'>
            <div className='rank-avatar'>
                <Avatar name="alt for image" size="md" url={url} variant="primary" />
            </div>
            <div className='rank-content'>
                <div className="rank-box">
                    <Typography variant="h6">{name}</Typography>
                    <Typography variant="small" color="muted">{publish_date}</Typography>
                </div>
                <div classname="rank-box">
                    <Typography variant="small">{content}</Typography>
                </div>
            </div>
            <div className='rank-rank'><Rank placeholder={placeholder} readonly={readonly}/></div>
        </div>
    );
};

RankItem.propTypes = {
    url: PropTypes.string,
    name : PropTypes.string.isRequired,
    publish_date : PropTypes.string.isRequired,
    content : PropTypes.string,
    placeholder: PropTypes.number.isRequired,
    readonly: PropTypes.bool.isRequired
};

export default RankItem;