import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '../../Typography/Typography';
import Avatar from '../../Avatar/Avatar';
//import { Redirect } from 'react-router-dom';
import Lang from '../../../api/Lang';
import Rank from '../../Rank/Rank';
import Responsive from '../../Responsive/Responsive';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

class ListRequestItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            this.props.history.push(`/${Lang.lancode}/request/${this.props.id}`);
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        if (this.props.status === 'assigned' || this.props.status === 'requested') {
            this.setState({ redirect: true })
        }
    }

    render() {
        const { stateName, requesting, handleToCloseRequest, handleToCheck, handleToPaid, alreadyRanked, state, status, priority, url, id, name, created_date, requirement, time, toggleModal, assingRequest, timeRemaining, rank, assignDate, timeAdditional, toggleToCloseRequest, alreadyHasClosingPetition, textWarrantyTime } = this.props;
        return (
            <Fragment>
                {(status === 'assigned' || status === 'requested') && this.renderRedirect()}
                <div className={priority === 1 ? `ar-item normal` : `ar-item plus`}>
                    <div className="ar-avatar" onClick={this.handleClick}>
                        <Avatar name={name} size="md" url={url} variant="primary" />
                    </div>
                    <Responsive mode="tablet" break="up">
                        <div className="ar-content ar-content-mobile">
                            <Typography variant="h6"><Typography variant="strong">{requirement}</Typography></Typography>
                            <Typography variant="small">{name}</Typography>
                            <Typography variant="small">#{id}</Typography>
                            <span  className="ar-content-mobile-date">
                                <Typography variant="small">{Lang.get('LISTREQUESTITEM.CREATED')}<Typography variant="strong"></Typography>{created_date}</Typography>
                            </span>
                            <span  className="ar-content-mobile-estimated">
                                <Typography variant="small">{Lang.get('LISTREQUESTITEM.ESTIMATED.TIME')}<Typography variant="strong">{time}hrs</Typography></Typography>
                            </span>
                            <Typography variant="small">{Lang.get('LISTREQUESTITEM.ESTATE')}<Typography variant="strong">{stateName}</Typography></Typography>
                        </div>
                    </Responsive>
                    <Responsive mode="tablet">
                        <div className="ar-content">
                            <div>
                                <Typography variant="h6"><Typography variant="strong">{requirement}</Typography></Typography>
                                <Typography variant="small">{name}</Typography>
                                <Typography variant="small">#{id}</Typography>
                            </div>
                            <div>
                                <Typography variant="small">{Lang.get('LISTREQUESTITEM.CREATED')}<Typography variant="strong"></Typography>{created_date}</Typography>
                                <Typography variant="small">{Lang.get('LISTREQUESTITEM.ESTIMATED.TIME')}<Typography variant="strong">{time}hrs</Typography></Typography>
                                <Typography variant="small">{Lang.get('LISTREQUESTITEM.ESTATE')}<Typography variant="strong">{stateName}</Typography></Typography>
                            </div>
                        </div>
                    </Responsive>

                    {status === 'available' &&
                        <div className="ar-buttonarea">
                            <Button color="danger" name={id} onClick={() => assingRequest(id)} disabled={Boolean(requesting)}>
                                {Lang.get('LISTREQUESTITEM.BUTTON.TOASSIGN')}
                            </Button>
                        </div>
                    }
                    {(status === 'assigned' || status === 'requested') &&
                        <div className='ari-lastarea'>
                            {state === 'UNP' ?
                                <div className="ar-buttonarea">
                                    <Button color="danger" name={id} onClick={() => handleToPaid(id)} disabled={Boolean(requesting)}>
                                        {Lang.get('LISTREQUESTITEM.BUTTON.TOPAID')}
                                    </Button>
                                </div>
                                :
                                state === 'ERP' ?
                                    <div className="ar-buttonarea">
                                        <Button color="danger" name={id} onClick={() => handleToCheck(id)} disabled={Boolean(requesting)}>
                                            {Lang.get('LISTREQUESTITEM.BUTTON.TOCHECK')}
                                        </Button>
                                    </div>
                                    :
                                    state === 'FNS' && alreadyRanked !== 'S' && status === 'requested' ?
                                        <div className="ar-buttonarea multiple-button">
                                            <Button color="danger" name={id} onClick={() => handleToCloseRequest(id)}>
                                                {Lang.get('LISTREQUESTITEM.BUTTON.CLOSE')}
                                            </Button>
                                            <Button onClick={this.handleClick} className="text-link" color="link">
                                                {Lang.get('LISTREQUESTITEM.CHECKREQUEST')}
                                            </Button>
                                        </div>
                                        :
                                        state === 'CLD' && alreadyRanked !== 'S' && status === 'requested' ?
                                            <div className="ar-buttonarea">
                                                <Button color="danger" name={id} onClick={() => toggleModal(id)}>
                                                    {Lang.get('LISTREQUESTITEM.BUTTON.TORANK')}
                                                </Button>
                                            </div>
                                            :
                                            state === 'CLD' && alreadyRanked === 'S' ?
                                                <div className='ar-actionarea'>
                                                    <div>
                                                        <Rank placeholder={rank} readonly={true} />
                                                    </div>
                                                </div>
                                                :
                                                state === 'CLD' && alreadyRanked === 'N' && status === 'assigned' ?
                                                    <div className='ar-actionarea'>
                                                        <div>
                                                            <Typography variant='h6'>{Lang.get('LISTREQUESTITEM.WAIT.RANK')}</Typography>
                                                        </div>
                                                    </div>
                                                    :
                                                    state === 'RPN' ?
                                                        <div className='ar-actionarea'>
                                                            <div>
                                                                <Typography variant="small" color="muted">{Lang.get('TIMERWARRANTY.TITLE')}</Typography>
                                                                <Typography variant='h6'>{textWarrantyTime}</Typography>
                                                            </div>
                                                        </div>
                                                        :
                                                        state === 'FNS' && alreadyRanked === 'N' ?
                                                            <div className='ar-actionarea'>
                                                                <div>
                                                                    {/* {assignDate !== null && timeAdditional !== null && <Typography variant='h6' color="danger">{Lang.get('LISTREQUESTITEM.TIME.FINALIZED')}</Typography>} */}
                                                                    {alreadyHasClosingPetition === 'N' ?
                                                                        <Button color="danger" name={id} onClick={() => toggleToCloseRequest(id)}>
                                                                            {Lang.get('LISTREQUESTITEM.BUTTON.TOCLOSE')}
                                                                        </Button>
                                                                        :
                                                                        <Typography variant='h6'>{Lang.get('LISTREQUESTITEM.CLOSE.REQUEST')}</Typography>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='ar-actionarea'>
                                                                <div>
                                                                    {assignDate !== null && timeAdditional === null &&
                                                                        <Typography variant="small" color="muted">{Lang.get('TIMER.TITLE1')}</Typography>
                                                                    }
                                                                    <Typography variant='h6'>{assignDate !== null ? timeRemaining : Lang.get('LISTREQUESTITEM.UNINICIATED')} </Typography>
                                                                    {assignDate !== null && timeAdditional !== null &&
                                                                        <Typography variant='h6' color="danger">{timeAdditional}</Typography>
                                                                    }
                                                                </div>
                                                            </div>
                            }
                        </div>
                    }
                </div>
            </Fragment>
        );
    };
};

ListRequestItem.propTypes = {
    status: PropTypes.string.isRequired,
    priority: PropTypes.number.isRequired,
    url: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    created_date: PropTypes.string.isRequired,
    requirement: PropTypes.string.isRequired,
    time_remaining: PropTypes.string,
    time: PropTypes.number.isRequired,
    finishDate: PropTypes.string,
    state: PropTypes.string
};

export default withRouter(ListRequestItem);
