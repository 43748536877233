import React, { Component } from 'react';
import Validate from '../../api/Validate';
import Lang from '../../api/Lang';
import { Link } from 'react-router-dom';
import XServices from '../../api/XServices';

//Ui
import { Button, Form, FormGroup, Label } from 'reactstrap';
import InputField from '../../components/InputField/InputField';
import Typography from '../../components/Typography/Typography';

const defaultOptions = {
    default: 'consulta',
    options:  [
        {key: 'Consulta', value: 'Consulta'},
        {key: 'Reclamo', value: 'Reclamo'},
        {key: 'Sugerencia', value: 'Sugerencia'},
        {key: 'Unirse', value: 'Quiero Unirme'},
        {key: 'Otro', value: 'Otro'},
    ]
}
//'Consulta', 'Reclamo', 'Sugerencia', 'Quiero Unirme', 'Otro'
class Contact extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            phone: '',
            type: '',
            message: '',
            issubmited: false,
            requesting: false,
            error: '',
            success: '',
            fields: [
                {
                    id: 'register-name',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                      'required',
                      {
                        "name": "maxlength",
                        "params": [255]
                      }
                    ]
                  },
                  {
                    id: 'register-email',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                      'required',
                      'email',
                      {
                        "name": "maxlength",
                        "params": [255]
                      }
                    ]
                  },
                  {
                    id: 'register-phone',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                      'number',
                      {
                        "name": "maxlength",
                        "params": [16]
                      }
                    ]
                  },
                  {
                    id: 'register-type',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                      'required',
                    ]
                  },
                  {
                    id: 'register-message',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                      'required',
                      {
                        "name": "maxlength",
                        "params": [1024]
                      }
                    ]
                  },
            ]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange = (value, id) => {
        const fieldIndex = this.state.fields.findIndex(f => {
          return f.id === id;
        });

        let fields = [...this.state.fields];
        fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);

        this.setState({fields: fields});
    };

    handleSubmit = e => {
        e.preventDefault();

        let fields = this.validateForm();

        if (!this.state.issubmited) {
          this.setState({issubmited: true});
        }

        if (!Validate.validForm(fields)) {
          return;
        }
        this.setState({ requesting: true, error: ''});

        XServices.post('/contact/send/email', {
            name: this.state.fields[0].value,
            email: this.state.fields[1].value,
            phone: this.state.fields[2].value,
            type: this.state.fields[3].value,
            message: this.state.fields[4].value, 

        }, response => {
            this.setState({requesting: false, error: '', success: response.message});
        }, error => {
            this.setState({
                requesting: false,
                error: error.message,
                success: '',
            });
        })
    };

    validateForm = () => {
        const fields = Validate.validateForm(this.state.fields);
        this.setState({fields: fields});
        return fields;
    }
    
    render(){
        const { fields, issubmited, requesting, error } = this.state;
        return(
            <div className="content-body pt-4 pb-4">
                <div className="container mt-4 mb-4">
                    <div className="row">
                        <div className="col-xs-12 col-md-7">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    {((!this.state.success) && (!this.state.error)) && 
                                    <>
                                    <Typography variant="h1">{Lang.get('CONTACT.FORM.TITLE')}</Typography>
                                    <br />
                                    <Typography variant="h6">{Lang.get('CONTACT.FORM.SUBTITLE')}</Typography> </>}
                                    {this.state.success && <>
                                        <Typography variant="h1">{Lang.get('CONTACT.SUCCESS.TITLE')}</Typography>
                                        <br />
                                        <Typography variant="h6">{Lang.get('CONTACT.SUCCESS.SUBTITLE')}</Typography>
                                        <Typography variant="h6">{Lang.get('CONTACT.SUCCESS.INFO')}</Typography>
                                    </>}
                                    {this.state.error && <>
                                        <Typography variant="h1">{Lang.get('CONTACT.ERROR.TITLE')}</Typography>
                                        <Typography variant="h6">{Lang.get('CONTACT.ERROR.SUBTITLE')}</Typography>
                                    </>}
                                </div>
                                <div className="col-md-12">
                                    {((!this.state.success)  && (!this.state.error)) &&
                                    <Form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <FormGroup>
                                                    <Label className="label required" for="register-name">{Lang.get('CONTACT.FORM.NAME')}</Label>
                                                    <InputField 
                                                        handleChangeInput={this.handleChange} 
                                                        inputId="register-name" 
                                                        id="register-name" 
                                                        type="T" valid={fields[0].valid || !issubmited} 
                                                        size="lg" 
                                                        message={fields[0].message} 
                                                        feedback={issubmited} 
                                                        disabled={requesting}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <FormGroup>
                                                    <Label className="label required" for="register-email">{Lang.get('CONTACT.FORM.EMAIL')}</Label>
                                                    <InputField 
                                                        handleChangeInput={this.handleChange} 
                                                        inputId="register-email" 
                                                        id="register-email" 
                                                        type="T" 
                                                        valid={fields[1].valid || !issubmited} 
                                                        size="lg" 
                                                        message={fields[1].message} 
                                                        feedback={issubmited} 
                                                        disabled={requesting}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label for="register-phone">{Lang.get('CONTACT.FORM.PHONE')}</Label>
                                                    <InputField 
                                                        handleChangeInput={this.handleChange} 
                                                        inputId="register-phone" 
                                                        id="register-phone" 
                                                        type="T" 
                                                        valid={fields[2].valid || !issubmited} 
                                                        size="lg" 
                                                        message={fields[2].message} 
                                                        feedback={issubmited} 
                                                        disabled={requesting}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label className="label required" for="register-type">{Lang.get('CONTACT.FORM.TYPE')}</Label>
                                                    <InputField
                                                        inputId="register-type"
                                                        id={'register-type'}
                                                        type="L"
                                                        size="lg" 
                                                        additional={defaultOptions}
                                                        handleChangeInput={this.handleChange}
                                                        valid={fields[3].valid || !issubmited}
                                                        message={fields[3].message}
                                                        feedback={issubmited}
                                                        disabled={requesting}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <FormGroup>
                                                    <Label className="label required" for="register-message">{Lang.get('CONTACT.FORM.MESSAGE')}</Label>
                                                    <InputField 
                                                        handleChangeInput={this.handleChange} 
                                                        inputId="register-message" 
                                                        id="register-message" 
                                                        type="A" 
                                                        valid={fields[4].valid || !issubmited} 
                                                        size="lg" 
                                                        message={fields[4].message} 
                                                        feedback={issubmited} 
                                                        disabled={requesting}/>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <Button 
                                                    type="submit" 
                                                    size="lg" 
                                                    block 
                                                    color="danger" 
                                                    disabled={requesting}>
                                                    {Lang.get('CONTACT.FORM.SUBMIT')}
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-5 pt-4">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <div className="card bg-contact box-contact">
                                        <div className="card-body">
                                            <Typography variant="h5">
                                                {Lang.get('CONTACT.BOX.TITLE')}
                                            </Typography>
                                            <br />
                                            <Typography variant="p">
                                                {Lang.get('CONTACT.BOX.MESSAGE')} <Link to={`/${Lang.lancode}/faq`}>{Lang.get('FOOTER.FAQ')}</Link>
                                            </Typography>
                                            <br />
                                            <br />
                                            <Typography variant="p">
                                                {Lang.get('CONTACT.BOX.MESSAGE_ALT')}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12 mb-4">
                                    <Typography variant="h5">{Lang.get('CONTACT.FORM.PHONE')}</Typography>
                                    <Typography>+56 9 6122 7066</Typography>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <Typography variant="h5">{Lang.get('CONTACT.FORM.WSP')}</Typography>
                                    <Typography>+56 9 6122 7066</Typography>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <Typography variant="h5">{Lang.get('CONTACT.FORM.EMAIL')}</Typography>
                                    <Typography>contact@revu.cl</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}       
export default Contact;