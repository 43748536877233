import React from 'react';

//Ui
import { TabContent, TabPane } from 'reactstrap';

const TabsArea = props => {
    const { active, items } = props;
    let tabPanes = items.map((item, i) =>
      <TabPane key={i} tabId={item.id}>{item.component}</TabPane>
    );
    return (
        <TabContent activeTab={active}>
            {tabPanes}
        </TabContent>
    );
};

export default TabsArea;
