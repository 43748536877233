import XServices from './XServices';

function FileUtilities () {
  this.uploadMulitple = function (files, callback, errorCallback) {
    let temporaryId = [];
    for (const file of files) {
      var data = new FormData();
      data.append('files', file);
      data.append('token', XServices.token());

      XServices.upload('file/upload', data, response => {
        temporaryId.push(response);
        if (files.length === temporaryId.length) {
          callback(temporaryId);
        }
      }, error => {
        if (errorCallback !== undefined) {
          errorCallback(error);
        }
      });
    }
  };
}

const FileUtils = new FileUtilities();

export default FileUtils;
