import React, { Component, Fragment } from 'react';
import { Link, Redirect} from 'react-router-dom';
import XServices from '../../api/XServices';
import Lang from '../../api/Lang';
import ErrorCode from '../../api/ErrorCode';
import { AuthConsumer } from '../../config/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';

//Components
import Typography from '../../components/Typography/Typography';
import Avatar from '../../components/Avatar/Avatar';
import Loading from '../../components/Loading/Loading';
import CustomError from '../../components/Error/CustomError';

//Ui
import { Container, Button } from 'reactstrap';

class PaymentSuccess extends Component {

  constructor(){
      super();
      this.state = {
          loading: true,
          data: null,
          error: null
      };
      this.markAsViewed = this.markAsViewed.bind(this);
  };

  componentDidMount() {
    let that = this;
    XServices.get('request/info', {
      id: this.props.match.params.id
    }, function (response) {
      that.setState({ loading: false, data: response, error: null });
      that.markAsViewed();
    }, function (error) {
      that.setState({ loading: false, error: { code: ErrorCode.get(error.code), message: error.message}});
    });
  }

  markAsViewed() {
    XServices.post('request/view/success', {
      requestId: this.props.match.params.id
    });
  }

  render() {
    const { loading, data, error } = this.state;
    return(
        <AuthConsumer>
            {({ user: { img_url, name }})=> (
              loading ?
                <div className="margin-top">
                  <Loading variant="component" color="danger" style={{ width: '3rem', height: '3rem' }}/>
                </div>
               :
                error !== null ? <CustomError code={error.code} message={error.message} /> :
                <Fragment>
                {data.paymentviewed && <Redirect to={ "/" + Lang.lancode + "/request/" + data.id } />}
                <section className="checkout-wrapper">
                    <Container>
                        <div className="checkout-wrapper-item">
                            <Typography variant="h1">{Lang.get("REQUEST.PAY.SUCCESS.CONGRATS", [name])}</Typography>
                            <Typography variant="h2">{Lang.get("REQUEST.PAY.SUCCESS.YOURPAYMENT")}<Typography color="success">{Lang.get("REQUEST.PAY.SUCCESS.YOURPAYMENTSUCCESS")}</Typography></Typography>
                            <Button className="link" color="link" tag={Link} to={ "/" + Lang.lancode + "/request/" + data.id }>{Lang.get("REQUEST.PAY.SUCCESS.CHECKLINK", [data.id])}<FontAwesomeIcon icon={faArrowRight}/></Button>
                        </div>
                        <div className="checkout-wrapper-item">
                            <div className="card">
                                <div className="card-item">
                                    <Avatar url={img_url} name={name} size="md" variant="primary"/>
                                </div>
                                <div className="card-item">
                                    <Typography variant="h4">{Lang.get("REQUEST.PAY.SUCCESS.ID", [data.id])}</Typography>
                                    <Typography variant="h5">{data.type}</Typography>
                                    <Typography variant="p">{data.time}</Typography>
                                    <Typography variant="p">{data.pricemode}</Typography>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <section className="back-wrapper">
                    <Container>
                        <div className="checkout-wrapper-item">
                            <Typography variant="h5">{Lang.get("REQUEST.PAY.SUCCESS.THANKS", [name])}<Typography variant="strong">rev-u.com</Typography></Typography>
                            <Typography variant="h6">{Lang.get("REQUEST.PAY.SUCCESS.GOHOME.TITLE")}</Typography>
                            <Button color="info" size="lg" tag={Link} to={"/" + Lang.lancode}>{Lang.get("REQUEST.PAY.SUCCESS.GOHOME.LINK")}</Button>
                        </div>
                        <div className="checkout-wrapper-item">
                        </div>
                    </Container>
                </section>
            </Fragment>
            )}
        </AuthConsumer>
    );
  }
}

export default PaymentSuccess;
