import React, { Component } from 'react';

import XServices from '../../api/XServices';
import FileUtils from '../../api/FileUtils';
import moment from 'moment';
// UI
import RequestField from './RequestField';
import AskNewField from './AskNewField';

const REFRESH_TIME = 60000;

class TabField extends Component {

  constructor() {
    super();
    this.state = {
        loading: true,
        fields: null,
        error: null,
        timeout: null,
        errorUpload: null,
        localServerDifference: null
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleSaveFile = this.handleSaveFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddField = this.handleAddField.bind(this);
    this.refreshLastUpdateTime = this.refreshLastUpdateTime.bind(this);
    this.setFieldLoading = this.setFieldLoading.bind(this);
    this.handleServiceFields = this.handleServiceFields.bind(this);
    this.removeMessageError = this.removeMessageError.bind(this);
    this.calculateUpdateTime = this.calculateUpdateTime.bind(this);
    this.handleCancelAntecedent = this.handleCancelAntecedent.bind(this);
  }

  componentDidMount() {
    this.handleServiceFields();  
  }

  handleServiceFields (){
    XServices.get('request/fields', {
      id: this.props.id
    },response => {
        let fields = [...response];
        let i = 0;

        if (this.state.localServerDifference == null) {
            let serverTime = moment(this.props.serverTime);
            this.setState({
                localServerDifference: serverTime.diff(moment())
            });
        }
      
        for (const field of fields) {
            let validations = [...field.additional.validations];
            validations.unshift("required");
            fields[i].additional.validations = [...validations];
            fields[i].lastupdate = this.calculateUpdateTime(fields[i]);
            i++;
        }
      
        let toState = {loading: false, fields: fields};
        if (this.state.timeout == null) {
            const timeout = setTimeout(this.refreshLastUpdateTime, REFRESH_TIME);
            toState["timeout"] = timeout;
        }

        this.setState(toState);
      
      this.props.checkRemainingTime();
    }, error => {
      this.setState({loading: false, error: error.message});
    });
  }

  componentWillUnmount() {
    if (this.state.timeout !== null) {
      clearTimeout(this.state.timeout);
    }
  }

  refreshLastUpdateTime() {
    let fields = [...this.state.fields];
    for (const field of fields) {
      field.lastupdate = this.calculateUpdateTime(field);
    }

    const timeout = setTimeout(this.refreshLastUpdateTime, REFRESH_TIME);
    this.setState({fields: fields, timeout: timeout});
  }

  setFieldLoading(id, loading) {
    const fieldIndex = this.state.fields.findIndex(f => {
      return f.id === id;
    });

    const fields = [...this.state.fields];
    fields[fieldIndex].loading = loading;

    this.setState({fields: fields});
  }

  handleCancelAntecedent (id, callback) {
    this.setFieldLoading(id, true);

    XServices.post('request/field/remove', {
      id: this.props.id,
      field: id,
    }, response => {
      const fieldIndex = this.state.fields.findIndex(f => {
        return f.id === response.field.id;
      });
      const fields = [...this.state.fields];
      fields[fieldIndex] = response.field;
      fields[fieldIndex].loading = false;
      fields[fieldIndex].lastupdate = this.calculateUpdateTime(fields[fieldIndex]);
      this.setState({fields: fields});
      this.props.checkRemainingTime();
      callback();
    }, error => {
      this.setFieldLoading(id, false);
    });
  }

  handleSave(id, value, callback) {
    this.setFieldLoading(id, true);

    XServices.post('request/field/save', {
        id: this.props.id,
        field: id,
        value: value
    }, response => {
      const fieldIndex = this.state.fields.findIndex(f => {
        return f.id === response.field.id;
      });

    const fields = [...this.state.fields];
    fields[fieldIndex] = response.field;
    fields[fieldIndex].loading = false;

    let validations = [...fields[fieldIndex].additional.validations];
    validations.unshift("required");
    fields[fieldIndex].additional.validations = [...validations];
    fields[fieldIndex].lastupdate = this.calculateUpdateTime(fields[fieldIndex]);
    this.setState({fields: fields});
    this.props.checkRemainingTime();
    callback();
    }, error => {
      this.setFieldLoading(id, false);
    });
  }

  handleSaveFile(fieldId, deleteFiles, newFiles, callback, errorCallback) {
    const requestId = this.props.id;
    this.setFieldLoading(fieldId, true);
    if (newFiles.length > 0) {
      FileUtils.uploadMulitple(newFiles, tmpIds => {
        this.doSaveFile(requestId, fieldId, deleteFiles, tmpIds, callback);
      }, error => {
        this.setFieldLoading(fieldId, false);
        errorCallback(error);
      });
    } else {
      this.doSaveFile(requestId, fieldId, deleteFiles, []);
    }
  }

  doSaveFile(requestId, fieldId, deleteFiles, tmpIds, callback) {
    XServices.post('request/field/upload', {
        id: requestId,
        field: fieldId,
        deleteFiles: JSON.stringify(deleteFiles),
        newFiles: JSON.stringify(tmpIds)
    }, response => {
      const fieldIndex = this.state.fields.findIndex(f => {
        return f.id === response.field.id;
      });

      const fields = [...this.state.fields];
      fields[fieldIndex] = response.field;
      fields[fieldIndex].loading = false;

      let validations = [...fields[fieldIndex].additional.validations];
      validations.unshift("required");
      fields[fieldIndex].additional.validations = [...validations];
      fields[fieldIndex].lastupdate = this.calculateUpdateTime(fields[fieldIndex]);

      this.setState({fields: fields, errorUpload: null});
      this.props.checkRemainingTime();
      callback();
    }, error => {
      if (error.code === 127){
        this.setState({
          errorUpload: error.message
        });
      }
      this.setFieldLoading(fieldId, false);
    });
  }

  handleChange(id, text, callback) {

    this.setFieldLoading(id, true);
    XServices.post('request/field/change', {
        requestId: this.props.id,
        fieldId: id,
        comment: text
    }, response => {
      const fieldIndex = this.state.fields.findIndex(f => {
        return f.id === id;
      });

      const fields = [...this.state.fields];
      fields[fieldIndex].requirechange = 'S';
      fields[fieldIndex].comment = text;

      this.setState({fields: fields});
      this.setFieldLoading(id, false);
      this.props.checkRemainingTime();
      callback();
    }, error => {
      this.setFieldLoading(id, false);
    });
  }

  handleAddField(fields) {
    let newFields = [...this.state.fields];
    for (let field of fields) {
      let validations = [...field.additional.validations];
      validations.unshift("required");
      field.additional.validations = [...validations];
      field.lastupdate = this.calculateUpdateTime(field);

      newFields.push(field);
    }
    this.setState({fields: newFields});
    this.props.checkRemainingTime();
  }

  removeMessageError(){
    if(this.state.errorUpload !== null){ this.setState({errorUpload: null});}
  }

  /**
   * Calculates the time in which a given field was updated, taking into account the server and local times.
   */
  calculateUpdateTime(field) {

    let now = moment();

    let fieldUpdateTime = field.lastmodifydate || field.createdate;
    let localUpdateDifference = now.diff(fieldUpdateTime);
    let totalDifference = Math.abs(localUpdateDifference + this.state.localServerDifference);

    return moment.duration(totalDifference).humanize(); 
  }

  render() {
    const { role, id, readonly, fieldsChange, handleFieldHasChange } = this.props;
    const { fields, errorUpload } = this.state;

    if (fieldsChange){
      handleFieldHasChange();
      this.handleServiceFields();
    };

    let items;
    if (fields) {
      items = fields.map((item, i) =>
        <RequestField
          key={i}
          data={item}
          requestId={id}
          role={role}
          readonly={readonly}
          handleSave={this.handleSave}
          handleSaveFile={this.handleSaveFile}
          handleCancelAntecedent={this.handleCancelAntecedent}
          errorUpload={errorUpload}
          removeMessageError={this.removeMessageError}
          handleChange={this.handleChange}/>
      );
    }
    return (
        <div>
          {items}
          {role === 'WKR' && !readonly && <AskNewField id={id} handleAddField={this.handleAddField}/>}
        </div>
    );
  }
}

export default TabField;
