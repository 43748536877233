import React, { Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';

//Components
import Typography from '../../components/Typography/Typography';
import Avatar from '../../components/Avatar/Avatar';

//Ui
import { Container, Button } from 'reactstrap';

const Checkout = props => {
    return(
        <AuthConsumer>
            {({ user: { img_url, name }})=> (
                <Fragment>
                <section className="checkout-wrapper">
                    <Container>
                        <div className="checkout-wrapper-item">
                            <Typography variant="h1">Excelente {name}</Typography>
                            <Typography variant="h2">tu pago ha sido <Typography color="success">exitoso :)</Typography></Typography>
                            <Button className="link" color="link">Revisar mi trámite <FontAwesomeIcon icon={faArrowRight}/></Button>
                        </div>
                        <div className="checkout-wrapper-item">
                            <div className="card">
                                <div className="card-item">
                                    <Avatar url={img_url} name={name} size="md" variant="primary"/>
                                </div>
                                <div className="card-item">
                                    <Typography variant="h5">Contrato de arriendo.</Typography>
                                    <Typography variant="p">24 horas</Typography>
                                    <Typography variant="p">Pago Plus</Typography>
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                <section className="back-wrapper">
                    <Container>
                        <div className="checkout-wrapper-item">
                            <Typography variant="h5">Gracias {name} por preferir <Typography variant="strong">rev-u.com</Typography></Typography>
                            <Typography variant="h6">
                                Verifica el estado de tu trámite en la sección Mi Cuenta o puedes volver al incio presionando el siguiente botón.
                            </Typography>
                            <Button color="info" size="lg"> Volver al Inicio!</Button>
                        </div>
                        <div className="checkout-wrapper-item">
                        </div>
                    </Container>
                </section>
            </Fragment>
            )}
        </AuthConsumer>
    );
};

export default Checkout;
