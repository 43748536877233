import React, { Component, Fragment } from 'react';
import XServices from '../../api/XServices';

import { Container, Alert, Row, Col } from 'reactstrap';
import Typography from '../../components/Typography/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import RankItem from '../../components/RankItem/RankItem';
import Lang from '../../api/Lang';
import UserInfo from '../../components/UserInfo/UserInfo';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';

class Profile extends Component {
    constructor() {
        super();
        this.state = {
            items: [],
            lastView: '',
            hasMore: false,
            loading: true,
            data: null,
            error: null
        };
        this.handleDataProfile = this.handleDataProfile.bind(this);
        this.handleReviews = this.handleReviews.bind(this);
        this.loadReviewsOlds = this.loadReviewsOlds.bind(this);
    };

    componentDidMount() {
        this.handleDataProfile();
        this.handleReviews(null);
    }

    handleDataProfile = () => {
        let that = this;
        XServices.get('account/info', {
            slug: this.props.match.params.slug
        }, function (response) {
            that.setState({ loading: false, data: response, error: null });
        }, function (error) {
            that.setState({ loading: false, error: error.message });
        });
    }

    handleReviews() {
        let that = this;
        XServices.get('account/reviews', {
            slug: this.props.match.params.slug,
            rnkId: null
        }, function (response) {
            that.setState({
                items: response.reviews,
                lastView: response.reviews.length > 0 ? response.reviews[response.reviews.length - 1].rnk_id : '',
                hasMore: response.hasMore
            });
        }, function (error) {
            that.setState({
                error: error.message
            });
        });
    }

    loadReviewsOlds(lastView) {
        let that = this;
        XServices.get('account/reviews', {
            slug: this.props.match.params.slug,
            rnkId: lastView
        }, function (response) {
            var last = response.reviews.length > 0 ? response.reviews[response.reviews.length - 1].rnk_id : that.state.lastView
            if (last !== that.state.lastView) {
                var newsAndOlds = [];
                const actual = [...that.state.items];
                newsAndOlds = actual.concat(response.reviews);
                that.setState({
                    items: newsAndOlds,
                    lastView: response.reviews.length > 0 ? response.reviews[response.reviews.length - 1].rnk_id : that.state.lastView,
                    hasMore: response.hasMore
                });
            }
        }, function (error) {
            that.setState({
                error: error.message
            });
        });
    }

    render() {
        const { loading, data, error, lastView } = this.state;
        return (
            <Fragment>
                {loading ? (
                    <div className="margin-top">
                        <Loading variant="component" color="danger" style={{ width: '3rem', height: '3rem' }} />
                    </div>
                ) : (
                        error !== null ? <Error type="danger" message={error} /> :
                            <Fragment>
                                <UserInfo data={data} viewrole="RQT" />
                                <Container>
                                    <div className="profile-main">
                                        <div className="profile-rank">
                                            <div className="profile-rank-left">
                                                <Typography variant="h1">{data.requests_quantity}</Typography>
                                                <Typography variant="small">
                                                    {Lang.get('PROFILE.REQUESTFINISHED')}
                                                </Typography>
                                            </div>
                                            <div className="profile-rank-right">
                                                <Typography variant="h1">{data.reviews_average}</Typography>
                                                <FontAwesomeIcon icon={faStar} className="filled" />
                                                <Typography variant="small">
                                                    {Lang.get('PROFILE.ASSESSMENTS.LEFT') + data.reviews_quantity + Lang.get('PROFILE.ASSESSMENTS.RIGTH')}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="profile-rank-list">
                                            {this.state.items.length > 0 ?
                                                this.state.items.map((item, index) =>
                                                    <RankItem
                                                        url={item.url}
                                                        name={item.name}
                                                        publish_date={Lang.get('PROFILE.PUBLISHED') + item.createdate  }
                                                        content={item.content}
                                                        readonly={true}
                                                        placeholder={parseFloat(item.value)}
                                                        key={index}
                                                    />
                                                )
                                                :
                                                <Alert color="primary">
                                                    <Row>
                                                        <Col xs={{ size: 1, offset: 1 }}>
                                                            <Typography variant="h1"><FontAwesomeIcon icon={faInfoCircle} /></Typography>
                                                        </Col>
                                                        <Col xs={{ size: 10 }}>
                                                            <Typography variant="h1">Info</Typography>
                                                            <Typography variant="p">{Lang.get('SEARCH.NORESULTS')}</Typography>
                                                        </Col>
                                                    </Row>
                                                </Alert>
                                            }
                                        </div>
                                        {this.state.hasMore ?
                                        <div className="profile-rank-footer" onClick={() => this.loadReviewsOlds(lastView)}>
                                            <Typography variant="p" >{Lang.get('PROFILE.MORECOMMENTS')} &darr;</Typography>
                                        </div>
                                        :
                                        <div className="profile-rank-footer off"></div>
                                        }
                                    </div>
                                </Container>
                            </Fragment>
                    )}
            </Fragment>
        );
    }
}

export default Profile;
