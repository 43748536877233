import React from 'react';
import defaultDocument from '../../resources/images/file-document.png';
import XServices from '../../api/XServices';
import { AuthConsumer } from '../../config/AuthContext';

//Components
import Typography from '../Typography/Typography';

//Ui
import { Button } from 'reactstrap';

function getSRCUrl(type, data, requestId, fieldId) {
  if (type === 'server') {
    //(string $token, string $id, string $field, string $file)
    return data.url + "?"
      + "id=" + requestId
      + "&field=" + fieldId
      + "&file=" + data.id
      + "&token=" + XServices.token();
  } else {
    return data.preview;
  }
}

function doClick(url) {
  window.open(url,'_blank');
}

function shortName(nameFile){
    let strnameFile = String(nameFile);
    return strnameFile.length > 25 ? strnameFile.substring(0, 20) + "..." + strnameFile.substring(strnameFile.length - 5, strnameFile.length) : strnameFile;
}

const FileItem = props => {
    const { data, id, type, editable, requestId, fieldId, color } = props;
    const url = getSRCUrl(type, data, requestId, fieldId);
    let className = "preview-image";

    if (!editable) {
      className += " pointer";
    }

    return (
        <div className="preview-image-wrap">
            <div className={className} onClick={() => !editable && doClick(url)}>
                <AuthConsumer>
                    {({isIE, isEdge}) => {
                        const compatibilty = isIE || isEdge;
                        const tplPreview = <div className="preview-ie" style={{ background: `url(${url}) center no-repeat`, backgroundSize: 'cover'}}></div>
                        switch(data.type) {
                            case 'image/png':
                                return !compatibilty ? <img src={url} alt={data.name}/> : tplPreview;
                            case 'image/jpg':
                                return !compatibilty ? <img src={url} alt={data.name}/> : tplPreview;
                            case 'image/jpeg':
                                return !compatibilty ? <img src={url} alt={data.name}/> : tplPreview;
                            default:
                                return <img src={defaultDocument} alt={data.name}/>;
                            }
                    }}
                </AuthConsumer>
            </div>
            <div className="preview-data">
                <div>
                    <Typography variant="small" color={color}>{shortName(data.name)}</Typography>
                </div>
                {editable && <Button color="link" onClick={()=> props.dispatch({action: 'DEL', id: id, type: type})}>Eliminar</Button>}
            </div>
        </div>
    )
};

export default FileItem;
