import React, { Component } from 'react';
import { AuthConsumer } from '../../../config/AuthContext';
import { Link , withRouter} from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Typography from '../../Typography/Typography';
import Lang from '../../../api/Lang';

import XServices from '../../../api/XServices';
import Loading from '../../Loading/Loading';

class Category extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            categories: [],
            error: null,
            ajaxRequest: null
        };
        this.getCategories = this.getCategories.bind(this);
        this.redirect = this.redirect.bind(this);
    };

    componentDidMount() {
        this.getCategories();
    }

    componentWillUnmount () {
        if (this.state.ajaxRequest !== null) {
            this.state.ajaxRequest.cancel();
        }
    }

    getCategories() {

        if (this.state.ajaxRequest !== null) {
            this.state.ajaxRequest.cancel();
        }

        this.setState({
            loading: true,
            error: null
        });
        let ajaxRequest = XServices.get('category/featured', {},
        response => {
            this.setState({
                loading: false,
                categories: response,
                error: null,
                ajaxRequest: null
            });
        }, error => {
            this.setState({
                loading: false,
                categories: [],
                error: { code: error.code, message: error.message },
                ajaxRequest: null
            });
        });

        this.setState({ajaxRequest: ajaxRequest});
    }

    redirect(slug) {
        let url = "/" + Lang.lancode + '/contract/' + slug;
        this.props.history.push(url);
    }

    render() {
        const { variant, type } = this.props;
        const { categories, loading } = this.state;
        
        let title = type === 'REQUESTED' ? 'CATEGORY.MOST.REQUESTED' : 'CATEGORY.FEATURED';
        let description = title + ".DESCRIPTION";
        return (
            <AuthConsumer>
                {({isIE})=> {
                    return (
                        variant === 'home' ?
                            (loading || categories.length === 0 ? <Row><Col xs="12"></Col></Row> : <section className="area-wrapper-category">
                                <Container className="title-wrapper-category">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <Typography variant="h3">
                                                {Lang.get("CATEGORY.TITLE.MENU")} 
                                            </Typography>
                                            <Typography variant="h3">
                                                {Lang.get(title)}
                                            </Typography>
                                        </Col>
                                        <Col xs="12" sm="6" className="area-link">
                                            <Link to={`/${Lang.lancode}/contract`}>{Lang.get('CATEGORY.ALLCONTRACT')}</Link>
                                        </Col>
                                    </Row>
                                    {loading ? <Row className="margin-bottom"><Loading variant="component" color="danger" /></Row> :
                                    <Row className={`category-list ${ variant !== 'home' && 'column'}`}>
                                        {categories.map((item, i) => (
                                            <div className="category-item" key={i}  onClick={() => this.redirect(item.slug)}>
                                                {!isIE ?
                                                    <div className="category-item-header">
                                                        <img src={'/assets/img/category/' + item.slug + '.jpg'} alt={item.slug}/>
                                                    </div> : 
                                                    <div
                                                        style={{background: `url(${item.image}) center center no-repeat`}}
                                                        className="category-item-header ie">
                                                    </div>
                                                }
                                                <div className="category-item-content">
                                                    <Typography variant="p">
                                                        {item.name}
                                                    </Typography>
                                                    <Typography variant="small">
                                                        {item.description}
                                                    </Typography>
                                                </div>
                                            </div>
                                        ))}
                                    </Row>}
                                </Container>
                            </section>):
                        (loading || categories.length === 0 ? <Row><Col xs="12"></Col></Row> : <section className="area-wrapper-category-list">
                            <div className="area-wrapper-title">
                                <Typography variant="h6">
                                    {Lang.get("CATEGORY.TITLE.MENU")} {Lang.get(title)}
                                </Typography>
                                <Typography variant="small">
                                    {Lang.get(description)}
                                </Typography>
                            </div>
                            {loading ? <Row className="margin-bottom"><Loading variant="component" color="danger" /></Row> :
                            <div className="area-wrapper-content">
                                {categories.map((item, i) => (
                                    <div className={`category-item ${isIE && 'ie'}`} key={i} onClick={() => this.redirect(item.slug)}>
                                        {!isIE ?
                                        <div className="item-image">
                                            <img src={'/assets/img/category/' + item.slug + '.jpg'} alt={item.slug}/>
                                        </div>:
                                        <div className="item-image ie" style={{background: `url(${item.image}) center center no-repeat`}}>
                                        </div>}
                                        <div className="item-content">
                                            <Typography variant="p">
                                                {item.name}
                                            </Typography>
                                            <Typography variant="small">
                                                {item.description}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                        </section>)
                    );
                }}
            </AuthConsumer>

        );
    }
};

export default withRouter(Category);