import React from 'react';

import ListRequestItem from './ListRequestItem/ListRequestItem';
import PaginationMod from '../../components/Pagination/Pagination';
import Lang from '../../api/Lang';
import PropTypes from 'prop-types';

const ListRequest = props => {
    const { requesting, handleToCheck, handleToPaid, handleToCloseRequest ,states, status, items, numberOfItems, onChangePage, initialPage, currentPage, pageSize, toggleModal, toggleToCloseRequest, assingRequest, alert_success } = props;
    return (
        <div className="maar-list">
            {items.map(item =>
                <ListRequestItem
                    status={status}
                    url={item.userImageUrl}
                    id={item.requestId}
                    name={(item.userName === null)  ? Lang.get('LISTREQUESTITEM.AVAILABLE') : item.userName}
                    created_date={item.createDate}
                    requirement={item.taskName}
                    priority={item.priority}
                    key={item.requestId}
                    time={item.time}
                    toggleModal={toggleModal}
                    toggleToCloseRequest={toggleToCloseRequest}
                    finishDate={item.finishDate}
                    assingRequest={assingRequest}
                    state={item.state}
                    alert_success={alert_success}
                    states={states}
                    isAvailable= {((item.userName === null) && (item.state === 'OPN')) ? true : false}
                    alreadyRanked={item.alreadyRanked}
                    alreadyHasClosingPetition={item.alreadyHasClosingPetition}
                    handleToPaid={handleToPaid}
                    requesting={requesting}
                    stateName={item.stateName}
                    assignDate={item.assignDate}
                    timeRemaining={item.timeRemaining}
                    timeAdditional={item.timeAdditional}
                    minutesRemaining={item.minutesRemaining}
                    rank={item.rank}
                    handleToCheck={handleToCheck}
                    handleToCloseRequest={handleToCloseRequest}
                    textWarrantyTime={item.textWarrantyTime}
                />
            )}
            <PaginationMod
                onChangePage={onChangePage}
                numberOfItems={numberOfItems}
                initialPage={initialPage}
                currentPage={currentPage}
                pageSize={pageSize}
            />

        </div>
    );
};

ListRequest.propTypes = {
    status: PropTypes.string.isRequired,
    numberOfItems: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    alert_success: PropTypes.bool
};

export default ListRequest;
