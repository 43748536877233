import Lang from "../api/Lang";
import { faFacebookF, faTwitter, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import posterHeroVideo from '../resources/images/video1b-min.jpg';
import posterHeroVideo2 from '../resources/images/video2-min.jpg';

export const Constants = {
    MIN_SEARCH_LENGTH: 3,
    HELP_TOOLTIP: {
        RANK: false
    },
    URL_SEPARATOR: "::",
    COUNTRY: {
        CL: {
            
            PHONE: '+56 9 6122 7066',
            WHATSAPP: '56961227066',
            EMAIL: 'contact@revu.cl'
        }
    },
    PAGES: ["ourteam", "speedup", "joinus", "monetize", "pressroom", "oportunities", "privacy", "terms", "faq"],
    HEADER: {
        MENU: [
            { link: '/ourteam', text: 'HOME.MENU.PRINCIPAL.US', type: 'internal'},
            { link: '/contract', text: 'HOME.MENU.PRINCIPAL.CONTRACT', type: 'internal'},
            { link: '/contact', text: 'HOME.MENU.PRINCIPAL.CONTACT', type: 'internal'},
        ]
    },
    FOOTER:
    {
        COPYRIGHT: '© 2020 REVU by <a href="https://www.tuxpan.com/" target="_blank" class="copyright-link">TUXPAN INGENIERIA SPA</a>',
        INLINE_MENU: [
            { link: '/privacy', text: 'FOOTER.PRIVACY', type: 'internal'},
            { link: '/terms', text: 'FOOTER.TERMS', type: 'internal'},
            //{ link: 'https://www.facebook.com/Revu-100446661698043', icon: faFacebookF, type: 'external'},
            { link: 'https://twitter.com/Revu_legal', icon: faTwitter, type: 'external'},
            { link: 'https://www.linkedin.com/company/revulegal', icon: faLinkedinIn, type: 'external'},
        ],
        MENU: [
            {
                title: 'FOOTER.PEOPLE',
                submenu: [
                    { text: 'FOOTER.REGISTER', type: 'register'},
                    { link: '/contract', text: 'HOME.MENU.PRINCIPAL.CONTRACT', type: 'internal'},
                    { link: '/faq', text: 'FOOTER.FAQ', type: 'internal'},
                    //{ link: '/speedup', text: 'FOOTER.SPEEDUP', type: 'internal'},
                    //{ link: '/joinus', text: 'FOOTER.JOINUS', type: 'internal'},
                    //{ link: '/monetize', text: 'FOOTER.MONETIZE', type: 'internal'}
                ]
            },
            {
                title: Lang.get('FOOTER.US'),
                submenu: [
                    { link: '/ourteam', text: 'FOOTER.US_ITEM', type: 'internal'},
                    { link: '/joinus', text: 'FOOTER.JOINUS', type: 'internal'},
                ]
            }
        ]
    },
    VIDEO: {
        HEADER: {
            url: 'https://www.youtube.com/embed/ldSNAwgk900',
            poster: posterHeroVideo,
        },
        FOOTER: {
           url: 'https://www.youtube.com/embed/pYAforno0Sw',
           poster: posterHeroVideo2,
        }
    }
};