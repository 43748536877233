import React, {Component} from 'react';

import { FormGroup, Label } from 'reactstrap';

//Ui
import HelpTooltip from '../HelpTooltip/HelpTooltip';
import InputField from '../InputField/InputField';
import Typography from '../Typography/Typography';

class NewRequestField extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {title, id, type, tooltip, additional, value, valid, message, mandatory, handleChangeInput, inProgress} = this.props;
    let inputId = "field-" + id;
    let requiredText = mandatory ? (<Typography  color="danger">&nbsp;*</Typography>) : null;

    let size="";

    return (
        <FormGroup>
            <Label for={inputId}>{title}{requiredText}</Label>
            <div className="btn-tooltip action-control">
              <HelpTooltip id={id} title={tooltip.title} description={tooltip.description}/>
            </div>
            <InputField
              inputId={inputId}
              id={id}
              type={type}
              additional={additional}
              mandatory={mandatory}
              handleChangeInput={handleChangeInput}
              valid={valid}
              value={value}
              message={message}
              size={size}
              feedback={true}
              disabled={inProgress}
             />
        </FormGroup>
    );
  }
};

export default NewRequestField;
