import React from 'react';
import PropTypes from 'prop-types';
import Lang from '../../api/Lang';
//Ui
import Typography from '../Typography/Typography';

const TimerWarranty = props => {
    const { warranty } = props;
    return (
        <div className="timer">
            <div>
                <Typography variant="small" color="muted">{Lang.get('TIMERWARRANTY.TITLE')}</Typography>
                <Typography variant="h3" color="primary">{warranty.text}</Typography>
            </div>
        </div>
    );
};

TimerWarranty.propTypes = {
    warranty: PropTypes.object.isRequired,
};

export default TimerWarranty;
