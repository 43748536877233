import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Lang from '../../api/Lang';

//Components
import Timer from '../Timer/Timer';
import TimerWarranty from '../TimerWarranty/TimerWarranty';

//Ui
import Typography from '../Typography/Typography';
import Loading from "../Loading/Loading";
import Breadcrumb from '../Breadcrumb/Breadcrumb';

class Status extends Component {

    constructor(props) {
        super(props);
        this.getBreadcrumb = this.getBreadcrumb.bind(this);
    };

    getBreadcrumb(id) {
        let breadcrumb = [];

        breadcrumb.push({
            link: "/" + Lang.lancode + '/' + (this.props.role === "WKR" ? "assigned" : "requested"),
            text: Lang.get('BREADCRUMB.' + (this.props.role === "WKR" ? "MYASSIGN" : "MYREQUEST"), [id])
        });

        breadcrumb.push({
            text: Lang.get('BREADCRUMB.REQUEST', [id])
        });

        return breadcrumb;
    }

    render() {
        const { data } = this.props;
        return (
            <section className="status">
                <div className="status-area-left">
                    <Breadcrumb data={this.getBreadcrumb(data.id)}/>
                    <div>
                        <Typography variant="h2">
                            #{data.id}
                        </Typography>
                        <Typography variant="p" color="muted">
                            {data.type}
                        </Typography>
                    </div>
                    {/*
                    <div className="status-box-request">
                        <div>
                            <StatusTag state={data.state} value={data.statename} />
                        </div>
                        <div>
                            {data.lastmodify && <Typography variant="p" color="muted">
                                {Lang.get('REQUEST.LASTUPDATE')} : {data.lastmodify}
                            </Typography>}
                        </div>
                    </div>
                    */}
                </div>
                <div className="status-area-right">
                    {data.loadingTimer ?
                        <Loading variant="button" color="danger" /> :
                        data.hasWarranty && (data.state === 'FNS' || data.state === 'RPN') ?
                            <TimerWarranty warranty={data.textWarrantyTime} />
                            :
                            data.state !== 'CLD' && data.state !== 'FNS' &&
                            <Timer rest={data.timeRemaining || Lang.get('LISTREQUESTITEM.UNINICIATED')} more={data.timeAdditional} hasAdditionalTime={data.hasAdditionalTime} />
                    }
                </div>
            </section>
        )
    };
}

Status.propTyoes = {
    id: PropTypes.string,
    type: PropTypes.string,
};

export default withRouter(Status);
