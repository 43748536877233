import React, { Component } from 'react';
import queryString from 'query-string';
import {withRouter, Link } from 'react-router-dom';

import Lang from '../../api/Lang';

//Ui
import Logo from '../../components/Logo/Logo';
import Typography from '../../components/Typography/Typography';

import { Button } from 'reactstrap';
import AccountMessages from './AccountMessages';

class AcountError extends Component {
    constructor(){
        super();
        this.state = {
            error: null
        }
        this.checkError = this.checkError.bind(this);
        this.redirectHome = this.redirectHome.bind(this);
    };

    componentDidMount(){
        const getParams = this.props.location.search;
        const params = queryString.parse(getParams);

        if (params.error !== undefined && params.error !== null) {
            const errorCode = this.checkError(params.error);
            this.setState({ error: errorCode});
        } else {
          this.redirectHome();
        }
    }

    redirectHome() {
      this.props.history.push('/' + Lang.lancode);
    }

    checkError(error) {
      const knownErrors = ["200", "201", "202", "500"];

      if (knownErrors.indexOf(error) === -1) {
        this.props.history.push('/' + Lang.lancode + '/account/login/error?error=500');
      }

      return knownErrors.indexOf(error) !== -1 ? error : "500";
    }

    render(){
        const { error } = this.state;
        return(
        <div className="account-recover-wrapper">
            <div className="account-recover-logo">
                <Logo variant="full"/>
                <AccountMessages title={Lang.get('SOCIAL.ERROR.' + error + '.TITLE')} message={Lang.get('SOCIAL.ERROR.' + error + '.MESSAGE')}/>
            </div>
        </div>
        );
    };
};

export default withRouter(AcountError);
