import React from 'react';

//Component
import Typography from '../Typography/Typography';
import Lang from '../../api/Lang';
import Avatar from '../Avatar/Avatar';

//UI
import { Button } from 'reactstrap';

const ShowNew = props => {
    const { data, handleAShowModal } = props
    return (
            <div className="notify-sticky-wrapper" id="notify-sticky-wrapper">
                <div className="notify-avatar">
                    <Avatar 
                        name={data.usr_name} 
                        size="sm" 
                        url={data.usr_img_url} 
                        variant="primary" />
                </div>
                <div className="notify-body">
                    <Typography variant="h6">
                        {Lang.get('NEWREQUESTMODAL.BOTTOM.TITLE')}
                    </Typography>
                    <Typography variant="strong">
                       {data.tkk_name}
                    </Typography>
                </div>
                <div className="notify-action">
                    <Button color="danger" size="sm" name={data.rqt_id} onClick={() => handleAShowModal(data.rqt_id)}>
                        {Lang.get('NEWREQUESTMODAL.BTNSHOW')}
                    </Button>
                </div>
            </div>
    );
};

export default ShowNew;