import React, { Component, Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';

//Ui
import { Button, Form, FormGroup, Label } from 'reactstrap';
import Typography from '../Typography/Typography';
import XServices from '../../api/XServices';
import Lang from '../../api/Lang';
import Validate from '../../api/Validate';
import InputField from '../InputField/InputField';
import { Alert } from 'reactstrap';

class Recover extends Component {
    constructor() {
        super();
        this.state = {
            requesting: false,
            issubmited: false,
            error: '',
            messageSent: '',
            isFormValid: true,
            fields: [
                {
                    id: 'recover-email',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                        'required',
                        'email'
                    ]
                }
            ]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    };

    handleChange = (value, id) => {
        if (this.error !== ''){ this.setState({error: ''})}
        
        const fieldIndex = this.state.fields.findIndex(f => {
            return f.id === id;
        });

        let fields = [...this.state.fields];
        fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);

        this.setState({ fields: fields });
    };

    handleSubmit = e => {
        e.preventDefault();
        let fields = this.validateForm();

        if (!this.state.issubmited) {
            this.setState({ issubmited: true });
        }

        if (!Validate.validForm(fields)) {
            this.setState({ isFormValid: false });
            return;
        }

        this.setState({
            requesting: true,
            error: '',
            messageSent: ''
        });

        XServices.get('/account/restorePassword',
            { email: this.state.fields[0].value },
            response => {
                this.setState({
                    messageSent: response.message,
                    isFormValid: true
                });
            },
            error => {
                this.setState({
                    requesting: false,
                    error: error.message,
                    isFormValid: false
                });
            });
    };

    validateForm = () => {
        const fields = Validate.validateForm(this.state.fields);
        this.setState({ fields: fields });
        return fields;
    }

    handleClose = () => {
        this.context.dispatch({ action: 'close-login' });
    }

    render() {
        const { requesting, fields, issubmited, isFormValid, error } = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                {!issubmited ?
                    <FormGroup>
                        <Label for="recover-email">{Lang.get('FORGOT.SUBTITLE')}</Label>
                        <InputField handleChangeInput={this.handleChange} inputId="recover-email" id="recover-email" type="T" valid={fields[0].valid || !issubmited} size="lg" message={fields[0].message} feedback={issubmited} disabled={requesting} />
                    </FormGroup>
                    :
                    <Fragment>
                        {isFormValid ?
                            <Alert color="success" className="alert-custom" close="none">
                                < div className="alert-message">
                                    <Typography variant="p">{Lang.get("FORGOT.SUCESS.A")}
                                        <Typography variant="strong">{fields[0].value}</Typography>
                                        {Lang.get("FORGOT.SUCESS.B")}</Typography>
                                </div>
                            </Alert >
                            :
                            <Fragment>
                                <FormGroup>
                                    <Label for="recover-email">{Lang.get('FORGOT.SUBTITLE')}</Label>
                                    <InputField handleChangeInput={this.handleChange} inputId="recover-email" id="recover-email" type="T" valid={fields[0].valid || !issubmited} size="lg" message={fields[0].message} feedback={issubmited} disabled={requesting} value={fields[0].value} />
                                </FormGroup>
                                {error !== '' &&
                                    <Alert color="danger" className="alert-custom" close="none">
                                        <Typography variant="p">{error}</Typography>
                                    </Alert >
                                }
                            </ Fragment>
                        }
                    </Fragment>
                }
                <div className="helper-text-register">
                    <Typography variant="p">{Lang.get('FORGOT.ALREADYHAVEACCOUNT')}</Typography>
                    <Button color="link" type="button" onClick={() => this.props.handleClick('signin')}>
                        {Lang.get('FORGOT.ENTER')}
                    </Button>
                </div>
                {!issubmited ?
                    <FormGroup>
                        <Button type="submit" size="lg" block color="danger" disabled={Boolean(requesting)}>{Lang.get('FORGOT.RECOVER')}</Button>
                    </FormGroup>
                    :
                    <Fragment>
                        {!isFormValid ?
                            <FormGroup>
                                < Button type="submit" size="lg" block color="danger" disabled={Boolean(requesting)}>{Lang.get('FORGOT.RECOVER')}</Button>
                            </FormGroup >
                            :
                            <FormGroup>
                                <Button size="lg" block color="danger" onClick={this.handleClose}>{Lang.get('ACOOUNT.CLOSE')}</Button>
                            </FormGroup>
                        }
                    </Fragment>
                }
                <div className="helper-text-register">
                    <Typography variant="p">{Lang.get('FORGOT.DONTHAVEACCOUNT')}</Typography>
                    <Button color="link" type="button" onClick={() => this.props.handleClick('register')}>
                        {Lang.get('FORGOT.REGISTER')}
                    </Button>
                </div>
            </Form >
        );
    };
};
Recover.contextType = AuthConsumer;
export default Recover;