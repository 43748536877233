import moment from 'moment';
import 'moment/locale/es'

function DateUtilities () {
  this.datetimeInFormat = 'YYYY-MM-DD hh:mm:ss';
  this.dateInFormat = 'YYYY-MM-DD';
  this.dateOutFormat = 'DD-MM-YYYY';
  this.getTimeDifference = function (date) {
    return moment(date, this.datetimeInFormat).fromNow();
  };
  this.format = function (date) {
    return date !== undefined && date !== null ? moment(date, this.dateInFormat).format(this.dateOutFormat) : '';
  };
  this.getTimeRemaining = function(created_date, adicional, time){
    var creacion = moment(created_date).format(this.datetimeInFormat);
    var estimado = adicional > 0 ? adicional + time : time;
    var tiempo = moment(creacion).add(estimado, 'hour').format(this.datetimeInFormat);
    var dias = moment.duration(moment(tiempo).diff(moment())).format('d') > 0 ? moment.duration(moment(tiempo).diff(moment())).format('d') + 'dias ' : ' ';
    var hour = moment().hours() - moment(tiempo).hours() > 0 ? moment().hours() - moment(tiempo).hours()  + 'hrs ' : '';
    var minutes = moment().minutes() - moment(tiempo).minutes() > 0 ? moment().minutes() - moment(tiempo).minutes()  + 'min' : '';
    return dias + hour + minutes;
  }
  this.getPublished = function (date_create){
    var retorno= '';
    if (moment().format(this.dateInFormat) === moment(date_create).format(this.dateInFormat)){
      retorno = moment(date_create).format('hh:mm');
    }else{
      retorno = moment(date_create).format(this.dateOutFormat);
    }
    return retorno;
  }
  this.isToday = function(date_create){
    return (moment().format(this.dateInFormat) === moment(date_create).format(this.dateInFormat))
  }
}

const DateUtils = new DateUtilities();

export default DateUtils;
