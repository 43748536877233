import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

import DynamicContent from '../../components/DynamicContent/DynamicContent';

class DynamicPage extends Component { 
    render(){
        return <DynamicContent type={this.props.match.params.pagename}/>;
    }
}

export default withRouter(DynamicPage);