import React, {Component, Fragment} from 'react';
import { AuthConsumer } from '../../config/AuthContext';
import { Label, Input, Col} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPaperclip, faTimes} from '@fortawesome/free-solid-svg-icons';

class NewRequestFieldFile extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.handleChangeInput(event.target.value, this.props.id);
  }

  getFilename(fullPath) {
    const startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
    let filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
    }
    return filename;
  }

  render() {
    const {title, id, value, inProgress, handleRemoveFile} = this.props;
    let inputId = "field-" + id;

    return (
      <AuthConsumer>
          {({ isAuth })=> (
            <Fragment>
            {isAuth ?
                <Col md="6" className="file-wrapper">
                  <Label for={inputId}><FontAwesomeIcon icon={faPaperclip}/>&nbsp;{title}</Label> <strong>(opcional)</strong>
                  <Input type="file" name={inputId} id={inputId} onChange={this.handleChange} accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" disabled={inProgress}/>
                  {value && <div className="file-uploaded">
                    <span className="text-success"><FontAwesomeIcon icon={faCheck}/></span>{this.getFilename(value)}
                    <span className="text-danger spacing-left" onClick={() => handleRemoveFile(id)}><FontAwesomeIcon icon={faTimes}/></span>
                  </div>}
                </Col> :
                <Col md="6">
                  <Label for={inputId}>-&nbsp;{title}</Label> <strong>(opcional)</strong>
                </Col>
              }
            </Fragment>
          )}
      </AuthConsumer>
    );
  }
};

export default NewRequestFieldFile;
