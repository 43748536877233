import React from 'react';
import PropTypes from 'prop-types';

const Typography = props => {
    const { variant, children, color, asHTML } = props;

    let classColor = color !== undefined ? 'text-' + color : null;
    let body = (asHTML !== undefined && asHTML === true) ? <span dangerouslySetInnerHTML={{__html: children}}></span> : children;
    switch(variant) {
        case 'h1':
            return <h1 className={classColor}>{children}</h1>;
        case 'h2':
            return <h2 className={classColor}>{children}</h2>;
        case 'h3':
            return <h3 className={classColor}>{children}</h3>;
        case 'h4':
            return <h4 className={classColor}>{children}</h4>;
        case 'h5':
            return <h5 className={classColor}>{children}</h5>;
        case 'h6':
            return <h6 className={classColor}>{children}</h6>;
        case 'p':
            return <p className={classColor}>{body}</p>;
        case 'small':
            return <small className={classColor}>{children}</small>;
        case 'strong':
            return <strong className={classColor}>{children}</strong>;
        default : {
            return <span className={classColor}>{body}</span>;
        }
    };
};

Typography.propTypes = {
    variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "small", "strong", "error"]),
    color: PropTypes.oneOf(["danger", "secondary", "primary", "success", "warning", "info", "muted", "inherit"]),
    asHTML: PropTypes.bool
};
export default Typography;
