import React, { Component, Fragment } from 'react';
import XServices from '../../api/XServices';
import Lang from '../../api/Lang';

//Components
import Category from '../../components/Home/Category/Category';
import Loading from "../../components/Loading/Loading";
import NewRequest from '../NewRequest/NewRequest';
import TasksItem from '../../components/Category/TasksItem';
import CategoryHeader from '../../components/Category/CategoryHeader';
import Typography from '../../components/Typography/Typography';

class TasksCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            requesting: false,
            tasks: [],
            categories: [],
            name: null,
            description: '',
            slugs: null,
            idToContract: '',
            modal: false,
            ajaxRequest: null
        };
        this.getTasksCategory = this.getTasksCategory.bind(this);
        this.callModalContract = this.callModalContract.bind(this);
        this.handleCreated = this.handleCreated.bind(this);
        this.closeModal = this.closeModal.bind(this);
    };

    componentDidMount() {
        this.getTasksCategory(this.props.match.params.id);
    }

    componentWillUnmount () {
        if (this.state.ajaxRequest !== null) {
            this.state.ajaxRequest.cancel();
        }
    }

    componentDidUpdate() {
        if (this.state.slugs !== this.props.match.params.id) {
            this.getTasksCategory(this.props.match.params.id);
        }
    }

    getTasksCategory(slugs) {
        if (this.state.ajaxRequest !== null) {
            this.state.ajaxRequest.cancel();
        }

        this.setState({
            loading: true,
            requesting: true,
            error: null,
            slugs: slugs
        });

        let ajaxRequest = XServices.get('task/search/category', {
            slug: slugs
        },
        response => {
            this.setState({
                loading: false,
                requesting: false,
                categories: response.categories,
                tasks: response.tasks,
                name: response.name,
                description: response.description,
                ajaxRequest: null
            });
        }, error => {
            console.log(error);
            this.setState({
                loading: false,
                requesting: false,
                error: { code: error.code, message: error.message },
                ajaxRequest: null
            });
        });
        this.setState({ajaxRequest: ajaxRequest});
    }

    callModalContract = id => {
        this.setState({ idToContract: id, modal: true });
    }

    handleCreated = (response) => {
        if (response.url !== null) {
            window.location = response.url;
        }
    };

    closeModal() {
        this.setState({ modal: false, idToContract: '' })
    }

    render() {
        const { modal, loading, tasks, requesting, slugs, categories } = this.state;
        let resultTitle = '';
        if (categories.length === 0) {
            resultTitle = 'CATEGORY.CATEGORY.NOTFOUND';
        } else if (tasks.length === 0) {
            resultTitle = 'CATEGORY.TASK.NOTFOUND';
        } else {
            resultTitle = 'CATEGORY.TOTALFOUND';
        }
        return (
            <Fragment>
                <div className="mt-4">
                    <Typography variant="h1">{Lang.get('CATEGORY.TITLE.HEADER')}</Typography>
                </div>
                {loading ? <Loading variant="fullscreen" /> : 
                <Fragment>
                    <CategoryHeader slugs={slugs} categories={categories} />
                    <div>
                        <div className="mb-4">
                            <Typography variant="p">{Lang.get(resultTitle, [tasks.length])}</Typography>
                        </div>
                        {tasks.map((item, i) => (
                            <TasksItem
                                id={item.id}
                                name={item.name}
                                description={item.description}
                                callModalContract={this.callModalContract}
                                requesting={requesting}
                                key={i}
                                tags={item.tags}
                                prices={item.prices}
                            />
                        ))}
                        {modal && <NewRequest open={modal} toggle={() => this.closeModal()} handleCreated={this.handleCreated} id={this.state.idToContract} />}
                        <Category variant="category"/>
                    </div>
                </Fragment>
                }
            </Fragment>
        );
    };
};

export default TasksCategory;