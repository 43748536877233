import React, { Component, Fragment } from 'react';
import classnames from 'classnames';

import XServices from '../../api/XServices';
import Lang from '../../api/Lang';

//components
import Typography from '../Typography/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import Validate from '../../api/Validate';
//Ui
import { Button, Form, Col } from 'reactstrap';
import InputField from '../InputField/InputField';
import Loading from '../Loading/Loading';
import CustomAlert from '../Alert/CustomAlert';

class TabDocument extends Component {

  constructor() {
    super();
    this.state = {
      generating: false,
      uploading: false,
      sending: false,
      errorGenerating: '',
      errorUploading: '',
      errorSending: '',
      errorSendingRevision: '',
      successSending: '',
      systemDoc: null,
      userDoc: null,
      showRevision: false,
      error: '',
      messageSendingRevision: '',
      requesting: false,
      inRevision: false,
      textInRevision: '',
      idDocInRevision: '',
      isNotSameDocument: true,
      isUpLoad: false,
      hasWarranty: false,
      fields: [
        {
          id: 'revision-comment',
          value: '',
          valid: true,
          message: '',
          validations: [
            "required",
            {
              "name": "maxlength",
              "params": [512]
            }
          ]
        }
      ]
    }
    this.uploadRef = React.createRef();
    this.handleGenerate = this.handleGenerate.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleSentReview = this.handleSentReview.bind(this);
    this.getDownloadUrl = this.getDownloadUrl.bind(this);
    this.handleRevision = this.handleRevision.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSendRevision = this.handleSendRevision.bind(this);
    this.closeRevision = this.closeRevision.bind(this);
  }

  componentDidMount() {
    let that = this;
    XServices.get('request/doc', {
      id: this.props.id
    }, response => {
      that.setState({
        systemDoc: response.system,
        userDoc: response.user,
        inRevision: response.revision !== null ? true : false,
        textInRevision: response.revision !== null ? response.revision.text : '',
        idDocInRevision: response.revision !== null ? response.revision.doc : '',
        isNotSameDocument: response.revision !== null ? response.revision.doc !== response.user.id ? true : false : true,
      });
    });
  }

  componentDidUpdate() {
    if (this.props.revisionText !== null && (this.state.textInRevision !== this.props.revisionText)) {
      this.setState({ 
        textInRevision: this.props.revisionText, 
        inRevision: true
       });
    }
  }

  handleSentReview() {
    this.setState({ sending: true, errorSending: '', inRevision: false });
    XServices.post('request/sent/review', {
      id: this.props.id
    }, response => {
      this.setState({ sending: false, successSending: response.message, textInRevision: '', isUpLoad: false, inRevision: false, isNotSameDocument: false });
      this.props.handleChangeState(response);
    }, error => {
      this.setState({ sending: false, errorSending: error.message });
    });
  }

  handleGenerate() {
    this.setState({ generating: true, errorGenerating: '' });
    XServices.post('request/doc/generate', {
      id: this.props.id
    }, response => {
      this.setState({ generating: false, systemDoc: response });
      document.getElementById("btnDownloadGenerated").click();
    }, error => {
      this.setState({ generating: false, errorGenerating: error.message });
    });
  };

  handleUpload() {
    var data = new FormData();
    data.append('files', document.getElementById('requestNewFile').files[0]);
    data.append('token', XServices.token());
    data.append('id', this.props.id);

    this.setState({ uploading: true, errorUploading: '' });

    XServices.upload('request/doc/upload',
      data
      , response => {
        this.setState({ uploading: false, userDoc: response, isUpLoad: true, isNotSameDocument: this.state.idDocInRevision !== response.id });
      }, error => {
        this.setState({ uploading: false, errorUploading: error.message, isUpLoad: false });
      });
  }

  getDownloadUrl(doc) {
    return doc.url + "?"
      + "id=" + this.props.id
      + "&doc=" + doc.id
      + "&type=" + doc.type
      + "&token=" + XServices.token();
  }

  getFilename(filename) {
    return filename.length > 45 ? filename.substring(0, 42) + "..." + filename.substring(filename.length - 5, filename.length) : filename;
  }

  handleRevision() {
    let fields = [...this.state.fields];
    fields.map((item, i) => (
      item.value = ''
    ));
    this.setState({
      showRevision: !this.state.showRevision,
      fields: fields,
      requesting: false
    });
  }

  handleSendRevision = e => {
    e.preventDefault();
    let fields = this.validateForm();

    if (!this.state.sending) {
      this.setState({ sending: true });
    }

    if (!Validate.validForm(fields)) {
      return;
    }
    this.setState({ requesting: true, errorSending: '' });

    XServices.post('request/revision/create', {
      requestId: this.props.id,
      text: this.state.fields[0].value
    }, response => {
      let textInRevision = this.state.fields[0].value;
      let fields = [...this.state.fields];
      fields.map((item, i) => (
        item.value = ''
      ));
      this.setState({
        errorSendingRevision: '',
        messageSendingRevision: response.message,
        textInRevision: textInRevision,
        showRevision: false,
        sending: false,
        fields: fields,
        inRevision: true,
        isUpLoad: false,
        //requesting: false
      });
      this.props.handleChangeState(response);
    }, error => {
      this.setState({
        requesting: false,
        errorSendingRevision: error.message,
        messageSendingRevision: '',
        inRevision: false,
        isUpLoad: false
      });
    });
  }

  closeRevision() {
    if (this.state.requesting) {
      this.setState({
        requesting: false,
        showRevision: false
      });
    }
  }

  handleChange = (value, id) => {
    const fieldIndex = this.state.fields.findIndex(f => {
      return f.id === id;
    });

    let fields = [...this.state.fields];
    fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);
    this.setState({ fields: fields });
  };

  validateForm = () => {
    const fields = Validate.validateForm(this.state.fields);
    this.setState({ fields: fields });
    return fields;
  }

  render() {
    const { requesting, error, generating, errorGenerating, uploading, errorUploading, sending, errorSending}  = this.state;
    const { systemDoc, userDoc, successSending, showRevision, errorSendingRevision, fields, isNotSameDocument } = this.state;
    const { role, state, hasWarranty, isInTimeWarranty, isWidget } = this.props;
    return (
      <div className={classnames("request-doc-wrapper", isWidget && 'request-doc-widget')}>
        {userDoc && <div className={this.state.inRevision ? `request-last-wrapper withRevision` : `request-last-wrapper`}>
          <div className="item">
            <Typography variant="p" color="muted">{Lang.get("REQUEST.DOCUMENT.READYTOSEND")}</Typography>
            <Typography variant="h5">{Lang.get("REQUEST.DOCUMENT.LASTUPLOADED")}</Typography>
            {<Button className="link break-word" color="link" href={this.getDownloadUrl(userDoc)} target="_blank">{this.getFilename(userDoc.name)}</Button>}
            {state === 'RPN' && this.state.inRevision && 
              <div className="in-revision">
                <Typography variant="h5" color="danger">{Lang.get("REVISION.DESCRIPTION.TITLE")}</Typography>
                <Typography variant="p">{this.state.textInRevision}</Typography>
              </div>
            }
            {errorSending !== '' && <Typography color="danger">{errorSending}</Typography>}
          </div>
          {role === "WKR" && (state === 'OPN' || state === 'RPN') && isNotSameDocument &&
            <div className="item">
              <Button type="button" size="lg" color="danger" onClick={this.handleSentReview} disabled={sending}>
                {!sending ? Lang.get("REQUEST.DOCUMENT.SENT") : Lang.get("REQUEST.DOCUMENT.SENDING")}
              </Button>
            </div>
          }
        </div>}
        {(role === 'RQT' && state === 'FNS' && hasWarranty) &&
          <div className={classnames(isInTimeWarranty ? `bbr-item request-last-wrapper no-bottom` : `bbr-item request-last-wrapper no-bottom disableddiv`, showRevision && 'revision-open')}>
            <div className="bbr-text">
              <Typography variant="h4">{Lang.get("REVISION.TITLE")}{!isInTimeWarranty && Lang.get("REVISION.SUBTITLE")}</Typography>
            </div>
            <div className="bbr-button">
              <Button color="secondary" size="lg" onClick={this.handleRevision} >{this.state.showRevision ? Lang.get("REVISION.BTN.CLOSE") : Lang.get("REVISION.BTN.NEW")}</Button>
            </div>
          </div>
        }
        {role === 'RQT' && state === 'FNS' && showRevision &&
          <div className="request-last-wrapper revision">
            <Fragment>
              <Form onSubmit={this.handleSendRevision} inline>
                <div className="item">
                  <InputField
                    handleChangeInput={this.handleChange}
                    inputId="revision-comment"
                    id="revision-comment"
                    type="A"
                    valid={fields[0].valid || !sending}
                    message={fields[0].message}
                    feedback={sending}
                    disabled={requesting} 
                    value={fields[0].value}
                    placeholder={Lang.get("REVISION.PLACEHOLDER")}
                  />
                </div>
                {error !== '' && <Typography variant="strong" color="danger">{error}</Typography>}
                {errorSendingRevision !== '' && <Typography color="danger">{errorSendingRevision}</Typography>}
              </Form>
              <div className="row">
                <Col xs="12" className="d-flex justify-content-end revision-action">
                  <Button className="mr-3" size="md" onClick={this.handleRevision}>{Lang.get('REVISION.BTN.CANCEL')}</Button>
                  <Button color="danger" size="md" disabled={requesting} onClick={this.handleSendRevision}>{Lang.get('REVISION.BTN.SAVE')}</Button>
                </Col>
              </div>
            </Fragment>
          </div>
        }
        {requesting && <CustomAlert type="success" message={this.state.messageSendingRevision} asHTML={true} />}
        {successSending && <CustomAlert type="success" message={successSending} asHTML={true}/>}
        {role === "WKR" && (state === 'OPN' || state === 'RPN') &&
          <Fragment>
            <div className="request-last-up-down">
              <div className="item">
                <Typography variant="h5">{Lang.get("REQUEST.DOCUMENT.GENERATE")}</Typography>
                <Typography variant="p">{Lang.get("REQUEST.DOCUMENT.GENERATE.DESCRIPTION")}</Typography>
                {systemDoc && !generating &&
                  <Button id="btnDownloadGenerated" className="link" color="link" href={this.getDownloadUrl(systemDoc)} target="_blank">{Lang.get("REQUEST.DOCUMENT.GENERATE.DOWNLOAD")}</Button>
                }
                {errorGenerating !== '' && <Typography color="danger">{errorGenerating}</Typography>}
              </div>
              <div className="item">
                <div className="icon">
                  {!generating ?
                    <a onClick={this.handleGenerate} ><FontAwesomeIcon icon={faArrowCircleDown} /></a>
                    : <Loading variant="component" color="danger" />}
                </div>
              </div>
            </div>
            <div className="request-last-up-down">
              <div className="item">
                <Typography variant="h5">{Lang.get("REQUEST.DOCUMENT.UPLOAD")}</Typography>
                <Typography variant="p">{Lang.get("REQUEST.DOCUMENT.UPLOAD.DESCRIPTION")}</Typography>
                {errorUploading !== '' && <Typography color="danger">{errorUploading}</Typography>}
              </div>
              <div className="item">
                <div className="icon">
                  {!uploading ?
                    <a onClick={() => { this.uploadRef.current.click() }} ><FontAwesomeIcon icon={faArrowCircleUp} /></a>
                    : <Loading variant="component" color="danger" />}
                </div>
                <div className="d-none">
                  <input id="requestNewFile" type="file" ref={this.uploadRef} onChange={this.handleUpload} accept=".docx" />
                </div>
              </div>
            </div>
          </Fragment>}
      </div>
    )
  };
}

export default TabDocument;