import React, { Component } from 'react';
import 'babel-polyfill';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../resources/scss/index.scss';

//Functional Component
import PrivateRoute from '../config/PrivateRoute';
import PublicRoute from '../config/PublicRoute';

//Functional Component
import { Route } from 'react-router-dom';
import XServices from '../api/XServices';
import Lang from '../api/Lang';

//Ui
import LayoutPrivate from '../components/Layout/LayoutPrivate';
//Views
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Request from './Request/Request';
import MyAccount from './MyAccount/MyAccount';
import AllRequestAssigned from './MyAccountAllRequest/AllRequestAssigned';
import AllRequestAvaliable from './MyAccountAllRequest/AllRequestAvaliable';
import AllRequestRequested from './MyAccountAllRequest/AllRequestRequested';
import Checkout from './Checkout/Checkout';
import PaymentSuccess from './Payment/PaymentSuccess';
import PaymentError from './Payment/PaymentError';
import Profile from './Profile/Profile';
import AccountRecover from './Account/AccountRecover';
import AccountSocial from './Account/AccountSocial';
import AcountError from './Account/AcountError';
import TasksCategory from './Category/TasksCategory';
import CategoryAside from '../components/Category/CategoryAside';
import RequestUnsubscribe from './Request/RequestUnsubscribe';
import { WelcomeLandingPage } from './WelcomeLanding';

import MyAccountAllNotifications from './MyAccountAllNotifications/MyAccountAllNotifications';
import DynamicPage from './Content/DynamicPage';

//Adside Nav
import MyAccountAside from '../components/MyAccount/MyAccountAside';

//Heading
import MyAccountProfile from '../components/MyAccount/MyAccountProfile';
import MyAccountSuggested from '../components/MyAccount/MyAccountSuggested';

//Dynamic Footer
import RelatedSlider from '../components/RelatedSlider/RelatedSlider';

import { AuthContext } from '../config/AuthContext';

//Utils
import Cookie from '../api/Cookies';

XServices.baseURL = process.env.REACT_APP_API_URL;
XServices.country = process.env.REACT_APP_COUNTRY;

XServices.token = function () {
    const token = Cookie.get('token');
    return token !== null ? token : '';
};

Lang.loadFromUrl(window.location.pathname);
XServices.language = Lang.lancode;

class App extends Component {

  constructor(props) {
      super(props);
      this.handleExpired = this.handleExpired.bind(this);
      this.isFullscreen = this.isFullscreen.bind(this);
      XServices.onSecurityException = this.handleExpired;

      if (Lang.lancode === null) {
        window.location.replace("/" + Lang.default);
      }
  }

  componentDidMount(){
    let request = sessionStorage.getItem('newRequestLocal');
    if (request !== null) {
      const item = JSON.parse(request);
      if (item.id !== undefined) {
          this.context.dispatch({action: 'add', id: item.id});
      }
    }
  }

  handleExpired(response) {
    this.context.dispatch({action: 'close'});
    this.context.clear();
  }

  isFullscreen() {
    const match = [
      `/${Lang.lancode}/account/recover`,
      `/${Lang.lancode}/account/login/social`,
      `/${Lang.lancode}/account/login/error`,
      `/${Lang.lancode}/account/`,
      `/${Lang.lancode}/request/unsubscribe`
    ];
    let currentRoute = this.props.location.pathname;
    if (currentRoute.substr(-1) === '/') {
      currentRoute = currentRoute.substr(0, currentRoute.length -1);
    }
    
    for (let path of match) {
        if (currentRoute.includes(path)) {
            return true;
        }
    }

    return false;
  }

  render() {
    const fullscreen = this.isFullscreen() ? 'fullscreen centered': null;
    return (
        <main className={fullscreen}>
          <Switch>
            <Route exact path="/" render={() => (<Redirect to={`/` + Lang.default}/>)}/>
            <Route exact path="/:lang" component={Home}/>
            <Route exact path="/:lang/account/login/social" component={AccountSocial}/>
            <Route exact path="/:lang/account/login/error" component={AcountError}/>
            <Route exact path="/:lang/account/:token" component={AccountRecover}/>
            <Route exact path="/:lang/request/unsubscribe/:token" component={RequestUnsubscribe}/>
            <PublicRoute exact path="/:lang/contact" layout={LayoutPrivate} child={RelatedSlider} component={Contact} />
            <PublicRoute exact path="/:lang/contract/:id" layout={LayoutPrivate} child={RelatedSlider} component={TasksCategory} aside={CategoryAside} />
            <PublicRoute exact path="/:lang/contract" layout={LayoutPrivate} child={RelatedSlider} component={TasksCategory} aside={CategoryAside} />
            <PrivateRoute exact path="/:lang/request/:id" layout={LayoutPrivate} child={RelatedSlider} component={Request} />
            <PrivateRoute exact path="/:lang/request/:id/payment/success" layout={LayoutPrivate} child={RelatedSlider} component={PaymentSuccess} />
            <PrivateRoute exact path="/:lang/request/:id/payment/error" layout={LayoutPrivate} child={RelatedSlider} component={PaymentError} />
            <PrivateRoute exact path="/:lang/requested" layout={LayoutPrivate} child={RelatedSlider} component={AllRequestRequested} aside={MyAccountAside} heading={MyAccountProfile}/>
            <PrivateRoute exact path="/:lang/assigned" layout={LayoutPrivate} child={RelatedSlider} component={AllRequestAssigned} aside={MyAccountAside} heading={MyAccountProfile}/>
            <PrivateRoute exact path="/:lang/availables" layout={LayoutPrivate} child={RelatedSlider} component={AllRequestAvaliable} aside={MyAccountAside} heading={MyAccountProfile}/>
            <PrivateRoute exact path="/:lang/notifications" layout={LayoutPrivate} child={RelatedSlider} component={MyAccountAllNotifications} aside={MyAccountAside} heading={MyAccountProfile}/>
            <PrivateRoute exact path="/:lang/myaccount" layout={LayoutPrivate} child={RelatedSlider} component={MyAccount} aside={MyAccountAside} heading={MyAccountProfile}/>
            <PrivateRoute exact path="/:lang/checkout" layout={LayoutPrivate} child={RelatedSlider} component={Checkout}/>
            <PrivateRoute exact path="/:lang/profile/:slug" layout={LayoutPrivate} child={RelatedSlider} component={Profile}/>
            <PrivateRoute exact path="/:lang/welcome" layout={LayoutPrivate} child={RelatedSlider} component={WelcomeLandingPage} />
            <PublicRoute exact path="/:lang/:pagename" layout={LayoutPrivate} child={RelatedSlider} component={DynamicPage}/>
            <Route render={() => (<Redirect to="/"/>)}/>
          </Switch>
        </main>
    );
  }
}
App.contextType = AuthContext;
export default withRouter(App);
