import React from 'react';
import PropTypes from 'prop-types';
import { AuthConsumer } from '../../config/AuthContext';

import iconAvatar from '../../resources/images/avatar-default.png';
import iconAvatarWhite from '../../resources/images/avatar-default-2.png';

const Avatar = props => {
    const { url, size, name, className, variant } = props;
    const iconDefault = variant === 'primary' ? iconAvatar : iconAvatarWhite;
    return (
        <AuthConsumer>
            {({isIE, isEdge})=> {
                const compatibility = isEdge || isIE;
                return (
                    !compatibility ?
                    <div className={`avatar ${size} ${className ? className : null}`}>
                        <img src={url !== null ? url : iconDefault} alt={name}/>
                    </div>: 
                    <div 
                        style={{background: `url(${url !== null ? url : iconDefault}) center no-repeat`, backgroundSize:'cover'}}
                        className={`avatar ${size} ${className ? className : null}`}></div>
                );
            }}
        </AuthConsumer>
    );
};
Avatar.propTypes = {
    size: PropTypes.oneOf(["xs","sm", "md", "lg", "xlg"]),
    url: PropTypes.string,
    name: PropTypes.string,
};
export default Avatar;
