import React, { Component, Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';
import XServices from '../../api/XServices';
import Lang from '../../api/Lang';
import Validate from '../../api/Validate';

//Ui
import { Button, Form, FormGroup, Label } from 'reactstrap';
import Typography from '../Typography/Typography';
import InputField from '../InputField/InputField';
import Loading from '../Loading/Loading';

class Register extends Component {
    constructor(){
        super();
        this.state = {
            name: '',
            email: '',
            password: '',
            issubmited: false,
            requesting: false,
            error: '',
            fields: [
              {
                id: 'register-name',
                value: '',
                valid: true,
                message: '',
                validations: [
                  'required',
                  {
                    "name": "maxlength",
                    "params": [512]
                  }
                ]
              },
              {
                id: 'register-email',
                value: '',
                valid: true,
                message: '',
                validations: [
                  'required',
                  'email',
                  {
                    "name": "maxlength",
                    "params": [255]
                  }
                ]
              },
              {
                id: 'register-password',
                value: '',
                valid: true,
                message: '',
                validations: [
                  'required',
                  {
                      "name": "password",
                      "params": [8,16]
                  }
                ]
              }
            ]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange = (value, id) => {
        const fieldIndex = this.state.fields.findIndex(f => {
          return f.id === id;
        });

        let fields = [...this.state.fields];
        fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);

        this.setState({fields: fields});
    };

    handleSubmit = e => {
        e.preventDefault();

        let fields = this.validateForm();

        if (!this.state.issubmited) {
          this.setState({issubmited: true});
        }

        if (!Validate.validForm(fields)) {
          return;
        }

        this.setState({ requesting: true, error: ''});

        XServices.post('/account/register',
            {name: this.state.fields[0].value, email: this.state.fields[1].value, password: this.state.fields[2].value},
            response => {
                this.setState({requesting: false, error: ''});
                this.context.isLogin(response);
                // this.context.dispatch({action: 'close-login'});
                this.context.dispatch({action: 'close-register'});
            },
            error => {

              if (error.code === 122) {
                let fields = [...this.state.fields];
                fields[1].valid = false;
                fields[1].message = error.message;

                this.setState({
                  requesting: false,
                  fields: fields
                });

              } else {
                this.setState({
                  requesting: false,
                  error: error.message
                });
              }
            });
    };

    validateForm = () => {
        const fields = Validate.validateForm(this.state.fields);
        this.setState({fields: fields});
        return fields;
    }

    render(){
        const { fields, issubmited, requesting, error } = this.state;
        const { showLinkFooter} = this.props
        return(
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Label for="register-name">{Lang.get('REGISTER.NAME')}</Label>
                    <InputField handleChangeInput={this.handleChange} inputId="register-name" id="register-name" type="T" valid={fields[0].valid || !issubmited} size="lg" message={fields[0].message} feedback={issubmited} disabled={requesting}/>
                </FormGroup>
                <FormGroup>
                    <Label for="register-email">{Lang.get('LOGIN.EMAIL')}</Label>
                    <InputField handleChangeInput={this.handleChange} inputId="register-email" id="register-email" type="T" valid={fields[1].valid || !issubmited} size="lg" message={fields[1].message} feedback={issubmited} disabled={requesting}/>
                </FormGroup>
                <FormGroup>
                    <Label for="register-password">{Lang.get('LOGIN.PASSWORD')}</Label>
                    <InputField handleChangeInput={this.handleChange} inputId="register-password" id="register-password" type="P" valid={fields[2].valid || !issubmited} size="lg" message={fields[2].message} feedback={issubmited} disabled={requesting}/>
                </FormGroup>
                <FormGroup>
                    <Button type="submit" size="lg" block color="danger" disabled={requesting}>
                    {requesting ? <Fragment><Loading variant="component" color="white"/> {Lang.get('REGISTER.REGISTERING')}</Fragment> : <Fragment>{Lang.get('REGISTER.ENTER')}</Fragment>}
                    </Button>
                    {error !== '' && <Typography variant="span" color="danger">{error}</Typography>}
                </FormGroup>
                { showLinkFooter &&
                <div className="helper-text-register">
                    <Typography variant="p">{Lang.get('REGISTER.ALREADYHAVEACCOUNT')}</Typography>
                    <Button color="link" type="button" onClick={()=> this.props.handleClick('signin')}>
                        {Lang.get('LOGIN.SUBTITLE')}
                    </Button>
                </div>
                }
            </Form>
        );
    };
};
Register.contextType = AuthConsumer;
export default Register;
