import React from 'react';
import PropTypes from 'prop-types';
import StteperIcons from './StepperIcons';
import Typography from '../Typography/Typography';
const Stepper = props => {
    const { size, stage } = props;
    return (
        <div className={`stepper-wrapper ${size}`}>
            <div className="stepper-wraper-icons">
                {
                    stage.steps.map( (step, i) => {
                        return <StteperIcons key={i} value={step.number} message={step.message} status={Boolean(step.status)}/>
                    } )
                }
            </div>
            <div className="stepper-wraper-info">
                <Typography variant="p">{stage.current.name}</Typography>
                <Typography variant="small" color="muted">{stage.current.description}</Typography>
            </div>
        </div>
    );
};
Stepper.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
};
export default Stepper;
