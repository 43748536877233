import React from 'react';

import Typography from '../Typography/Typography';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const BannerButtonRequest = props => {
    const {text_request, handleClickRequestButton, showList} = props;
    return (
        <div className="bbr-wrapper">
            <div className="bbr-item">
                <div className="bbr-text">
                    <Typography variant="h4">{text_request}</Typography>
                </div>
                <div className="bbr-button">
                    <Button color="secondary" size="lg" onClick={handleClickRequestButton}>{showList ? 'Cerrar' : 'Nuevo'}</Button>
                </div>
            </div>
        </div>
    );
};

BannerButtonRequest.propTypes = {
    text_request:  PropTypes.string.isRequired
}

export default BannerButtonRequest;