function Codes () {
  this.default = 500;
  this.codes = {
    124: 404,
    125: 403
  };
  this.get = function (error) {
    return this.codes[error] !== undefined ? this.codes[error] : error
  }
}

const ErrorCode = new Codes();

export default ErrorCode;
