import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { Alert, Container, Row, Col } from 'reactstrap';

import Typography from '../Typography/Typography';

const Error = props => {
    const {type, message} = props;
    return (
      <Container>
        <Alert color={type}>
          <Row>
              <Col xs={{ size: 1, offset: 1 }}>
                <Typography variant="h1"><FontAwesomeIcon icon={faTimesCircle}/></Typography>
              </Col>
              <Col xs={{ size: 10}}>
                <Typography variant="h1">Error</Typography>
                <Typography variant="p">{message}</Typography>
              </Col>
        </Row>
        </Alert>
      </Container>
    );
};

Error.propTypes = {
    type:  PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
}

export default Error;
