import Rut from './Rut';
import rutRegex from 'rut-regex';
import Lang from './Lang';

/* eslint-disable */
function Validation () {

    this.messages = {
      default: "VALIDATE.ERROR.DEFAULT",
      numberWithDash: "VALIDATE.ERROR.NUMBERWITHDASH",
      passportFormat: "VALIDATE.ERROR.PASSPORTFORMAT",
      required: "VALIDATE.ERROR.REQUIRED",
      email: "VALIDATE.ERROR.EMAIL",
      url: "VALIDATE.ERROR.URL",
      maxlength: "VALIDATE.ERROR.MAXLENGTH",
      minlength: "VALIDATE.ERROR.MINLENGTH",
      rangelength: "VALIDATE.ERROR.RANGELENGTH",
      number: "VALIDATE.ERROR.NUMBER",
      decimal: "VALIDATE.ERROR.DECIMAL",
      currencyCLP: "VALIDATE.ERROR.CURRENCYCLP",
      digits: "VALIDATE.ERROR.DIGITS",
      rut: "VALIDATE.ERROR.RUT",
      date: "VALIDATE.ERROR.DATE",
      equal: "VALIDATE.ERROR.EQUAL",
      password: "VALIDATE.ERROR.PASSWORD",
      telegramAlias: "VALIDATE.ERROR.TELEGRAM_ALIAS"
    };

    this.validate = function (value, rules) {
      if (rules === undefined || rules === null) {
        return "";
      }

      const optional = rules.indexOf("required") === -1;

      let error = "";
      for (let item of rules) {
        let ruleName = typeof item === 'string' ? item : item.name;
        let params =  typeof item === 'string' ? [] : item.params;

        if (ruleName === "depends") {
          const isParentPresent = typeof params[0] === 'function' ? params[0]() : params[0] === value;
          if (isParentPresent) {
            ruleName = "required";
          } else {
            break;
          }
        }

        if (error === "" && this.methods[ruleName] !== undefined) {
          let valid = this.methods[ruleName](value, params, optional);

          if (!valid) {
            error = this.message(ruleName, params);
          }
        }
      };

      return error;
    }

    this.message = function (ruleName, params) {
      let message = Lang.get(this.messages[ruleName] !== undefined ? this.messages[ruleName] : this.messages["default"]);

      let i = 0;
      for (let item of params) {
        message = message.replace('{' + i + '}', item);
        i++;
      };

      return message;
    }

    this.addRules = function (name, message, callback, params) {
      this.methods[name] = function (value) {
        return callback(value, params);
      }
      this.messages[name] = message;
    }

    this.methods = {
        telegramAlias: function(value, params, isoptional) {

            return this.optional(value, isoptional) || /^[a-zA-Z0-9_]+$/.test(value);
        },
        length: function (value) {
          const type = typeof value;
          let length = null;
          switch (type) {
            case "boolean":
              length = value ? 1 : 0;
              break;
            default:
              length = value.toString().trim().length;
          }
          return length;
        },
        //new
        equal: function(value, params, isoptional){
          return value === params[0]() ;
        },
        //new
        optional: function (value, isoptional) {
          return isoptional && this.length(value) === 0;
        },
        // nuevo
        numberWithDash: function (value, params, isoptional) {
            return this.optional(value, isoptional) || /^((\d{0,}\-{1}\d{1,})|(\d))+$/.test( value );
        },
        passportFormat: function(value, params, isoptional) {
            return this.optional(value, isoptional) || /^[a-zA-Z0-9]+$/.test(value);
        },
        required: function (value) {
            return this.length(value) > 0;
        },
        email: function (value, params, isoptional) {
          return this.optional(value, isoptional) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
		     },
         url: function (value, params, isoptional) {
           return this.optional(value, isoptional) || /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test( value );
		     },
         maxlength: function (value, params, isoptional) {
            return this.optional(value, isoptional) || this.length(value) <= params[0];
         },
         minlength: function (value, params, isoptional) {
           return this.optional(value, isoptional) || this.length(value) >= params[0];
         },
         rangelength: function (value, params, isoptional) {
           return this.optional(value, isoptional) || (this.length(value) >= params[0] && this.length(value) <= params[1]);
         },
         number: function (value, params, isoptional) {
			      return this.optional(value, isoptional) || /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test( value );
         },
         decimal: function (value, params, isoptional) {
			      return this.optional(value, isoptional) || /^(?:\d{1,3}(?:\.\d{3})*|\d+)(?:\,\d+)?$/.test( value );
         },
         currencyCLP: function (value, params, isoptional) {
			      return this.optional(value, isoptional) || /^(?:\d{1,3}(?:\.\d{3})*|\d+)$/.test( value );
		     },
		     digits: function (value, params, isoptional) {
			      return this.optional(value, isoptional) || /^\d+$/.test(value);
		     },
         rut: function (value, params, isoptional) {
            return this.optional(value, isoptional) || (rutRegex({exact: true}).test(value) && Rut.validar(value));
         },
         password: function (value, params, isoptional) {
          return this.optional(value, isoptional) || (/^(?=(?:.*\d){1})(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})\S{8,16}$/.test(value));
         },
         date: function (value, params, isoptional) {
           let check = false;
           let re, adata, gg, mm, aaaa, xdata;
           
           const isIE = /*@cc_on!@*/false || !!document.documentMode;
           re = isIE ? /^\d{1,2}\-\d{1,2}-\d{4}$/ : /^\d{4}\-\d{1,2}\-\d{1,2}$/ ;

           if ( re.test( value ) ) {
             adata = value.split( "-" );
             aaaa = isIE ? parseInt( adata[ 2 ], 10 ) : parseInt( adata[ 0 ], 10 );
             mm = parseInt( adata[ 1 ], 10 );
             gg = isIE ? parseInt( adata[ 0 ], 10 ) : parseInt( adata[ 2 ], 10 );
             xdata = new Date(Date.UTC( aaaa, mm - 1, gg, 12, 0, 0, 0 ) );
             if ( ( xdata.getUTCFullYear() === aaaa ) && ( xdata.getUTCMonth() === mm - 1 ) && ( xdata.getUTCDate() === gg ) ) {
               check = true;
             }
           }
           return this.optional(value, isoptional) || check;
         }
    };

    this.validForm = function (fields) {
      let valid = true;
      for (const field of fields) {
          if (!field.valid) {
              valid = false;
              break;
          }
      }
      return valid;
    };

    this.validateForm = function (fields) {
        const newFields = [...fields];

        let i = 0;
        for (let item of fields) {
          const field = {...item};

          if (field.value === undefined || field.value === null) {
            field.value = "";
          }

          field.message = this.validate(field.value, field.validations);
          field.valid = field.message === "";
          newFields[i] = field;
          i++;
        }
        return newFields;
    };

    this.validateField = function (field, value) {
      let newField = {...field};
      newField.value = value;
      newField.message = this.validate(value, newField.validations);
      newField.valid = newField.message === "";
      return newField;
    };
}

const Validate = new Validation();

export default Validate;
