import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class PaginationMod extends Component {
    constructor(props) {
        super(props);
        this.state = { pager: {} };
    }

    handleClick(e, value) {
        e.preventDefault();
        this.setPage(value);
        this.props.onChangePage(value);
    }

    componentWillMount() {
        if (this.props.numberOfItems > 0){
            this.setPage(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.numberOfItems !== prevProps.numberOfItems) {
            this.setPage(this.props.initialPage);
        }
    }

    setPage( page)  {
        var { pageSize } = this.props;
        var pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) { return; }
        pager = this.getPager(this.props.numberOfItems, page, pageSize);
        this.setState({ pager : pager });
    }

    getPager(totalItems, currentPage, pageSize) {
        currentPage = currentPage || 1;
        var totalPages = Math.ceil(totalItems / pageSize);
        var startPage, endPage, maxPages;
        maxPages = 10; // maxima cantidad de paginas a mostrar
        if (totalPages <= maxPages) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= ((maxPages/2)+1)) {
                startPage = 1;
                endPage = maxPages;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - (maxPages-1);
                endPage = totalPages;
            } else {
                startPage = currentPage - (maxPages / 2);
                endPage = currentPage + ((maxPages/2)-1);
            }
        }

        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
        return {
            pages: pages
        };
    }

    render() {
        var pager = this.state.pager;
        var {pageSize, numberOfItems} = this.props

        if (!pager.pages || pager.pages.length <= 1) {
            return null
        }

        return (
            <div className="pagination-wrapper">
                <Pagination aria-label="Page navigation solicitudes">
                    <PaginationItem disabled={this.props.currentPage === 1}>
                        <PaginationLink first='true'
                            onClick={e => this.handleClick(e, 1)}
                            href="#"
                        >«</PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={this.props.currentPage === 1}>
                        <PaginationLink
                            onClick={e => this.handleClick(e, this.props.currentPage - 1)}
                            previous
                            href="#"
                        >‹</PaginationLink>
                    </PaginationItem>
                    {pager.pages.map((page, index) =>
                        <PaginationItem active={this.props.currentPage === page} key={page}>
                            <PaginationLink disabled={this.props.currentPage === page} onClick={e => this.props.currentPage !== page && this.handleClick(e, page)}>
                                {page}
                            </PaginationLink>
                        </PaginationItem>
                    )}
                    <PaginationItem disabled={this.props.currentPage === Math.ceil(numberOfItems / pageSize)}>
                        <PaginationLink
                            onClick={e => this.handleClick(e, this.props.currentPage + 1)}
                            next
                            href="#"
                        >›</PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={this.props.currentPage === Math.ceil(numberOfItems / pageSize)}>
                        <PaginationLink
                            onClick={e => this.handleClick(e, Math.ceil(numberOfItems / pageSize))}
                            last='true'
                            href="#"
                        >»</PaginationLink>
                    </PaginationItem>
                </Pagination>
            </div>
        );
    }
}

PaginationMod.propTypes = {
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    numberOfItems: PropTypes.number,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired
};

export default PaginationMod;
