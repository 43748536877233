import React from 'react';
import PropTypes from 'prop-types';

//Ui
import { Modal } from 'reactstrap';

const VideoExternal = ({open, handleClick, url})=> {
    return(
        <Modal 
            className="modal-video modal-video-external"
            centered 
            size="lg"
            isOpen={open} 
            toggle={handleClick}>
                <button
                    onClick={handleClick}
                    className="close-modal-video"
                    type="button">
                        X
                </button>
                <div class="embed-responsive embed-responsive-4by3">
                    <iframe 
                        width="560" 
                        height="315" 
                        src={url}
                        frameBorder="0" 
                        allowFullScreen/>
                </div>
        </Modal>
    );
};

VideoExternal.propTypes = {
    url: PropTypes.string,
    open: PropTypes.bool,
    handleClick: PropTypes.func,
};
export default VideoExternal;