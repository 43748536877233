import React, { Fragment } from 'react';

//Component
import Typography from '../Typography/Typography';
import Lang from '../../api/Lang';
import Avatar from '../Avatar/Avatar';
import RequestAlert from '../../components/Alert/RequestAlert';

//UI
import { Button } from 'reactstrap';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const ShowNew = props => {
    const { data, open, toggle, isActive, handleAcceptNew, error, message, closeModalAll, requesting } = props
    return (
        <Modal id="notify-sticky-wrapper" isOpen={open} toggle={toggle} className="modal-request-info modal-notify-request" backdrop={'static'} keyboard={isActive}>
            <ModalBody>
                <Avatar 
                    name={data.usr_name} 
                    size="lg"
                    url={data.usr_img_url} 
                    variant="primary" />
                    <Typography variant="small">
                        {Lang.get('NEWREQUESTMODAL.TITLE')}
                    </Typography>
                    <Typography variant="h4">{data.tkk_name}</Typography>
                    <Typography variant="h5">{data.usr_name}</Typography>
                    {message !== null &&
                        <RequestAlert variant="inline" type={error ? `error` : `success`} message={message} id={data.rqt_id} handleClick={closeModalAll}/>
                    }
            </ModalBody>
            <ModalFooter>
                <div className="new-footer">
                    {message === null ?
                        <Fragment>
                            <Button color="danger" name={data.rqt_id} onClick={() => handleAcceptNew(data.rqt_id)} disabled={Boolean(requesting)} >
                                {Lang.get('NEWREQUESTMODAL.BTN.ACCEPT')}
                            </Button>
                            <Button color="danger" onClick={() => toggle(data.rqt_id)} disabled={Boolean(requesting)}>
                                {Lang.get('NEWREQUESTMODAL.BTN.DECLINED')}
                            </Button>
                        </Fragment>
                        :
                        <Button color="danger" name={data.rqt_id} onClick={closeModalAll}>
                            Cerrar
                        </Button>
                    } 
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ShowNew;