import React, { Component } from 'react';
import { Constants } from '../../../config/Constants';

//Components
import VideoExternal from './VideoExternal';

//Ui
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import videoImage from '../../../resources/images/lawyers.jpg';

class Video extends Component {
    constructor(){
        super()
        this.state = {
            open: false,
        };
        this.handleClick = this.handleClick.bind(this);
    };
    handleClick = e => {
        this.setState({
            open: !this.state.open,
        });
    };
    
   
    render(){
        const { open } = this.state;
        return(
            <section className="area-wrapper video" 
                style={{
                    backgroundImage: `url(${Constants.VIDEO.FOOTER.poster})`
                    //background: `url(${videoImage}) center center no-repeat`
                }}>
                <Container>
                    <div className="video-wrapper">
                        <div className="video-wrapper-item">
                            <button
                                onClick={this.handleClick} 
                                type="button" 
                                className="video-item-play">
                                <FontAwesomeIcon icon={faPlay}/>
                            </button>
                        </div>
                        <div className="video-wrapper-item">
                            {/*<h1>REVU</h1>*/}
                        </div>
                    </div>
                </Container>
                <VideoExternal
                    url={Constants.VIDEO.FOOTER.url}
                    open={open}
                    handleClick={this.handleClick}/>
            </section>
        );
    };
};

export default Video;