import React, { Component, Fragment } from 'react';
import DisponibilitySchedule from '../Disponibility/DisponibilitySchedule';
import SimpleMaps from '../Disponibility/SimpleMaps';
import XServices from '../../api/XServices';
import Loading from '../Loading/Loading';

class TabDisponibility extends Component {
    constructor() {
        super();
        this.state = {
            error: null,
            data: null,
            loading: false,
            dataMaps: null,
            contactRequest: false,
            contactRequestStatus: false,
        };
        this.handleLoadNotaryData = this.handleLoadNotaryData.bind(this);
    };
    componentDidMount() {
        this.handleLoadNotaryData();
    }

    handleLoadNotaryData = () => {
        this.setState({ loading: true });
        XServices.get('request/notary', {
            requestId: this.props.id,
        }, response => {
            this.setState({
                data: response,
                loading: false,
                dataMaps: { lat: response.lat, lng: response.lon }
            });
        }, error => {
            this.setState({
                error: error.message,
                loading: false
            });
        });
    }

    handleContactNotary = () => {
        XServices.post('request/notary/contact', {
            requestId: this.props.id
        }, (response) => {
            this.setState({
                contactRequest: true,
                contactRequestStatus: response.code === undefined
            });
        }, () => {
            this.setState({
                contactRequest: true,
                contactRequestStatus: false
            });
        });
    }

    handleDismissContact = () => {
        this.setState({
            contactRequest: false
        });
    }

    render() {
        const { data, dataMaps, loading, contactRequest, contactRequestStatus } = this.state;
        return (
            <div className="disponibility-wrapper">
                {loading ? <Loading variant="component" color="white" /> :
                    data !== null && dataMaps !== null &&
                    <Fragment>
                        <DisponibilitySchedule data={data} handleContact={this.handleContactNotary} contactRequest={contactRequest} contactRequestStatus={contactRequestStatus} handleDismissContact={this.handleDismissContact}/>
                        <SimpleMaps center={dataMaps} zoom={18} nameNotary={data.fullname} />
                    </Fragment>

                }
            </div>
        );
    }
}

export default TabDisponibility;