import React, {Fragment} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

import FileUpload from '../FileUpload/FileUpload';
import Lang from '../../api/Lang';
import Loading from '../Loading/Loading';
import Typography from '../Typography/Typography';

const RequireFileAsWorker = props => {
    const { handleSave, handleFiles, data, valid, message, feedback, handleCancelAntecedent } = props;
    const { type } = data;

    let isValid = valid !== undefined && type !== 'F' ? valid : true;

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
              {type === 'F' &&
                <FileUpload
                  onDrop={handleFiles}
                  valid={valid}
                  message={message}
                  feedback={feedback}
                  disabled={data.loading === true}/>}
          </div>
          <div className="col-md-12">
            <div className="action-control">
                {data.loading === true ?
                    <button className="btn btn-sm" disabled={true}><Loading variant="component" /></button>
                 :
                    <Fragment>
                        <button className="btn btn-sm btn-action-button btn-action-success" onClick={handleSave} disabled={!isValid || data.loading === true}>
                            <span>
                                <Typography variant="small">{Lang.get('ACTION_BUTTON_ACCEPT')}</Typography>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                        </button>
                        <button className="btn btn-sm btn-action-button btn-action-cancel" onClick={() => handleCancelAntecedent()} disabled={!isValid || data.loading === true}>
                            <span>
                                <Typography variant="small">{Lang.get('ACTION_BUTTON_CANCEL_ANTECEDENT')}</Typography>
                                <FontAwesomeIcon icon={faTimes}/>
                            </span>
                        </button>
                    </Fragment>
                 }
            </div>
          </div>
        </div>
      </div>
    );
};

export default RequireFileAsWorker;
