import React, { Fragment } from 'react';

//Components
import Areas from '../../components/Home/Areas/Areas';
import Category from '../../components/Home/Category/Category';
import Video from '../../components/Home/Video/Video';
//import ClientSlider from '../../components/Home/ClientSlider/ClientSlider';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import HelpFooter from '../../components/HelpFooter/HelpFooter';
import Lang from '../../api/Lang';

const Home = props => {
    if (props.match.params.lang !== undefined) {
        Lang.load(props.match.params.lang);
    }
    return (
        <Fragment>
            <Header variant="home"/>
            <Areas />
            <Category variant="home"/>
            <Video />
            {/*<ClientSlider />*/}
            <HelpFooter />
            <Footer />
        </Fragment>
    )
};

export default Home;
