import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import InputField from '../InputField/InputField';
import FileUpload from '../FileUpload/FileUpload';
import Lang from '../../api/Lang';
import Loading from '../Loading/Loading';
import Typography from '../Typography/Typography';

const RequireField = props => {
    const { handleSave, handleChange, handleFiles, data, value, valid, message, feedback } = props;
    const { id, type, additional, mandatory} = data;

    let inputId = "field-" + id;
    let isValid = valid !== undefined && type !== 'F' ? valid : true;

    return (
      <div className="col-md-12">
        <div className="row">
          {type !== 'F' && <div className="col-md-12">
            <label> {Lang.get('REQUEST.FIELD.NEWVALUE')}</label>
          </div>}
          <div className="col-md-12">
              {type === 'F' ?
                <FileUpload
                  onDrop={handleFiles}
                  valid={valid}
                  message={message}
                  feedback={feedback}
                  disabled={data.loading === true}/>
                :
                <InputField
                  inputId={inputId}
                  id={id}
                  type={type}
                  additional={additional}
                  mandatory={mandatory}
                  handleChangeInput={handleChange}
                  valid={valid}
                  value={value}
                  message={message}
                  feedback={feedback}
                  disabled={data.loading === true}
                 />
             }
          </div>
          <div className="col-md-12">
            <div className="action-control">
              <button className="btn btn-sm btn-action-button btn-action-success" onClick={handleSave} disabled={!isValid || data.loading === true}>
                {data.loading === true ?
                  <Loading variant="component" />
                : <span>
                    <Typography variant="small">{Lang.get('ACTION_BUTTON_ACCEPT')}</Typography>
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default RequireField;
