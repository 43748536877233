import Rut from "./Rut";

/* eslint-disable */

//https://rhashemian.github.io/js/NumberFormat.js
function FormatNumberBy3(value, decpoint, sep, includeComma) {
  // check for missing parameters and use defaults if so
  if (arguments.length == 2) {
    sep = ",";
  }
  if (arguments.length == 1) {
    sep = ",";
    decpoint = ".";
  }
  // need a string for operations
  let num = value.toString().trim();
  num = num.replace(/\./g,"");
  num = num.replace(/[^0-9\,]/g,"");

  // separate the whole number and the fraction if possible
  let a = num.split(decpoint);
  let x = a[0]; // decimal
  let y = a[1]; // fraction
  let z = "";
  let i;

  if (typeof(x) != "undefined") {
    // reverse the digits. regexp works from left to right.
    for (i=x.length-1;i>=0;i--)
      z += x.charAt(i);
    // add seperators. but undo the trailing one, if there
    z = z.replace(/(\d{3})/g, "$1" + sep);
    if (z.slice(-sep.length) == sep)
      z = z.slice(0, -sep.length);
    x = "";
    // reverse again to get back the number
    for (i=z.length-1;i>=0;i--)
      x += z.charAt(i);
    // add the fraction back in, if it was there
    if (typeof(y) != "undefined" && y.length > 0) {
      x += decpoint + y;
    } else if (a.length > 1 && includeComma) {
      x += decpoint;
    }
      
  }
  return x;
}

function FormatUtils () {
  this.doFormat = function (type, value) {
    return this.formats[type] !== undefined ? this.formats[type](value) : value;
  }
  this.formats = {
    "rut": function (value) {
      return Rut.formatear(value.replace(/[^0-9kK]/g,""), true);
    },
    "currencyCLP": function (value) {
      return FormatNumberBy3(value, ',', '.', false);
    },
    "decimal": function (value) {
      return FormatNumberBy3(value, ',', '.', true);
    }
  }
}

const Format = new FormatUtils();

export default Format;
