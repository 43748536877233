import React, {useEffect, Fragment, useState} from 'react';
import {Button} from 'reactstrap';
import { Card, CardText, CardImg, CardImgOverlay } from 'reactstrap';
import './WelcomeLandingPage.scss';
import heroWelcome from '../../resources/images/welcome-revu-min.jpg';
import Lang from "../../api/Lang";
import {Link} from "react-router-dom";
import { AuthConsumer } from '../../config/AuthContext';
import Loading from "../../components/Loading/Loading";
import XServices from "../../api/XServices";

export const WelcomeLandingPage = ()=> {

    const [loading, setLoading] = useState(true);
    const [lapseTime, setlapseTime] = useState(7000);

    const getLapseTime = ()=> {
        XServices.post('account/lapse', {
        }, response => {
            if (response.lapseOfTime !== null) {
                setlapseTime(response.lapseOfTime);
            }
        });
    };

    useEffect(()=> {
        eventRegister();
        getLapseTime();
        setLoading(false);
        setTimeout(() => {
            window.location = `/${Lang.lancode}`;
        }, lapseTime)
    }, []);

    const eventRegister = () => {
      window.dataLayer.push({
          'event': 'boton-registrar',
          // 'eventCategory': 'Registro',
          // 'eventAction': 'Registro',
          // 'eventLabel': 'Registro',
          'pageName': window.location.pathname
      })
    };

    return (
        <Fragment>
        {
            loading ?
                <Loading variant="fullscreen" color="white"/>
                        :
                <AuthConsumer>
                    {({isAuth})=> {
                        return (
                            <div className="content-body pt-0 pb-0 mt-0">
                                <Card inverse>
                                    <CardImg width="100%" src={heroWelcome} alt="Card image cap" />
                                    <CardImgOverlay className="test">
                                        <CardText className="offset-1">
                                            <div className="container">
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="col-xs-12 col-md-5"></div>
                                                <div className="col-xs-12 col-md-7 d-flex justify-content-center pt-4">
                                                    <Button color="danger" size="lg" tag={Link} to={"/" + Lang.lancode}>{Lang.get("ACCOUNT.SUCCESS.GO.HOME.LINK")}</Button>
                                                </div>
                                            </div>
                                        </CardText>
                                    </CardImgOverlay>
                                </Card>
                            </div>
                        );
                    }}
                </AuthConsumer>
        }
        </Fragment>
    );
};