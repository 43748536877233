import React, { Component } from 'react';
import queryString from 'query-string';
import {withRouter } from 'react-router-dom';
import XServices from '../../api/XServices';

import AccountExpired from './AccountExpired';
import AccountUpdatePassword from './AccountUpdatePassword';
import Lang from '../../api/Lang';

//Ui
import Logo from '../../components/Logo/Logo';
import AccountMessages from './AccountMessages';
import Loading from '../../components/Loading/Loading';

class AccountRecover extends Component {
    constructor(){
        super();
        this.state = {
            expired: false,
            loading: false,
            request: null,
            error: false,
        }
    };

    componentDidMount(){
        const getParams = this.props.location.search;
        const params = queryString.parse(getParams);
        XServices.post('/account/restorePassword/valid', params, response => {
            this.setState({
                loading: true,
                error: false,
                request: params.request
            })
        }, err => {
            this.setState({
                loading: true,
                error: true
            })
            if(err.code === 101){
                this.setState({ expired: true, error: false})
            }
        });
    }
   
    render(){
        const { error, expired, loading, request } = this.state;
        return(
        <div className="account-recover-wrapper">
            <div className="account-recover-logo">
                <Logo variant="full"/>
            </div>
            {loading ? 
                !error ? 
                expired ? <AccountExpired /> : <AccountUpdatePassword request={request}/> :
                <AccountMessages title={Lang.get('ACOOUNT.UPGRADE.NOOK')} message={Lang.get('ACOOUNT.UPGRADE.NOOK.MESSAGE')}/> : 
                <Loading variant="component" />}
        </div>
        );
    };
};

export default withRouter(AccountRecover);