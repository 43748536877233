import React, { Component, Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';

//Ui
import Swiper from 'react-id-swiper';

import fakeimage1 from '../../resources/images/avatar-lawyer.jpg';
import fakeimage2 from '../../resources/images/avatar-client.jpg';
import fakeimage3 from '../../resources/images/lawyer.jpg';
import 'react-id-swiper/lib/styles/css/swiper.css';

//Components
import Typography from '../Typography/Typography'; 
import Lang from '../../api/Lang';
import { Container } from 'reactstrap';

class RelatedSlider extends Component {
    render (){
        return <Fragment></Fragment>;
/*        
        const FAKE_DATA = [
            { 
                name: 'Lorem ipsum ',
                image: fakeimage1,
                message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ed tempus efficitur lectus eu consequat.'
            },
            { 
                name: 'Lorem ipsum ',
                image: fakeimage2,
                message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ed tempus efficitur lectus eu consequat.'
            },
            { 
                name: 'Lorem ipsum ',
                image: fakeimage3,
                message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.  ed tempus efficitur lectus eu consequat.'
            },
        ];
        const params = {
            slidesPerView: 3,
            spaceBetween: 0,
            centeredSlides: true,
            loop: true,
            shouldSwiperUpdate: true,
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 1,
                },
                320: {
                  slidesPerView: 1,
                },
              },
          };
        return (
            <AuthConsumer>
                {({isIE, isEdge})=> {
                    const compatibility = isEdge || isIE
                    return (
                        <section className="client-wrapper related-wrapper">
                            <div className="client-title-wrapper">
                                <Container>
                                    <Typography variant="h6">{Lang.get('HOME.MESSAGESLIDER')}</Typography>
                                </Container>
                            </div>
                            <div className="client-slider-wrapper">
                                <Swiper { ...params}>
                                    {FAKE_DATA.map((item, i)=> (
                                        <div key={i} className="slider-item">
                                            {compatibility ? 
                                                <div className="slider-image" 
                                                    style={{background:  `url(${item.image}) center center no-repeat`, backgroundSize:'cover'}}></div>: 
                                                <div className="slider-image">
                                                    <img src={item.image} alt={item.name} /> 
                                                </div>}
                                            <div className="slider-info">
                                                <Typography variant="h5">
                                                    <Typography variant="strong">{item.name}</Typography>
                                                </Typography>
                                                <Typography variant="p">
                                                    {item.message}
                                                </Typography>
                                                <Typography variant="h6">
                                                    <Typography variant="strong">{item.name}</Typography>
                                                </Typography>
                                            </div>
                                        </div>
                                    ))}
                                </Swiper>
                            </div>
                        </section>
                    );
                }}
            </AuthConsumer>
        );
*/
    };
};

export default RelatedSlider;
