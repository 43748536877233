import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';

const Notify = props => {
    const { title, message } = props;
    return (
        <div className="notify">
            <div className="notify-title">
                <Typography variant="p">
                    <Typography variant="strong">
                    {title}
                    </Typography>
                </Typography>
            </div>
            <div className="notify-message">
                <Typography variant="p">{message}</Typography>
            </div>
        </div>
    );
};

Notify.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};
export default Notify;
