import React, { Fragment, Component } from 'react';

import XServices from '../../api/XServices';
import Validate from '../../api/Validate';

// UI
import BannerButtonRequest from '../BannerButtonRequest/BannerButtonRequest';
import SearchBackground from '../SearchBackground/SearchBackground';
import Loading from '../Loading/Loading';
import Error from '../Error/Error';
import CustomAlert from '../Alert/CustomAlert';
import Lang from '../../api/Lang';

import {Button} from 'reactstrap';

class AskNewField extends Component {

  constructor() {
    super();
    this.state = {
      loading: true,
      showList: false,
      showCreate: false,
      items: null,
      selected: [],
      filtered: null,
      error: null,
      success: null,
      filterValue: '',
      newField : {
        value: '',
        type: '',
        valid: true,
        message: '',
        validations: [
          "required",
          {
            "name": "maxlength",
            "params": [255]
          },
          {
            "name": "minlength",
            "params": [5]
          }
        ]}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickCustom = this.handleClickCustom.bind(this);
    this.handleFieldClick = this.handleFieldClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveField = this.handleSaveField.bind(this);
    this.handleSaveCustomField = this.handleSaveCustomField.bind(this);
    this.handleChangeCustomType = this.handleChangeCustomType.bind(this);
    this.handleChangeCustomText = this.handleChangeCustomText.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.getClearNewField = this.getClearNewField.bind(this);
  }

  componentDidMount() {
    let that = this;
    XServices.get('request/fields/missing', {
      id: this.props.id
    }, function (response) {
      // Filter All option
      let filtered = response.filter(item => item.id !== '');
      filtered.sort(that.compareFields);

      that.setState({ loading: false, items: filtered, filtered: filtered});
    }, function (error) {
      that.setState({ loading: false, error: error.message });
    });
  }

  compareFields(a, b) {
    var x = a.name; var y = b.name;
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  }

  handleChange(value) {
    const filterValue = value.toLowerCase();
    const filtered = this.state.items.filter(item => item.name.toLowerCase().indexOf(filterValue) !== -1);
    this.setState({ filterValue : value, filtered: filtered, success: null});
  }

  handleClickCustom() {
    this.handleChangeCustomText(this.state.filterValue);
    this.setState({ showCreate : !this.state.showCreate, success: null});
  }

  handleFieldClick(data) {
    const items = this.state.items.filter(item => item.id !== data.id);
    const filtered = items.filter(item => item.name.toLowerCase().indexOf(this.state.filterValue) !== -1);
    const selected = [...this.state.selected];
    selected.push(data);
    this.setState({filtered: filtered, items: items, selected: selected, success: null});
  }

  handleDeleteClick(data) {
    const selected = this.state.selected.filter(item => item.id !== data.id);
    let items = [...this.state.items];
    items.push(data);
    items.sort(this.compareFields);
    const filtered = items.filter(item => item.name.toLowerCase().indexOf(this.state.filterValue) !== -1);
    this.setState({filtered: filtered, items: items, selected: selected, success: null});
  }

  handleToggle() {
    this.clearFields(true);
    this.setState({showList: !this.state.showList, showCreate: false, newField : this.getClearNewField()});
  }

  handleBack() {
    this.clearFields(true);
  }

  handleSave() {
    if (this.state.showCreate) {
      this.handleSaveCustomField();
    } else {
      this.handleSaveField();
    }
  }

  handleSaveField() {
    let fields = [];
    for (const item of this.state.selected) {
      fields.push(item.id);
    }

    if (fields.length > 0) {
    XServices.post('request/field/add', {
      requestId: this.props.id,
      fields: fields
    }, response => {
      this.clearFields();
      this.setState({success: response.message});
      this.props.handleAddField(response.fields);
    }, error => {
      this.setState({success: error.message});
    });
  }else{
    this.setState({success: Lang.get('SEARCHBACKGROUND.NOELEMENTS'), showList: true});
  }
  }

  handleSaveCustomField() {
    const {newField} = this.state;
    XServices.post('request/field/add/custom', {
      requestId: this.props.id,
      title: newField.value,
      type: newField.type
    }, response => {
      this.clearFields(false);
      this.setState({success: response.message});
      this.props.handleAddField([response.field]);
    }, error => {
      this.setState({success: error.message});
    });
  }

  clearFields(includeSelected) {
    let items = [...this.state.items];
    if (includeSelected === true) {
      for(let item of this.state.selected) {
        items.push(item);
      }
      items.sort(this.compareFields);
    }
    this.setState({filtered: items, items: items, selected: [], filterValue: '', success: null, showCreate: false, newField: this.getClearNewField()});
  }

  handleChangeCustomText(text) {
    let newField = {...this.state.newField};
    newField.value = text;
    newField.message = Validate.validate(newField.value, newField.validations);
    newField.valid = newField.message === "";
    this.setState({newField: newField});
  }

  handleChangeCustomType(type){
    let newField = {...this.state.newField}
    newField.type = type;
    this.setState({newField: newField});
  }

  getClearNewField() {
    let newField = {...this.state.newField};
    newField.value = '';
    newField.type = '';
    newField.valid = true;
    newField.message = '';
    return newField;
  }

  render() {
    const { loading, items, error, filtered, selected, showList, showCreate, filterValue, success, newField } = this.state;
    return (
        <Fragment>
        {loading ? (
          <div className="margin-top">
            <Loading variant="component" color="danger" style={{ width: '3rem', height: '3rem' }}/>
          </div>
        ) : (
          error !== null ? <Error type="danger" message={error}/> :
          <Fragment>
          <BannerButtonRequest
            text_request="Solicitar antecedente"
            handleClickRequestButton={this.handleToggle}
            showList={showList}
          />
          <SearchBackground
            showList={showList}
            showCreate={showCreate}
            handleChange={this.handleChange}
            handleClickCustom={this.handleClickCustom}
            newField = {this.state.newField}
            newType = {this.state.newField}
            items = {filtered}
            total = {items.length}
            selected = {selected}
            filterValue= {filterValue}
            handleFieldClick={this.handleFieldClick}
            handleDeleteClick={this.handleDeleteClick}
            handleChangeCustomType={this.handleChangeCustomType}
            handleChangeCustomText={this.handleChangeCustomText}
           />
           {showList && success &&
             <div className="row justify-content-end test1">
              <div className="col text-result">
              <CustomAlert type="success" message={success}/>
                {/* <Typography>{success}</Typography> */}
              </div>
             </div>}
           {showList &&
           <div className="custom-actions-wrapper">
              <div className="col-auto">
                {showCreate && <Button size="md" onClick={this.handleBack}>{Lang.get('SEARCHBACKGROUND.BUTTON.BACK')}</Button>}
                {!showCreate && <Button size="md" onClick={this.handleToggle}>{Lang.get('SEARCHBACKGROUND.BUTTON.CANCEL')}</Button>}
              </div>
              <div className="col-auto">
                <Button color="danger" size="md" disabled={selected.length === 0 && !newField.valid} onClick={this.handleSave}>{Lang.get('SEARCHBACKGROUND.BUTTON.SAVE')}</Button>
              </div>
            </div>}
           </Fragment>
         )}
        </Fragment>
    );
  }
}

export default AskNewField;
