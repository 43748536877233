import React from 'react';
import { AuthConsumer } from '../../config/AuthContext';

//Components

import Typography from '../Typography/Typography';
import { Button } from 'reactstrap';
import Lang from '../../api/Lang';
import { Constants } from '../../config/Constants';

const SearchItem = props => {
    const { loading, data, text } = props;
    if(loading) return null;
    if(data.length === 0) return (
        <div className="search-result">
            <div className="search-result-item">
                <Typography variant="p">{Lang.get(text.length >= Constants.MIN_SEARCH_LENGTH ? "SEARCH.NORESULTS" : "SEARCH.MINSEARCHLENGTH", [Constants.MIN_SEARCH_LENGTH])}</Typography>
            </div>
        </div>
    );
    return (
        <AuthConsumer>
            {({ dispatch })=> (
                <div className="search-result">
                    {data.map((item, i) => {
                        return (
                        <div key={item.id} className="search-result-item" onClick={()=> dispatch({action: 'add', id: item.id})}>
                            <div className="search-result-item-title">
                                <Typography variant="h6">{item.name}</Typography>
                            </div>
                            <div className="search-result-item-description">
                                <Typography variant="p" color="muted">{item.description}</Typography>
                            </div>
                        </div>
                    )})}
                    {data.length > 10 &&
                        <div className="search-result-item more">
                            <Button color="link">{Lang.get("SEARCH.SHOWALL")}</Button>
                        </div>}
                </div>
            )}
        </AuthConsumer>
    );
};

export default SearchItem;
