import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink as RRNavLink } from 'react-router-dom';
import { AuthConsumer, AuthContext } from '../../config/AuthContext';
import { isHome } from '../../api/isRoute';
import { withRouter } from 'react-router-dom';
import { Constants } from '../../config/Constants';

//Components
import NewRequest from '../../pages/NewRequest/NewRequest';
import Avatar from '../Avatar/Avatar';
import Logo from '../Logo/Logo';
import SearchHero from '../SearchHero/SearchHero';
import Account from '../Login/Account';
import Loading from '../Loading/Loading';
import AssistanceBell from '../AssistanceBell/AssistanceBell.';
import SearchNew from '../SearchNew/SearchNew';
import MyAccountAside from '../MyAccount/MyAccountAside';
import Responsive from '../Responsive/Responsive';
import SloganItems from './SloganItems';
import Slogan from './Slogan';
import MenuItems from './MenuItems';
import VideoExternal from '../Home/Video/VideoExternal';

//Ui
import {  Modal, Button,ModalBody, Collapse, Navbar, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faSearch } from '@fortawesome/free-solid-svg-icons';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenu:false,
            positionY: '',
            sticky: false,
            compact: false,
            redirect: false,
            requestId: null,
            fixed: false,
            open:false,
        };
        this.searchRef = React.createRef();
        this.getLink = this.getLink.bind(this);
        this.hasRol = this.hasRol.bind(this);
        this.handleClick = this.handleClick.bind(this);
    };

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    };
    
    handleMenu = ()=> {
        this.setState({
            isMenu: !this.state.isMenu
        });
    };
    handleScroll = (e)=> {
        let positionY;
        if(this.context.isIE){
            positionY = window.pageYOffset;
        }else{
            positionY = window.scrollY;
        }
        let offset = window.innerHeight*0.9*0.8-250;

        if(positionY > offset ){
            this.setState({compact: true, fixed: true});
        }else {
            this.setState({compact: false, fixed: false})
        }
        this.setState({ positionY })
        if(positionY > 200) {
            this.setState({ sticky: true, fixed: true });
        }else {
            this.setState({ sticky: false, fixed: false });
        }
    };

    handleCreated = (response) => {
      if (response.url !== null) {
          window.location = response.url;
      }
    };

    getLink(userRol) {
      if (this.hasRol(["RQT"], userRol)) {
        return "/es/requested";
      } else {
        return "/es/assigned";
      }
    };

    hasRol (requiredRol, userRol) {
      let containsRol = false;
      for (const rol of requiredRol) {
        if (userRol.indexOf(rol) !== -1) {
          containsRol = true;
          break;
        }
      }
      return containsRol;
    };
    handleClick = e => {
        this.setState({
            open: !this.state.open,
        });
    };
    render(){
        const { positionY, sticky, compact, fixed} = this.state;
        const { variant } = this.props;
        return (
            <AuthConsumer>
                {({isAuth, user, modal, id, dispatch, isLoginModal, loading, ctx, toggleSearchResponsive})=> {
                    return (
                        <Fragment>
                            <header className={`header ${variant}`}>
                                <div className="header-left">
                                    <div className="header-item-left">
                                    </div>
                                    <div className="header-item-left">
                                        {variant === 'home' &&
                                            <Fragment>
                                                <div className="header-item-slogan-wrap">
                                                    <Responsive mode="tablet" break="down">
                                                        <Slogan />
                                                        <SloganItems />
                                                    </Responsive>
                                                </div>
                                                <div className={classNames('hero-search-wrapper')} ref={this.searchRef}>
                                                    <Responsive mode="tablet" break="down">
                                                        {!compact && <SearchHero position={positionY} compact={Boolean(compact)}/> }
                                                    </Responsive>
                                                    <Responsive mode="tablet" break="up">
                                                        <SearchHero fixed={Boolean(fixed)}/>
                                                    </Responsive>
                                                </div>
                                            </Fragment>
                                        }
                                    </div>
                                    <div className="header-item-left">
                                    </div>
                                </div>
                                <div 
                                    style={{backgroundImage: `url(${Constants.VIDEO.HEADER.poster})` }}
                                    className="header-right">
                                    {variant === 'home' &&
                                        <Fragment>
                                            <div className="header-icon-play">
                                                <button
                                                    type="button"
                                                    onClick={this.handleClick}
                                                    className="icon-play">
                                                     <FontAwesomeIcon icon={faPlay}/>
                                                </button>
                                            </div>
                                            <Responsive mode="tablet" break="up">
                                                <div className="phone-wrapper-break">
                                                    <Slogan />
                                                    {!isAuth &&
                                                    <div className="login-wrapper-break">
                                                        <Button className="item-link-button sigin"
                                                            onClick={()=> dispatch({action: 'login', ctx: 'signin'})}>
                                                                Ingresar
                                                        </Button>
                                                        <Button className="item-link-button register"
                                                            onClick={()=> dispatch({action: 'login', ctx: 'register'})}>
                                                                Registrar
                                                        </Button>
                                                    </div>}
                                                    < SloganItems />
                                                </div>
                                            </Responsive>
                                            <VideoExternal 
                                                url={Constants.VIDEO.HEADER.url}
                                                open={this.state.open} 
                                                handleClick={this.handleClick}/>
                                        </Fragment>
                                       }
                                </div>
                                <Navbar
                                    className={classNames('navbar-custom', sticky && 'sticky-header', compact ? 'solid': '')} expand="xs">
                                    <div className={`nav-menu-left ${variant !== 'home' && 'grow-left'}`}>
                                        <NavbarBrand tag={RRNavLink} exact to="/">
                                            <Logo variant="medium" size="xs" />
                                        </NavbarBrand>
                                        <Nav className="menu-left" navbar>
                                            {variant === 'home' &&<MenuItems />}
                                        </Nav>
                                    </div>
                                    {/*<NavbarToggler onClick={this.toggle} />*/}
                                    <Collapse isOpen={this.state.isOpen} navbar>
                                        <div className="search-wrapper-header">
                                            {variant === 'home' &&  compact && 
                                                <Responsive mode="tablet" break="down">
                                                    <SearchHero compact={Boolean(compact)}/>
                                                </Responsive>}
                                            {variant === 'private' && 
                                                <SearchHero dark compact={Boolean(true)} toggle={Boolean(toggleSearchResponsive)}/>}
                                        </div>
                                        <Nav className="ml-auto" navbar>
                                            {isAuth ? user !== null &&
                                            <Fragment>
                                                {isHome(this.props.match.url) &&
                                                <Responsive mode="tablet" break="up">
                                                    <NavItem>
                                                        <NavLink onClick={()=> dispatch({action: 'toggle-search'})}>
                                                            <div className="bell-main">
                                                                <FontAwesomeIcon icon={faSearch} />
                                                            </div>
                                                        </NavLink>
                                                    </NavItem>
                                                </Responsive>}
                                                <NavItem>
                                                    <NavLink tag={RRNavLink}  exact to="/es/notifications">
                                                        <AssistanceBell />
                                                    </NavLink>
                                                </NavItem>
                                                {/*<NavItem>
                                                    <NavLink tag={RRNavLink}  exact to={this.getLink(user.roles)}>
                                                      <Avatar url={user.img_url} size="sm" name={user.name}/>
                                                    </NavLink>
                                                </NavItem>*/}
                                                <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle nav caret>
                                                        <Avatar url={user.img_url} size="sm" name={user.name}/>
                                                    </DropdownToggle>
                                                    <MyAccountAside position="NAV"/>
                                                </UncontrolledDropdown>
                                                <SearchNew />
                                            </Fragment>
                                                :
                                                (loading ? <Loading variant="component" color="white"/> :
                                                    <Fragment>
                                                        <Responsive mode="tablet" break="down">
                                                            <NavItem>
                                                                <NavLink href="#" className="item-link-button sigin"
                                                                    onClick={()=> dispatch({action: 'login', ctx: 'signin'})}>
                                                                        Ingresar
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink href="#" className="item-link-button register"
                                                                    onClick={()=> dispatch({action: 'login', ctx: 'register'})}>
                                                                        Registrar
                                                                </NavLink>
                                                            </NavItem>
                                                        </Responsive>    
                                                    </Fragment>
                                                )
                                            }
                                        </Nav>
                                    </Collapse>
                                </Navbar>
                        </header>
                            {modal && <NewRequest open={modal} toggle={()=>dispatch({action: 'close'})} 
                                handleCreated={this.handleCreated} id={id}/>}
                                <Modal isOpen={isLoginModal} toggle={()=> dispatch({action: 'login'})}>
                                    <ModalBody>
                                        <Account ctx={ctx} showLinkFooter={false}/>
                                    </ModalBody>
                                </Modal>
                        </Fragment>
                    );
                }}
            </AuthConsumer>
        );
    };
};
Header.propTypes = {
    size: PropTypes.oneOf(["home", "private"]),
};

Header.contextType = AuthContext;

export default withRouter(Header);
