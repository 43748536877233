import React, { Component } from 'react';

import { Button } from "reactstrap";

import CommentInput from '../CommentInput/CommentInput';
import CommentsReadItem from '../CommentsReadItem/CommentsReadItem';
import Loading from '../Loading/Loading';

import XServices from '../../api/XServices';
import Validate from '../../api/Validate';
import Lang from '../../api/Lang';
import Typography from '../Typography/Typography';

const REFRESH_TIME = 30000;

class TabComments extends Component {
  constructor() {
    super();
    this.state = {
      message: '',
      loading: true,
      comments: null,
      lastId: null,
      firstId: null,
      ajaxRequest: null,
      timeout: null,
      issubmited: false,
      requesting: false,
      hasMore: false,
      fields: [
        {
          id: 'comment-input',
          value: '',
          valid: true,
          message: '',
          validations: [
            'required',
            {
              "name": "maxlength",
              "params": [1024]
            }
          ]
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.refreshComments = this.refreshComments.bind(this);
    this.markCommentAsView = this.markCommentAsView.bind(this);
    this.loadCommentsOlds = this.loadCommentsOlds.bind(this);
  }

  handleChange = (value, id) => {
    const fieldIndex = this.state.fields.findIndex(f => {
      return f.id === id;
    });

    let fields = [...this.state.fields];
    fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);
    this.setState({ fields: fields });
  };

  validateForm = () => {
    const fields = Validate.validateForm(this.state.fields);
    this.setState({ fields: fields });
    return fields;
  }

  handleSubmit = e => {
    e.preventDefault();

    let that = this;
    if (that.state.ranking < 5 && that.state.fields[0].value === '') {
      this.setState({ error: Lang.get("COMMENT.PLACEHOLDER") })
      return
    }
    if (that.state.ranking === 0) {
      this.setState({ error: Lang.get("COMMENT.RANKREQUIRED") })
      return
    }
    let fields = that.validateForm();

    if (!that.state.issubmited) {
      that.setState({ issubmited: true });
    }

    if (!Validate.validForm(fields)) {
      return;
    }
    that.setState({ requesting: true, error: '' });

    XServices.post('request/comment', {
      requestId: this.props.id,
      message: that.state.fields[0].value
    }, function (response) {
      that.setState(prevState => {
        const newItems = [...prevState.fields];
        newItems[0].value = '';
        return { fields: newItems, requesting: false, error: '', message: '' };
      })
      that.refreshComments();
    }, function (error) {
      that.setState({
        requesting: false,
        error: error.message
      });
    });
  }

  componentDidMount() {
    this.refreshComments();
  }

  componentWillUnmount() {
    if (this.state.ajaxRequest !== null) {
      this.state.ajaxRequest.cancel();
    }

    if (this.state.timeout !== null) {
      clearTimeout(this.state.timeout);
    }
  }

  markCommentAsView() {
    var that = this;
    var listIdComments = []
    that.state.comments && that.state.comments.map((item, i) => (
      item.viewdate === null && listIdComments.push(item.id)
    ))
    if (listIdComments.length > 0) {
      XServices.post('request/comment/view', {
        requestId: that.props.id,
        listId: listIdComments
      }, function (response) {
        let thatsComments = [...that.state.comments];
        let idx = 0;
        for (let comment of thatsComments) {
          for (let commentNew of listIdComments) {
            if (comment.id === commentNew) {
              comment.viewdate = response.viewdate;
              thatsComments[idx] = comment;
            }
          }
          idx++;
        }
        that.setState({ comments: thatsComments });
      }, function (error) {
      });
    }
  }

  refreshComments() {
    this.setState({ loading: true });

    if (this.state.ajaxRequest !== null) {
      this.state.ajaxRequest.cancel();
    }

    if (this.state.timeout !== null) {
      clearTimeout(this.state.timeout);
    }
    const ajaxRequest = XServices.get('request/comments/news', {
      requestId: this.props.id,
      lastId: this.state.lastId
    }, response => {
      const comments = this.state.comments === null ? response.comments : this.state.comments.concat(response.comments);
      comments.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
      const lastId = comments.length > 0 ? comments[comments.length - 1].id : this.state.lastId;
      const firstId = comments.length > 0 ? comments[0].id : this.state.firstId;
      const hasMore = response.comments.length > 0 ? response.hasMore : this.state.hasMore;
      const timeout = setTimeout(this.refreshComments, REFRESH_TIME);

      this.setState({
        loading: false,
        comments: comments,
        lastId: lastId,
        firstId: firstId,
        hasMore: hasMore,
        ajaxRequest: null,
        timeout: timeout
      });
      //document.getElementById('TabComments-list').scrollTop = document.getElementById('TabComments-list').scrollHeight;
    }, error => {
      this.setState({
        loading: false,
        error: error
      });
    });

    this.setState({ ajaxRequest: ajaxRequest, timeout: null });
  }

  loadCommentsOlds (){
    this.setState({ loading: true });
    if (this.state.ajaxRequest !== null) {
      this.state.ajaxRequest.cancel();
    }
    if (this.state.timeout !== null) {
      clearTimeout(this.state.timeout);
    }
    const ajaxRequest = XServices.get('request/comments/olds', {
      requestId: this.props.id,
      firstId: this.state.firstId
    }, response => {
      let newAndOlds = this.state.comments;
      if (response.comments.length > 0) {
        response.comments.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
        newAndOlds.unshift(...response.comments);
      }
      const firstId = response.comments.length > 0 ? response.comments[0].id : this.state.firstId;
      const hasMore = response.comments.length > 0 ? response.hasMore : this.state.hasMore;
      const timeout = setTimeout(this.refreshComments, REFRESH_TIME);
      this.setState({
        loading: false,
        comments: newAndOlds,
        firstId: firstId,
        hasMore: hasMore,
        ajaxRequest: null,
        timeout: timeout
      });
    }, error => {
      this.setState({
        loading: false,
        error: error
      });
    });

    this.setState({ ajaxRequest: ajaxRequest, timeout: null });
  }

  render() {
    const { comments, loading, fields, issubmited, requesting } = this.state;
    //console.log(comments comments.lenght)
    const { readonly } = this.props;

    if (this.props.tabCommentsActive) {
      this.markCommentAsView();
    }

    return (
      <div className="TabComments-container">
        <div className="title-chat-area mb-2">
          <Typography variant="small">
            <Typography variant="strong">
              {Lang.get("CHAT.TITLE")} 
              {this.props.role === 'RQT' ? Lang.get("CHAT.LAWYER") : Lang.get("CHAT.CLIENT")}
            </Typography>
          </Typography>
        </div>
        
        <div className="TabComments-list" id="TabComments-list">
          {this.state.hasMore === true &&
            <div className="notify-buttonarea">
              <Button
                color="link"
                size="sm"
                onClick={this.loadCommentsOlds}
                id="button-more"
              >
                {/* {Lang.get("NOTIFY.MORE")} */}
                Mostar mensajes anteriores
              </Button>
            </div>
          }
          {comments && comments.length === 0 && <div className="alert alert-warning">
            <Typography variant="p">
              {Lang.get("COMMENT.NOMESSAGE")} 
            </Typography>
          </div>}
          {comments && comments.map((item, i) => (
            <CommentsReadItem key={i}
              url={item.user.img_url}
              username={item.user.name}
              time_ago={item.createdate}
              content={item.text}
              owner={item.owner}
              sameBefore={item.sameBefore} />
          ))}
        </div>
        {loading && <div>
          <Loading variant="component" color="danger" />
        </div>}
        {(!readonly || (this.props.state === 'RPN' || this.props.state === 'FNS') ) && <div className="TabComments-input">
          <CommentInput
            color_button="success"
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            error={this.state.error}
            inputId="comment-input"
            id="comment-input"
            valid={fields[0].valid || !issubmited}
            message={fields[0].message}
            feedback={issubmited}
            disabled={requesting}
            requesting={requesting}
            value={this.state.fields[0].value}
            fields={fields[0]}
            indexField={0}
            placeholder={Lang.get("COMMENT.PLACEHOLDER")}
          />
        </div>}
      </div>
    );
  }
}

export default TabComments;
