import React, { Component, Fragment } from 'react';

import Typography from '../Typography/Typography';
import Avatar from '../Avatar/Avatar';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Lang from '../../api/Lang';
import logorevu from '../../resources/images/avatar-revu.png';

class LawyerNotificationsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
    };
    renderRedirect = () => {
        if (this.state.redirect) {
            const url = '/' + Lang.lancode + '/request/' + this.props.requestId;
            return <Redirect to={url} />
        }
    }
    handleClick = (e) => {
        e.preventDefault();
        this.setState({ redirect: true })
    }
    render() {
        const { id, type, url, entity, time_remaining, content, requiresAttention, requestId } = this.props;
        return (
            <Fragment>
                {this.renderRedirect()}
                <div className='ln-item'>
                    <div className='ln-avatar' onClick={this.handleClick}>
                        <Avatar name={entity} size="md" url={type === 'SYS' || type === 'RAS' ? logorevu : url} variant="primary" />
                    </div>
                    <div className='ln-content'>
                        <div className="ln-heading">
                            <span>
                                <Typography variant="strong">{entity}</Typography>
                                <Typography variant="small" color="muted">{Lang.get('NOTIFICATION.ACCTION')}</Typography>
                            </span>
                            <Typography variant="p">{content}</Typography>
                        </div>
                    </div>
                    <div className={requiresAttention === 'S' ? `ln-lastarea atention` : `ln-lastarea`}>
                        <div>
                            {requestId && <Typography variant="small">{Lang.get('NOTIFICATION.REQUESTID') + requestId}</Typography>}
                        </div>
                        <div>
                            <Typography variant="h5">{time_remaining}</Typography>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
};

LawyerNotificationsItem.propTypes = {
    url: PropTypes.string,
    entity: PropTypes.string.isRequired,
    time_remaining: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
};

export default LawyerNotificationsItem;
