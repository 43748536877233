import React from 'react';
import classnames from 'classnames';

//Ui
import { Nav, NavItem, NavLink } from 'reactstrap';

const TabsNav = props => {
    const { active, handleClick, items } = props;
    let navItems = items.map((item, i) =>
    <NavItem key={i}>
      <NavLink
          className={classnames({ active: active === item.id })}
          onClick={() => { handleClick(item.id); }}>
          {item.name}
      </NavLink>
    </NavItem>
    );
    return (
        <Nav tabs>
          {navItems}
        </Nav>
    );
};

export default TabsNav;
