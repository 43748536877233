import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { Badge } from 'reactstrap';

const BadgeLabel = props => {
    const { type, color, text } = props;
    return (
        <p className="badge-label">
          <Badge color={color}>
              {type === 'important' && <FontAwesomeIcon icon={faStar}/>}&nbsp;{text}
          </Badge>
        </p>
    );
};
BadgeLabel.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string.isRequired,
};
export default BadgeLabel;
