import React, { Fragment } from 'react';
import Typography from '../Typography/Typography';
import { UncontrolledTooltip } from 'reactstrap';
import Lang from '../../api/Lang';

const CategoryHeader = props => {
    const {slugs, categories} = props;
    let title;
    let total = categories.length;

    if (slugs === '' || slugs === null || slugs === undefined) {
        title = (<Typography variant="p">{Lang.get('CATEGORY.DESCRIPTION.HEADER.ALL')}</Typography>);
    } else if (total === 1) {
        title = (
            <div className="heading-category">
                <Typography variant="h4">{categories[0].name}</Typography>
                <Typography variant="p">{categories[0].description}</Typography>
            </div>
        );
    } else if (total > 1) {
        title = (
            <Fragment>
            <Typography variant="p">{Lang.get('CATEGORY.DESCRIPTION.HEADER.SELECTED')}&nbsp;
            {categories.map((item, i) =>
                (<Fragment key={i}>
                    <a href="#" id={"categoty-tooltip-" + i}>{item.name}</a>
                    {
                        (i < total - 2) ? "," : (i < total - 1) ? " y" : ""
                    }
                    &nbsp;
                    <UncontrolledTooltip placement="auto" target={"categoty-tooltip-" + i}>{item.description}</UncontrolledTooltip>
                </Fragment>)
            )}
            </Typography>
            </Fragment>
        );
    }
    return (
        <Fragment>
            {title}
        </Fragment>
    );
};

export default CategoryHeader;