import React from 'react';
import image404 from '../../resources/images/revu-404.png';
import Lang from '../../api/Lang';
import PropTypes from 'prop-types';

//Ui
import { Container } from 'reactstrap';
import Typography from '../../components/Typography/Typography';

const CustomError = props => {
    const { code, message } = props;
    let errortpl;
    switch(code) {
        case(404):
            errortpl = <img src={image404} alt="404"/>
            break;
        default :
            errortpl = <Typography variant="h1">{code}</Typography>;
    }
    return (
        <section className="error-type">
            <Container>
                <div className={`error-item ${errortpl && 'error-text'}`}>
                    {errortpl}
                </div>
                <div className="error-item">
                    <Typography variant="h3">
                        {Lang.get('ERROR.GLOBAL.TITLE')}
                    </Typography>
                    <Typography variant="h5">
                        {message ? message : Lang.get('ERROR.GLOBAL.DEFAULT')}
                    </Typography>
                </div>
            </Container>
        </section>
    );
};

CustomError.propTypes = {
    message: PropTypes.string,
    code: PropTypes.number,
};

export default CustomError;
