import React from 'react';
import { Route } from  'react-router-dom';
import { AuthConsumer } from '../config/AuthContext';
import Loading from '../components/Loading/Loading';
import Lang from '../api/Lang';

const PublicRoute = ({ aside: Aside , heading: Heading, component: Component, layout: Layout, child: Child, ...rest }) => {
    if (rest.computedMatch !== undefined && rest.computedMatch.params !== undefined && rest.computedMatch.params.lang !== undefined) {
      Lang.load(rest.computedMatch.params.lang);
    }
    return  (
    <AuthConsumer>
        {({loading})=> {
             return(
             !loading ?
            <Route {...rest} render={props => 
                <Layout
                    heading={Heading ? <Heading {...props}/>: null}
                    child={<Child {...props}/>}
                    aside={Aside ? <Aside {...props}/> : null}>
                    <Component {...props} />
                </Layout> 
            }/> : <Loading variant="fullscreen"/>
         )}}
    </AuthConsumer>
)};
export default PublicRoute;
