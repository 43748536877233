/* eslint-disable */
const Rut = {

  formatear:  function(value, digitoVerificador)
          {
          let sRut = new String(value);
          let sRutFormateado = '';
          let sDV;
          sRut = Rut.quitarFormato(sRut);
          if(digitoVerificador){
            sDV = sRut.charAt(sRut.length-1);
            sRut = sRut.substring(0, sRut.length-1);
          }
          while( sRut.length > 3 )
          {
            sRutFormateado = "." + sRut.substr(sRut.length - 3) + sRutFormateado;
            sRut = sRut.substring(0, sRut.length - 3);
          }
          sRutFormateado = sRut + sRutFormateado;
          if(sRutFormateado != "" && digitoVerificador)
          {
            sRutFormateado += "-"+sDV;
          }
          else if(digitoVerificador)
          {
            sRutFormateado += sDV;
          }

          return sRutFormateado;
        },

  quitarFormato: function(rut)
        {
          let strRut = new String(rut);
          while( strRut.indexOf(".") != -1 )
          {
          strRut = strRut.replace(".","");
          }
          while( strRut.indexOf("-") != -1 )
          {
          strRut = strRut.replace("-","");
          }

          return strRut;
        },

  digitoValido: function(dv)
        {
          if ( dv != '0' && dv != '1' && dv != '2' && dv != '3' && dv != '4'
            && dv != '5' && dv != '6' && dv != '7' && dv != '8' && dv != '9'
            && dv != 'k'  && dv != 'K')
          {
            return false;
          }
          return true;
        },

  digitoCorrecto:   function(crut)
          {
            const largo = crut.length;
            let rut, dv;
            if ( largo < 2 )
            {
              return false;
            }
            if(largo > 2)
            {
              rut = crut.substring(0, largo - 1);
            }
            else
            {
              rut = crut.charAt(0);
            }
            dv = crut.charAt(largo-1);
            Rut.digitoValido(dv);

            if(rut == null || dv == null)
            {
              return 0;
            }

            const dvr = Rut.getDigito(rut);

            if (dvr != dv.toLowerCase())
            {
              return false;
            }
            return true;
          },

  getDigito:    function(rut)
        {
          let dvr = '0';
          let suma = 0;
          let mul  = 2;
          let i;
          for(i=rut.length -1;i >= 0;i--)
          {
            suma = suma + rut.charAt(i) * mul;
            if (mul == 7)
            {
              mul = 2;
            }
            else
            {
              mul++;
            }
          }
          const res = suma % 11;
          if (res==1)
          {
            return 'k';
          }
          else if(res==0)
          {
            return '0';
          }
          else
          {
            return 11-res;
          }
        },

  validar:   function(texto)
        {
          texto = Rut.quitarFormato(texto);
          let largo = texto.length;
          let i, j;

          // rut muy corto
          if ( largo < 2 )
          {
            return false;
          }

          // verifica que los numeros correspondan a los de rut
          for (i=0; i < largo ; i++ )
          {
            // numero o letra que no corresponda a los del rut
            if(!Rut.digitoValido(texto.charAt(i)))
            {
              return false;
            }
          }

          let invertido = "";
          for(i=(largo-1),j=0; i>=0; i--,j++)
          {
            invertido = invertido + texto.charAt(i);
          }
          let dtexto = "";
          dtexto = dtexto + invertido.charAt(0);
          dtexto = dtexto + '-';
          let cnt = 0;

          for ( i=1,j=2; i<largo; i++,j++ )
          {
            if ( cnt == 3 )
            {
              dtexto = dtexto + '.';
              j++;
              dtexto = dtexto + invertido.charAt(i);
              cnt = 1;
            }
            else
            {
              dtexto = dtexto + invertido.charAt(i);
              cnt++;
            }
          }

          invertido = "";

          for (i=(dtexto.length-1),j=0; i>=0; i--,j++)
          {
            invertido = invertido + dtexto.charAt(i);
          }

          if (Rut.digitoCorrecto(texto))
          {
            return true;
          }
          return false;
        }
};

export default Rut;
