import React, {Component} from 'react';

import MyAccountAllRequest from './MyAccountAllRequest';

class AllRequestAvaliable extends Component { 
    render(){
        return <MyAccountAllRequest status="requested"/>;
    }
}

export default AllRequestAvaliable;