import React, { Component, Fragment } from 'react';
import XServices from '../../api/XServices';
import { AuthConsumer } from '../../config/AuthContext';

//Components
import FileUpload from '../../components/FileUpload/FileUpload';
import FileItem from '../../components/FileUpload/FileItem';
import CustomAlert from '../../components/Alert/CustomAlert';
import Validate from '../../api/Validate';
import InputField from '../../components/InputField/InputField';
import Lang from '../../api/Lang';

//Ui
import { Button, Form, FormGroup, Label, FormText, CustomInput, FormFeedback } from 'reactstrap';

class MyAccount extends Component {
    constructor() {
        super();
        this.state = {
            password: '',
            newPassword: '',
            user_social: null,
            success: false,
            message: '',
            files: [],
            filename: '',
            fileError: false,
            uploading: null,
            issubmited: false,
            requesting: false,
            togglePass: false,
            toggleTelegramSubscription: false,
            errorFileMessage: false,
            fields: [
                {
                    id: 'nombre-profile',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                        'required',
                        {
                            "name": "maxlength",
                            "params": [512]
                        },
                    ],
                },
                {
                    id: 'current-password',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                        {
                            "name": "minlength",
                            "params": [8]
                        },
                        {
                            "name": "maxlength",
                            "params": [16]
                        }]
                },
                {
                    id: 'new-password',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                        {
                        "name": "password",
                        "params": [8,16]
                    }]
                },
                {
                    id: "telegramAlias",
                    value: '',
                    message: '',
                    valid: true,
                    validations: [
                        'telegramAlias',
                        {
                            "name": "minlength",
                            "params": [5]
                        }
                    ]
                }
            ],
        };
        this.handleFiles = this.handleFiles.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
    };
    componentDidMount() {

        let value = this.context;
        let init = {};
        this.setState(prevState => {
            const newItems = [...prevState.fields];
            newItems[0].value = value.user.name;
            if (value.user.telegram_alias) {
                newItems[3].value = value.user.telegram_alias;
                init.toggleTelegramSubscription = true;
            }

            init.fields = newItems;
            init.user_social = value.user.user_social;

            return init;
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        const { filename } = this.state;
        this.setState({ success: false, fileError: false });
        let fields = this.validateForm();
        if (!this.state.issubmited) {
            this.setState({ issubmited: true, });
        }

        if (!Validate.validForm(fields)) {
            return;
        }

        this.setState({ success: false, requesting: true, error: '', fileError: false, errorFileMessage: false });
        XServices.post('/account/save', {
            name: this.state.fields[0].value,
            password: this.state.fields[1].value,
            newPassword: this.state.fields[2].value,
            telegramAlias: this.state.fields[3].value,
            img: filename.length > 0 ? filename : ''
        }, response => {
            this.setState(prevState => {
                const reset = [...prevState.fields]
                reset[1].value = '';
                reset[2].value = '';
                return {
                    fields: reset,
                    requesting: false,
                    success: true,
                    message: response.message,
                    error: false,
                };
            })
            this.context.refresh();
        }, error => {
            this.setState({
                requesting: false,
            });
            if (error.code === 123) {
                this.setState({
                    fileError: true
                });
            };

            if (error.code === 121) {
                this.setState(prevState => {
                    const newItems = [...prevState.fields];
                    newItems[1].message = error.message;
                    newItems[1].valid = false;
                    return {
                        success: false,
                        fields: newItems,
                        issubmited: true,
                    };
                });
            }
        });
    };

    validateForm = () => {
        const fields = Validate.validateForm(this.state.fields);
        this.setState({ fields: fields });
        return fields;
    };

    handleFiles = (arg) => {
        this.setState({
            fileError: false,
            errorFileMessage: false,
        });
        this.setState({
            files: arg.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })),
            uploading: true,
        });
        var data = new FormData();
        data.append('files', arg[0]);
        data.append('token', XServices.token());
        XServices.upload('file/upload', data, response => {
            this.setState({ uploading: false, filename: response.filename })
        }, error => {
            if (error.code === 126) {
                this.setState({
                    fileError: true,
                    errorFileMessage: true,
                });
            }
        });
    };

    handleChange = (value, id) => {
        const fieldIndex = this.state.fields.findIndex(f => {
            return f.id === id;
        });

        let fields = [...this.state.fields];
        fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);

        this.setState({ fields: fields });
    };

    togglePassword = () => {

        this.setState({
            togglePass: !this.state.togglePass,
        });
        this.setState(prevState => {
            const withPass = [...prevState.fields];
            if (!this.state.togglePass) {
                withPass[1].validations.unshift('required');
                withPass[2].validations.unshift('required');
            } else {
                withPass[1].validations.shift();
                withPass[2].validations.shift();
                withPass[1].value = '';
                withPass[2].value = '';
            }
            return {
                fields: withPass
            };
        });
    };

    toggleTelegramSubscription = () => {
        this.setState({toggleTelegramSubscription: !this.state.toggleTelegramSubscription});

        this.setState(prevState => {
            let fields = [...prevState.fields];
            if (!this.state.toggleTelegramSubscription) {
                fields[3].validations.unshift('required');
            } else {
                fields[3].validations.shift();
                fields[3].value = '';
            }
            return {
                fields: fields
            };
        });
    }

    render() {
        const { fileError, requesting, fields, issubmited, files, success, message, uploading, errorFileMessage, user_social } = this.state;
        return (
            <div className="myaccount-wrapper">
                <Form onSubmit={this.handleSubmit}>
                    {success &&
                        <CustomAlert type="success" message={message} />}
                    <FormGroup>
                        <Label for="nombre-profile">{Lang.get('MYACCOUNT.UPDATE.USER.TITLE')}</Label>
                        <InputField
                            handleChangeInput={this.handleChange}
                            inputId="nombre-profile"
                            id="nombre-profile"
                            type="T"
                            valid={fields[0].valid || !issubmited}
                            size="lg" message={fields[0].message}
                            feedback={issubmited}
                            disabled={requesting || user_social}
                            value={this.state.fields[0].value} />
                        <FormText>{Lang.get('MYACCOUNT.UPDATE.USER.LABEL')}</FormText>
                    </FormGroup>
                    {!user_social &&
                        <Fragment>
                            <FormGroup>
                                <div className="file-upload-wrapper">
                                    <div className="file-reason">
                                        <div className="file-reason-info">
                                            <Label for="studyPlace-profile">{Lang.get('MYACCOUNT.UPDATE.AVATAR.TITLE')}</Label>
                                            {!fileError ?
                                                <FormText>
                                                    {Lang.get('MYACCOUNT.UPDATE.AVATAR.LABEL')}
                                                </FormText> :
                                                <FormFeedback style={{ display: 'block', fontSize: '1em' }}>
                                                    {errorFileMessage ? Lang.get('MYACCOUNT.UPDATE.AVATAR.ERROR_MAX') :
                                                        Lang.get('MYACCOUNT.UPDATE.AVATAR.ERROR')}
                                                </FormFeedback>}
                                        </div>
                                        <div className="file-reason-action">
                                            <FileUpload onDrop={this.handleFiles} />
                                        </div>
                                    </div>
                                    <div className="file-preview">
                                        {files.map((item, i) => (
                                            <FileItem data={item} key={i} id={i} />
                                        ))}
                                    </div>
                                </div>
                            </FormGroup>

                            <FormGroup className="form-toggle-options">
                                <div className="flex-right">
                                    <CustomInput
                                        type="checkbox"
                                        id="change-pass"
                                        label={Lang.get('MYACCOUNT.UPDATE.CHANGEPASS.LABEL')}
                                        onChange={this.togglePassword}
                                        checked={this.state.togglePass} />
                                </div>
                            </FormGroup>
                        </Fragment>
                    }

                    {this.state.togglePass &&
                        <Fragment>
                            <FormGroup>
                                <Label for="current-password">{Lang.get('MYACCOUNT.UPDATE.CURRENTPASS.TITLE')}</Label>
                                <InputField
                                    handleChangeInput={this.handleChange}
                                    inputId="current-password"
                                    id="current-password"
                                    type="P"
                                    valid={fields[1].valid || !issubmited}
                                    size="lg" message={fields[1].message}
                                    feedback={issubmited}
                                    disabled={requesting}
                                    value={this.state.fields[1].value} />
                                <FormText>{Lang.get('MYACCOUNT.UPDATE.CURRENTPASS.LABEL')}</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="newpassword-profile">{Lang.get('MYACCOUNT.UPDATE.NEWPASS.TITLE')}</Label>
                                <InputField
                                    handleChangeInput={this.handleChange}
                                    inputId="new-password"
                                    id="new-password"
                                    type="P"
                                    valid={fields[2].valid || !issubmited}
                                    size="lg" message={fields[2].message}
                                    feedback={issubmited}
                                    disabled={requesting}
                                    value={this.state.fields[2].value} />
                                <FormText>{Lang.get('MYACCOUNT.UPDATE.NEWPASS.LABEL')}</FormText>
                            </FormGroup>
                        </Fragment>}

                    <FormGroup>
                        <CustomInput
                            type="checkbox"
                            id="subscribeTelegram"
                            label={Lang.get("MYACCOUNT.UPDATE.TOGGLESUBSCRIPTION.LABEL")}
                            onChange={this.toggleTelegramSubscription}
                            checked={this.state.toggleTelegramSubscription}
                        />
                        {this.state.toggleTelegramSubscription && 
                            <FormGroup>
                                <Label for="telegramAlias">{Lang.get('MYACCOUNT.UPDATE.TELEGRAMALIAS.TITLE')}</Label>
                                <InputField

                                    handleChangeInput={this.handleChange}
                                    inputId="telegramAlias"
                                    id="telegramAlias"
                                    type="T"
                                    valid={fields[3].valid || !issubmited}
                                    size="lg" message={fields[3].message}
                                    feedback={issubmited}
                                    disabled={requesting}
                                    value={this.state.fields[3].value}
                                />
                                <FormText>{Lang.get('MYACCOUNT.UPDATE.TELEGRAMALIAS.LABEL')}</FormText>
                            </FormGroup>
                        }
                    </FormGroup>

                    {!user_social &&
                        <FormGroup className="buttons-form-action">
                            {this.state.uploading === null ?
                                <Button type="submit" size="lg" color="danger">{Lang.get('FORM.GLOBAL.SAVE')}</Button> :
                                <Button type="submit" size="lg" color="danger" disabled={Boolean(uploading)}>{Lang.get('FORM.GLOBAL.SAVE')}</Button>}
                        </FormGroup>
                    }
                </Form>
            </div>
        );
    };
};
MyAccount.contextType = AuthConsumer;
export default MyAccount;
