import React, { Component, Fragment } from 'react';
import XServices from '../../api/XServices';

import Typography from '../Typography/Typography';
import Avatar from '../Avatar/Avatar';
import InputField from '../InputField/InputField';
import PropTypes from 'prop-types';
import Lang from '../../api/Lang';
import Validate from '../../api/Validate';

import { ModalBody, ModalFooter, Button, Alert } from 'reactstrap';
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

class ToCloseRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.person.requestId,
            message: null,
            error: false,
            errorEnvio: '',
            issubmited: false,
            requesting: false,
            userDoc: null,
            uploading: false,
            errorUploading: '',
            fields: [
                {
                    id: 'toClose-comment',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                        "required",
                        {
                            "name": "maxlength",
                            "params": [1024]
                        }
                    ]
                },
                {
                    id: 'toClose-file',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                        "required"
                    ]
                }
            ]
        };
        this.uploadRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.getDownloadUrl = this.getDownloadUrl.bind(this);
    };

    getDownloadUrl(doc) {
        return doc.url + "?"
            + "id=" + this.props.person.requestId
            + "&doc=" + doc.id
            + "&type=" + doc.type
            + "&token=" + XServices.token();
    }

    getFilename(filename) {
        return filename.length > 45 ? filename.substring(0, 42) + "..." + filename.substring(filename.length - 5, filename.length) : filename;
    }

    handleUpload() {
        var data = new FormData();

        data.append('files', document.getElementById('requestNewFile').files[0]);
        data.append('token', XServices.token());

        this.setState({ uploading: true, errorUploading: '' });

        XServices.upload('file/upload',
            data
            , response => {
                this.setState({ uploading: false, userDoc: response });
                this.handleChange(response, "toClose-file");
            }, error => {
                let fields = this.state.fields;
                fields[1].value = ""
                fields[1].valid = false
                this.setState({
                    uploading: false,
                    errorUploading: error.message,
                    userDoc: null,
                    issubmited: false,
                    fields: fields
                });
            });
    }

    handleChange = (value, id) => {
        const fieldIndex = this.state.fields.findIndex(f => {
            return f.id === id;
        });

        let fields = [...this.state.fields];
        fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);
        this.setState({ fields: fields });
    };

    handleSubmit = e => {
        let fields = this.validateForm();

        if (!this.state.issubmited) {
            this.setState({ issubmited: true, errorUploading: "" });
        }

        if (!Validate.validForm(fields)) {
            return;
        }

        this.setState({ requesting: true, error: false });

        XServices.post('request/close/petition', {
            requestId: this.state.id,
            text: this.state.fields[0].value,
            file: this.state.userDoc.filename
        }, (response) => {
            this.setState({
                requesting: false,
                error: false,
                message: response.message
            });
        }, (error) => {
            let fields = this.state.fields;
            fields[1].value = ""
            fields[1].valid = false
            this.setState({
                requesting: false,
                error: true,
                // message: error.message,
                errorUploading: error.message,
                userDoc: null,
                issubmited: false,
                fields: fields
            });
        });
    }

    validateForm = () => {
        const fields = Validate.validateForm(this.state.fields);
        this.setState({ fields: fields });
        return fields;
    }

    render() {
        const { person, toggle } = this.props;
        const { fields, issubmited, requesting, message, error, userDoc, errorUploading, uploading } = this.state;
        return (
            <Fragment>
                <ModalBody className="no-padding-botton">
                    <Avatar
                        name={person.userName}
                        size="lg"
                        url={person.userImageUrl}
                        variant="primary" />
                    <Typography variant="small">
                        {Lang.get("TOCLOSE.TITLE")}
                    </Typography>
                    <Typography variant="h4">{person.taskName}</Typography>
                    <Typography variant="h5">{person.userName}</Typography>
                    <div className="fields">
                        <Typography variant="p">{Lang.get("TOCLOSE.DESCRIPTION")}</Typography>
                        <InputField
                            handleChangeInput={this.handleChange}
                            inputId="toClose-comment"
                            id="toClose-comment"
                            type="A"
                            valid={fields[0].valid || !issubmited}
                            message={fields[0].message}
                            feedback={issubmited}
                            value={fields[0].value}
                            disabled={uploading}
                            placeholder={Lang.get("TOCLOSE.INPUT.PLACEHOLDER")}
                        />
                        <div className="request-doc-wrapper">
                            <div className={!fields[1].valid ? `request-last-up-down is-invalid` : `request-last-up-down`} id="toClose-file">
                                <div className="item">
                                    <Typography variant="h5">{Lang.get("REQUEST.DOCUMENT.UPLOAD")}</Typography>
                                    <Typography variant="p">{Lang.get("TOCLOSE.DOCUMENT.UPLOAD.DESCRIPTION")}</Typography>
                                    <Typography variant="p">{Lang.get("TOCLOSE.BTN.FILEUPLOADED")}</Typography>
                                    {userDoc &&
                                        <Button className="link break-word" color="link" size="xs" href={this.getDownloadUrl(userDoc)} target="_blank">{this.getFilename(userDoc.filename)}</Button>
                                    }
                                </div>
                                <div className="item">
                                    <div className="icon">
                                        {!uploading ?
                                            <a onClick={() => { this.uploadRef.current.click() }}>
                                                <FontAwesomeIcon icon={faArrowCircleUp} />
                                            </a>
                                            : <Loading variant="component" color="danger" />}
                                    </div>
                                    <div className="d-none">
                                        <input id="requestNewFile" type="file" ref={this.uploadRef} onChange={this.handleUpload} />
                                        {/* accept=".docx" */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {errorUploading !== '' &&
                            <div className="invalid-feedback">
                                <Typography variant="p">{errorUploading}</Typography>
                            </div>
                        }
                        {(!fields[1].valid && errorUploading === '') &&
                            <div className="invalid-feedback">
                                <Typography variant="p">{fields[1].message}</Typography>
                            </div>
                        }
                        {message !== null &&
                            <Alert color={error ? `danger` : `success`} className="alert-custom" >
                                <div className="alert-message">
                                    <Typography variant="p">{message}</Typography>
                                </div>
                            </Alert>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="new-footer">
                        {message === null ?
                            <Fragment>
                                <Button color="danger" name={person.requestId} disabled={Boolean(requesting) || Boolean(uploading)} onClick={() => this.handleSubmit(person.requestId)}>
                                    {Lang.get('TOCLOSE.BTN.ACCEPT')}
                                </Button>
                                <Button color="danger" disabled={Boolean(requesting) || Boolean(uploading)} onClick={toggle}>
                                    {Lang.get('TOCLOSE.BTN.CANCEL')}
                                </Button>
                            </Fragment>
                            :
                            <Button color="danger" name={person.requestId} onClick={toggle}>
                                {Lang.get('TOCLOSE.BTN.CLOSE')}
                            </Button>
                        }
                    </div>
                </ModalFooter>
            </Fragment>

        );
    };
};

ToCloseRequest.propTypes = {
    person: PropTypes.array.isRequired,
    toggle: PropTypes.func.isRequired
}

export default ToCloseRequest;
