import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import Lang from '../../api/Lang';

const FooterMenuItem = (props) => {
    let {type, link, text, icon } = props;
    const handleRegister = ()=> {
        document.getElementsByClassName("register")[0].click();
    }
    if(type === 'register'){
        return (
            <NavItem>
                <NavLink href="#" onClick={handleRegister}>{text && Lang.get(text)}</NavLink>
            </NavItem>
        )
    }
    return (
        <NavItem>
            {type == 'external' ? 
            <NavLink href={link} target={'_blank'}>{icon && <FontAwesomeIcon icon={icon} />}{text && Lang.get(text)}
            </NavLink>
            : 
            <NavLink tag={RRNavLink} exact to={'/' + Lang.lancode + link}>{icon && <FontAwesomeIcon icon={icon} />}{text && Lang.get(text)}</NavLink>
            }
        </NavItem>
    );
};

export default FooterMenuItem;