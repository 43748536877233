import React, {Fragment} from 'react';
import Dropzone from 'react-dropzone';
import {FormFeedback} from 'reactstrap';

//Ui
import { Button } from 'reactstrap';

import Lang from '../../api/Lang';

const FileUpload = props => (
    <Fragment>
        <Dropzone onDrop={files => props.onDrop(files)} disabled={props.disabled}>
            {({getRootProps, getInputProps}) => (
                <Button type="button" size="xs" {...getRootProps({ className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    {props.disabled ? Lang.get("FILEUPLOAD.UPLOADING") : Lang.get("FILEUPLOAD.SELECT")}
                </Button>
            )}
        </Dropzone>
        {props.feedback && <FormFeedback invalid={!props.valid ? "invalid": null} className="d-block">{props.message}</FormFeedback>}
    </Fragment>
);

export default FileUpload;
