import React, { Component, Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';
import herologin from '../../resources/images/hero-login.jpg';
import XServices from '../../api/XServices';
import Validate from '../../api/Validate';

import {withRouter} from 'react-router-dom';

//Components
import HelpFooter from '../../components/HelpFooter/HelpFooter';
import Typography from '../../components/Typography/Typography';
import Account from '../../components/Login/Account';
import Loading from '../../components/Loading/Loading';
import NewRequestHeader from '../../components/NewRequest/NewRequestHeader';
import NewRequestPayment from '../../components/NewRequest/NewRequestPayment';
import NewRequestBody from '../../components/NewRequest/NewRequestBody';

//Ui
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

class NewRequest extends Component {
  constructor() {
    super();
    this.state = {
      selectedPrice: null,
      data: null,
      notariesFields: null,
      loading: true,
      submited: false,
      creating: false,
      creatingError: '',
      backdrop: 'static',
      idNotary: null,
      moduleNotary: true,
      acceptTerms: false,
      isBackButtonClicked: false
    };
    this.handleChoice = this.handleChoice.bind(this);
    this.doCreate = this.doCreate.bind(this);
    this.invokeCreate = this.invokeCreate.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleExternalLogin = this.handleExternalLogin.bind(this);
    this.loadLocalValues = this.loadLocalValues.bind(this);
    this.handleSendIdNotary = this.handleSendIdNotary.bind(this);
    this.setStateNotary = this.setStateNotary.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
  }

  componentDidMount() {
    let that = this;
    this.props.history.push({hash: "newrequest"});
    window.addEventListener('popstate', this.handleBackButton);
    XServices.get('task/info', {
      id: this.props.id
    }, function (response) {
      let selectedPrice = null;

      for (const item of response.prices) {
        if (item.default) {
          selectedPrice = item.id;
        }
      }

      let fields = [...response.fields];
      let i = 0;
      for (let field of response.fields) {
        if (field.dependency !== undefined) {
          const fieldIndex = response.fields.findIndex(f => {
            return f.id === field.dependency.id;
          });

          let validations = [...field.additional.validations];
          validations.unshift({
            name: "depends",
            params: [function () {
              return that.state.data.fields[fieldIndex].value === field.dependency.value;
            }]
          });

          fields[i].additional.validations = [...validations];
          field.dependency.index = fieldIndex;

        } else if (field.mandatory) {
          let validations = [...field.additional.validations];
          validations.unshift("required");
          fields[i].additional.validations = [...validations];
        }

        i++;
      };
      response.fields = that.loadLocalValues(fields);
      that.setState({ data: response, loading: false, selectedPrice: selectedPrice, acceptTerms: false, moduleNotary: response.requirenotary });
    }, function (error) {

    });
  }

  handleBackButton (e) {
    e.preventDefault();
    if (!this.isBackButtonClicked) {
      this.props.toggle();
      if (this.props.location.hash.indexOf("#newrequest") !== -1) {
        this.isBackButtonClicked = true;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
    if (this.props.location.hash.indexOf("#newrequest") !== -1) {
      window.history.back();
    }
  }

  handleSendIdNotary = idNotary => {
    this.setState({ idNotary: idNotary });
  }

  handleRemoveFile = arg => {

    const data = { ...this.state.data };
    let i = 0;
    for (let item of this.state.data.fields) {
      const field = { ...item };
      if (field.id === arg) {
        field.value = "";
      }
      data.fields[i] = field;
      i++;
    }

    this.setState({ data: data });
  };

  handleChoice = arg => {
    this.setState({ selectedPrice: arg });
  };

  handleNotary = (arg) => {
    this.setState({ moduleNotary: arg === "S"});
  }

  handleTerms = () => {
    this.setState({acceptTerms: !this.state.acceptTerms});
  }

  handlePay = () => {
    let that = this;
    let firstFailedId = that.validateForm();

    if (!that.state.issubmited) {
      that.setState({ issubmited: true });
    }
    if (that.isValidForm()) {
      that.doCreate();
    } else if (firstFailedId !== null){
      let fieldFailed = document.getElementById("field-" + firstFailedId);
      if (fieldFailed !== undefined) {
        fieldFailed.focus();
      }
    }
  }

  setStateNotary(notaries) {
    this.setState({ notariesFields: notaries });
  }

  isValidForm = () => {
    let valid = true;

    for (const field of this.state.data.fields) {
      if (field.type !== 'F' && !field.valid) {
        valid = false;
        break;
      }
    }
    if (this.state.moduleNotary) {
      for (const field of this.state.notariesFields) {
        if (!field.valid) {
          valid = false;
          break;
        }
      }
    }
    return valid;
  }

  validateForm = () => {
    const data = { ...this.state.data };
    let firstFailedId = null;

    let i = 0;
    for (let item of this.state.data.fields) {
      const field = { ...item };

      if (field.value === undefined || field.value === null) {
        field.value = "";
      }

      field.message = Validate.validate(field.value, field.additional.validations);
      field.valid = field.message === "";
      data.fields[i] = field;

      if (firstFailedId === null && !field.valid) {
        firstFailedId = field.id;
      }

      i++;
    }
    if (this.state.moduleNotary) {
      const notariesFields = [...this.state.notariesFields];
      i = 0;
      for (let item of this.state.notariesFields) {

        if (item.value === undefined || item.value === null) {
          item.value = "";
        }

        item.message = Validate.validate(item.value, item.additional.validations);
        item.valid = item.message === "";
        i++;
      }
      this.setState({notariesFields: notariesFields });
    }
    this.setState({ data: data });
    return firstFailedId;
  }

  handleChangeInput = (value, id) => {
    const fieldIndex = this.state.data.fields.findIndex(f => {
      return f.id === id;
    });

    const field = { ...this.state.data.fields[fieldIndex] };
    field.value = value;
    field.message = Validate.validate(field.value, field.additional.validations);
    field.valid = field.message === "";

    let data = { ...this.state.data };
    data.fields[fieldIndex] = field;
    data = this.handleRecursionChange(data, "", id);
    this.setState({ data: data });
  }

  handleRecursionChange(data, value, id) {
    for (let field of data.fields) {
      if ((field.dependency !== undefined) && (field.dependency.id === id) && (field.type === 'L')){
        const indexDependency = data.fields.indexOf(field);
        const fieldDependency = { ...data.fields[indexDependency] };
        fieldDependency.value = value;
        data.fields[indexDependency] = fieldDependency;
        this.handleRecursionChange(data, fieldDependency.value, fieldDependency.id);
      }
    }
    return data;
  }

  handleExternalLogin = () => {
    sessionStorage.setItem('newRequestLocal', JSON.stringify(this.state.data));
  }

  loadLocalValues = fields => {
    let request = sessionStorage.getItem('newRequestLocal');
    let newfields = [...fields];

    if (request !== null) {
      const item = JSON.parse(request);
      if (item.id !== undefined) {
        let i = 0;
        for (let field of item.fields) {
          if (field.id === newfields[i].id) {
            newfields[i] = field;
          }
          i++;
        }
      }
      sessionStorage.removeItem('newRequestLocal');
    }

    return newfields;
  };

  getTotalToUpload(fields) {
    let totalToUpload = 0;
    for (let field of fields) {
      if (field.type === 'F' && field.value !== '') {
        totalToUpload++;
      }
    }
    return totalToUpload;
  }

  doCreate() {
    this.setState({ creating: true, creatingError: '' });

    let fields = [...this.state.data.fields];
    const totalToUpload = this.getTotalToUpload(fields);

    let counter = 0;
    let totalUploaded = 0;

    for (let field of fields) {
      let idx = counter;

      if (field.type === 'F' && field.value !== '') {
        var data = new FormData();
        data.append('files', document.getElementById("field-" + field.id).files[0]);
        data.append('token', XServices.token());

        XServices.upload('file/upload', data, response => {
          fields[idx].value = response.filename;
          totalUploaded++;

          if (totalUploaded === totalToUpload) {
            this.invokeCreate(this.props.id, this.state.selectedPrice, fields, this.state.moduleNotary ? this.state.idNotary : "");
          }
        }, error => {
          this.setState({ creating: false, creatingError: error.message });
        });
      }
      counter++;
    }

    if (totalToUpload === 0) {
      this.invokeCreate(this.props.id, this.state.selectedPrice, fields, this.state.moduleNotary ? this.state.idNotary : "");
    }
  }

  invokeCreate(taskId, price, fields, notaryId) {
    let fieldValue = []
    const actualfields = [...fields];
    actualfields.map((field, index) => {
      if (field.type === 'D' && field.value !== '') {
        fieldValue = field.value.split("-");
        if (fieldValue[2].length == 4) {
          actualfields[index].value = fieldValue[2] + "-" + fieldValue[1] + "-" + fieldValue[0];
        }
      }
      return null;
    });
    XServices.post('request/create', {
      taskId: taskId,
      price: price,
      notaryId: notaryId,
      fields: JSON.stringify(fields)
    }, response => {
      if (response.url !== null) {
        this.props.handleCreated(response);
      } else {
        this.setState({ creating: false, creatingError: response.message });
      }
    }, error => {
      this.setState({ creating: false, creatingError: error.message });
    });
  }

  render() {
    const { open, toggle } = this.props;
    const { selectedPrice, loading, data, creating, creatingError, backdrop, moduleNotary, notariesFields, acceptTerms } = this.state;
    return (
      <AuthConsumer>
        {({ isAuth, ctx, dispatch }) => (

          <Modal isOpen={open} toggle={toggle} className="modal-request" backdrop={backdrop} keyboard={!creating}>
            {loading ? <Loading variant="component" /> :
              <Fragment>
                <NewRequestHeader data={data} toggle={toggle} />
                <ModalBody>
                <NewRequestBody data={data} handleRemoveFile={this.handleRemoveFile} handleChangeInput={this.handleChangeInput} inProgress={creating} isAuth={isAuth} handleSendIdNotary={this.handleSendIdNotary} moduleNotary={moduleNotary} notariesFields={notariesFields} setStateNotary={this.setStateNotary} handleNotary={this.handleNotary} />
                </ModalBody>
                <ModalFooter>
                  {!isAuth &&
                    <div className="modal-login">
                      <div className="image-login">
                        <img src={herologin} alt="Registrate" />
                        <span>
                          <Typography variant="h2">Optimiza</Typography>
                          <Typography variant="h2">tu tiempo!</Typography>
                        </span>
                      </div>
                      <div className="form-login">
                        <Account ctx='signin' handleExternalLogin={this.handleExternalLogin}  showLinkFooter={true} />
                      </div>
                    </div>}
                  <NewRequestPayment data={data} choice={this.handleChoice} selectedPrice={selectedPrice} handlePay={this.handlePay} inProgress={creating} error={creatingError} handleTerms={this.handleTerms} acceptTerms={acceptTerms} />
                  <HelpFooter />
                </ModalFooter>
              </Fragment>
            }
          </Modal>
        )}
      </AuthConsumer>
    );
  };
};

//export default NewRequest;
export default withRouter(NewRequest);