import React, { Fragment } from 'react';

import {Button} from 'reactstrap';

//Ui
import Typography from '../Typography/Typography';
import CustomAlert from '../Alert/CustomAlert';
import Lang from '../../api/Lang';

const DisponibilitySchedule = props => {
    return (
        <div className="schedule-wrapper">
            <div className="schedule-title">
                {Lang.get('DISPONIBILITY.TITLE')}
            </div>
            <div className="schedule-content">
                <div className={"schedule-content-area " + (props.data.contact_name !== null ? "d-flex align-items-center" : 'pr-4')}>
                    {props.data.contact_name !== null ? 
                        <Typography variant="p" asHTML={true}>{Lang.get('DISPONIBILITY.CONTACT', [props.data.contact_name, props.data.contact_email])}</Typography>
                        : <Fragment>
                            <Typography variant="p">{Lang.get('DISPONIBILITY.REQUEST.CONTACT')}</Typography>
                            <Button className="mt-2" color="danger" size="block btn-sm" onClick={props.handleContact}>{Lang.get('DISPONIBILITY.REQUEST.ACTION')}</Button>
                        </Fragment>
                    }
                </div>
                <div className="schedule-content-area">
                    <div className="schedule-location">
                        <Typography variant="small"><Typography variant="strong">{props.data.fullname}</Typography></Typography>
                        <Typography variant="small">{props.data.address}</Typography>
                        <Typography variant="small">{Lang.get('DISPONIBILITY.PHONE')}: {props.data.phone}</Typography>
                        <Typography variant="small">{props.data.schedule}</Typography>
                    </div>
                </div>
            </div>
            {props.data.contact_name === null && props.contactRequest === true &&
                <CustomAlert type={props.contactRequestStatus ? "success" : "primary"} message={Lang.get(props.contactRequestStatus ? 'DISPONIBILITY.REQUEST.SUCCESS' : 'DISPONIBILITY.REQUEST.FAIL')} onDismiss={() => {props.handleDismissContact()}}/>
            }
        </div>
    );
};

export default DisponibilitySchedule;