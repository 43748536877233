import React, { Component, Fragment } from 'react';
import { AuthConsumer } from '../../config/AuthContext';

//Components
import Avatar from '../Avatar/Avatar';
import Typography from '../Typography/Typography';
import BadgeLabel from '../BadgeLabel/BadgeLabel';
import Lang from '../../api/Lang';

//Ui
import { Container } from 'reactstrap';

function getAdditionals(data) {
    let additionals = null;
    if (data !== null) {
        additionals = data.map((item, index) => {
            if (item.type === 'I') {
                return null;
            }
            return (<Fragment key={index}>
                {item.type === 'L' ?
                    <BadgeLabel type="important" color="primary" text={item.value} />
                    : <Typography variant="p">{item.value}</Typography>}
            </Fragment>);
        });
    }
    return additionals;
}

class MyAccountProfile extends Component {
    constructor() {
        super();
        this.state = {
            redirect: false
        }
        this.renderRedirect = this.renderRedirect.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.hasRol = this.hasRol.bind(this);
    };
    

    renderRedirect = (slug) => {
        if (this.state.redirect) {
            this.props.history.push("/" + Lang.lancode + "/profile/" + slug);
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({ redirect: true })
    }

    hasRol(requiredRol, userRol) {
        let containsRol = false;
        for (const rol of requiredRol) {
            if (userRol.indexOf(rol) !== -1) {
                containsRol = true;
                break;
            }
        }
        return containsRol;
    };

    render() {
        // let className = this.state.redirect === true ? 'clickeable' : '';
        return (
            <AuthConsumer>
                {({ user: { name, img_url, custom_data, slug, roles } }) => (
                    <section className="profile-wrapper">
                        <Container>
                            <div className="profile-wrapper-area">
                                <div className="profile-area-left">
                                {this.hasRol(["WKR"], roles) && this.renderRedirect(slug)}
                                    <div className={this.hasRol(["WKR"], roles) ? `item  clickeable` : `item`} onClick={this.handleClick} >
                                        <Avatar url={img_url} name={name} size="xlg" />
                                    </div>
                                    <div className="item">
                                        <Typography variant="h5">
                                            <Typography variant="strong">{name}</Typography>
                                        </Typography>
                                        {getAdditionals(custom_data)}
                                    </div>
                                </div>
                                <div className="suggested-area-right">

                                </div>
                            </div>
                        </Container>
                    </section>
                )
                }
            </AuthConsumer >
        );
    };
};

export default MyAccountProfile;
