import React, { Fragment } from 'react';

//Ui
import Typography from '../../Typography/Typography';

const AreaItems = props => {
    const { data, thumbs } = props;
    return (
        <Fragment>
            <div className={`area-item ${thumbs && 'thumbs'}`}>
                <div className="image-wrapper">
                    <img src={data.image} alt={data.title}/>
                </div>
            </div>
            <div className={`area-item ${thumbs && 'thumbs'}`}>
                <div className="content-wrapper">
                    <Typography variant={thumbs ? 'h6': 'h1'}>{data.title}</Typography>
                    {!thumbs &&
                    <Typography variant="p">
                        <Typography variant="strong">{data.subtitle} </Typography>
                        {data.content}
                    </Typography>}
                </div>
            </div>
        </Fragment>
    );
};

export default AreaItems;