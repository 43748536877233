import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Rating from 'react-rating';
import { AuthConsumer } from '../../config/AuthContext';

class Rank extends Component {
    constructor(){
        super();
        this.handleChange = this.handleChange.bind(this);
    };
    handleChange = (args)=> {
        this.props.handleRatingChange(args);
    };
    render() {
        const {ranking} = this.props
        return (
            <AuthConsumer>
                {({isIE})=> (
                    <div className={`widget-rank ${isIE && 'ie'}`}>
                        <Rating 
                            onChange={this.handleChange}
                            readonly={Boolean(this.props.readonly)}
                            placeholderRating={this.props.placeholder}
                            fractions={2}
                            initialRating={ranking}
                            className="rank"
                            placeholderSymbol={<FontAwesomeIcon icon={faStar} className="filled"/>}
                            emptySymbol={<FontAwesomeIcon icon={faStar} className="empty"/>}
                            fullSymbol={<FontAwesomeIcon icon={faStar} className="filled"/>}
                        />
                    </div>
                )}
            </AuthConsumer>
        );
    };
};

export default Rank;