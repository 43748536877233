import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Typography from '../Typography/Typography';
import Lang from '../../api/Lang';

import XServices from '../../api/XServices';
import { Constants } from '../../config/Constants';

const HelpFooter = props => {
    let country = XServices.country.toUpperCase();
    return (
        <section className="dynamic-footer">
            <Container>
                <Row>
                    <Col xs="12" sm="6">
                        <Typography variant="h1">{Lang.get("HELP.TITLE")}</Typography>
                    </Col>
                    <Col xs="12" sm="6">
                        <Typography variant="p">
                            {Lang.get("HELP.DESCRIPTION1")}<br/>
                        </Typography>
                        <Typography variant="p">
                            {Lang.get("HELP.CONTACT")}<br/>
                        </Typography>
                        <Row className="contact-link">
                            <Col xs="2"><b>{Lang.get("HELP.PHONE")}</b></Col>
                            <Col xs="10"><a href={"tel:" + Constants.COUNTRY[country].PHONE.trim()}>{Constants.COUNTRY.CL.PHONE}</a></Col>
                        </Row>
                        <Row className="contact-link">
                            <Col xs="2"><b>{Lang.get("HELP.WHATSAPP")}</b></Col>
                            <Col xs="10"><a href={"https://wa.me/" + Constants.COUNTRY[country].WHATSAPP}>{Constants.COUNTRY.CL.PHONE}</a></Col>
                        </Row>
                        <Row className="contact-link">
                            <Col xs="2"><b>{Lang.get("HELP.EMAIL")}</b></Col>
                            <Col xs="10"><a href={"mailto:" + Constants.COUNTRY[country].EMAIL}>{Constants.COUNTRY.CL.EMAIL}</a></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default HelpFooter;
