import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCommentDots, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import Typography from '../Typography/Typography';
import InputField from '../InputField/InputField';
import Loading from '../Loading/Loading';

import Lang from '../../api/Lang';

const ChangeField = props => {
    const { showActions, handleChange, handleChangeModification, handleSave, handleClick, action,  data, dataComment, value, valid, message, feedback, handleCancelAntecedent } = props;
    const { id, type, additional, mandatory} = action === 'E' ? data : dataComment;

    let inputId = "field-" + id;
    let isValid = valid !== undefined ? valid : true;

    const labelTitle = action === 'E' ? Lang.get('REQUEST.FIELD.EDITVALUE') : Lang.get('REQUEST.FIELD.REQUESTMODIFICATION');
    const textHelp = action === 'E' ? Lang.get('REQUEST.FIELD.EDITVALUE.DESCRIPTION') : Lang.get('REQUEST.FIELD.REQUESTMODIFICATION.DESCRIPTION');
    const classGroup = action !== null ? "col-md-12" : "col-md-12";
    const classAction = showActions ? "col-md-12" : "col-md-12";

    return (
      (<div className={classGroup}>
        <div className="row">
          {data.type !== 'F' && showActions && labelTitle && <div className="col-md-12">
            <label>{showActions && labelTitle}&nbsp;</label>
          </div>}
          {showActions &&
          <div className="col-md-12">
              <InputField
                inputId={inputId}
                id={id}
                type={type}
                additional={additional}
                mandatory={mandatory}
                handleChangeInput={action === 'E' ? handleChange : handleChangeModification}
                valid={valid}
                value={value}
                message={message}
                feedback={feedback}
                disabled={data.loading === true}
               />
          </div>
          }
          <div className={classAction}>
            <div className="action-control">
              {data.loading === true ?
                <button className="btn btn-sm" disabled={true}><Loading variant="component" /></button> :
              (showActions ?
                <Fragment>
                  <button 
                    className="btn btn-sm btn-action-button btn-action-success"
                    onClick={handleSave} 
                    disabled={!valid}>
                      <span>
                        <Typography variant="small">{Lang.get('ACTION_BUTTON_ACCEPT')}</Typography>
                        <FontAwesomeIcon icon={faCheck}/>
                      </span>
                  </button>
                  <button 
                    className="btn btn-sm btn-action-button btn-action-cancel" 
                    onClick={() => handleClick('H')}>
                      <span>
                        <Typography variant="small">{Lang.get('ACTION_BUTTON_CANCEL')}</Typography>
                        <FontAwesomeIcon icon={faTimes}/>
                      </span>
                  </button>
                </Fragment>
                :
                <Fragment>
                  {data.type !== 'F' && 
                    <button 
                      className="btn btn-sm btn-action-button btn-action-edit" 
                      onClick={() => handleClick('E')}>
                        <span>
                          <Typography variant="small">{data.requirechange !== 'S' ? Lang.get('ACTION_BUTTON_EDIT') : Lang.get('ACTION_BUTTON_RESPONSE_ANTECEDENT')}</Typography>
                          <FontAwesomeIcon icon={faPencilAlt}/>
                        </span>
                    </button>}
                  {data.requirechange !== 'S' &&
                    <Fragment>
                        <button
                            className="btn btn-sm btn-action-button btn-action-comment"
                            onClick={() => handleClick('C')}>
                            <span>
                                <Typography variant="small">{Lang.get('ACTION_BUTTON_COMMENT')}</Typography>
                                <FontAwesomeIcon icon={faCommentDots}/>
                            </span>
                        </button>
                    </Fragment>}
                    {data.type !== 'F' && data.requirechange === 'S' &&
                    <Fragment>
                        <button
                            className="btn btn-sm btn-action-button btn-action-cancel"
                            onClick={() => handleCancelAntecedent()}>
                            <span>
                                <Typography variant="small">{Lang.get('ACTION_BUTTON_CANCEL_ANTECEDENT')}</Typography>
                                <FontAwesomeIcon icon={faTimes}/>
                            </span>
                        </button>
                    </Fragment>
                  }
                </Fragment>)
              }
            </div>
          </div>
          {showActions && <div className="col-md-12">
              <Typography color="muted">{textHelp}</Typography>
            </div>
          }
        </div>
      </div>)
    );
};

export default ChangeField;
