import React, { Component } from 'react';
import XServices from '../../api/XServices';
import Events from '../../api/Events';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
    FormGroup, Input, CustomInput,
    InputGroup, InputGroupAddon, Button
} from 'reactstrap';
import ListRequest from '../../components/ListRequest/ListRequest';
import Lang from '../../api/Lang';
import RankModal from '../../components/RankModal/RankModal';
import ToCloseRequest from '../../components/ToCloseRequest/ToCloseRequest';
import { Modal, ModalBody } from 'reactstrap';
import CustomAlert from '../../components/Alert/CustomAlert';
import Loading from '../../components/Loading/Loading';
import RequestAlert from '../../components/Alert/RequestAlert';

const REFRESH_TIME = 60000;
const IDDECLINED = "IDDECLINED";

class MyAccountAllRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: this.props.status, // valores validos: 'available', 'assigned' y 'requested'
            items: [],
            pageSize: 5,
            currentPage: 1,
            numberOfItems: 0,
            showModal: false,
            toCloseRequest: false,
            error: null,
            modes: null,
            states: null,
            person: null,
            loading: false,
            requesting: false,
            alerts: [],
            counter: 0,
            ajaxTimeRequest: null,
            timeTimeout: null,
            searchObject: { chk_0: false, chk_1: false, statusSelect: ''},
            starting: false
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleToCloseRequest = this.toggleToCloseRequest.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.assingRequest = this.assingRequest.bind(this);
        this.handleToPaid = this.handleToPaid.bind(this);
        this.loadStates = this.loadStates.bind(this);
        this.loadModesPrice = this.loadModesPrice.bind(this);
        this.handleDismissAlert = this.handleDismissAlert.bind(this);
        this.addAlert = this.addAlert.bind(this);
        this.handleToCheck = this.handleToCheck.bind(this);
        this.checkRemainingTime = this.checkRemainingTime.bind(this);
        this.handleToCloseRequest = this.handleToCloseRequest.bind(this);
        this.handleRemoveStoreData = this.handleRemoveStoreData.bind(this);
        this.createListeners = this.createListeners.bind(this);
        this.destroyListeners = this.destroyListeners.bind(this);
        this.refreshList = this.refreshList.bind(this);
    };
    
    componentDidMount() {
        this.loadStates();
        this.createListeners();
    }

    componentWillUnmount() {
        if (this.state.ajaxTimeRequest !== null) {
            this.state.ajaxTimeRequest.cancel();
        }

        if (this.state.timeTimeout !== null) {
            clearTimeout(this.state.timeTimeout);
        }
        this.destroyListeners(); 
    }
    
    refreshList (){
        if (this.state.status === 'available'){
            this.handleSearch(this.state.currentPage,this.state.searchObject);
        }
    }

    destroyListeners(){
        Events.unBindEvent(document.getElementById('root'), 'refreshList', this.refreshList);
    }

    createListeners(){
        Events.bindEvent(document.getElementById('root'), 'refreshList', this.refreshList);
    }

    handleSearch(page, searchObject) {
        let that = this;
        var servicio, state = '';
        if (this.state.status === 'assigned') {
            servicio = 'request/search/assigned';
            if (searchObject.statusSelect === '') {
                state = '';
            } else {
                state = searchObject.statusSelect;
            }
        } else if (this.state.status === 'available') {
            servicio = 'request/search/available';
            state = 'OPN';
        } else {
            servicio = 'request/search/requested';
            if (searchObject.statusSelect === '') {
                state = '';
            } else {
                state = searchObject.statusSelect;
            }
        }
        var modes = []
        if (searchObject.chk_0) { modes.push(this.state.modes[0].id) }
        if (searchObject.chk_1) { modes.push(this.state.modes[1].id) }
        that.setState({ loading: true });
        XServices.post(servicio, {
            page: page,
            state: state,
            priceModes: modes,
            text: this.state.search,
            perPage: that.state.pageSize
        }, function (response) {
            that.setState({
                numberOfItems: response.totalRecords,
                currentPage: response.page,
                items: response.data,
                loading: false,
                starting: false
            });
            that.checkRemainingTime();
        }, function (error) {
            that.setState({
                error: error.message,
                loading: false,
                starting: false
            });
        });
    }

    loadModesPrice() {
        let that = this;
        XServices.get('price/modes', {
        }, function (response) {
            that.setState({
                modes: response
            });
            if (that.state.status === 'assigned'){
                let searchObject = that.state.searchObject;
                searchObject['statusSelect'] = 'PEN';
                that.setState({ searchObject : searchObject });
            }
            that.handleSearch(1, that.state.searchObject);
            that.createListeners();
        }, function (error) {
            that.setState({
                error: error.message,
                loading: false,
                starting: false
            });
        });
    }

    loadStates() {
        let that = this;
        that.setState({starting: true, loading: true });
        var service = '';
        if ((that.state.status === 'assigned') || (that.state.status === 'available')) {
            service = 'request/states/worker';
        } else {
            service = 'request/states/user';
        }
        XServices.get(service, {
        }, function (response) {
            that.setState({
                states: response
            });
            that.loadModesPrice();
        }, function (error) {
            that.setState({
                error: error.message,
                loading: false,
                starting: false
            });
        });
    }

    handleToCloseRequest = id => {
        let that = this;
        that.setState({ requesting: true });
        XServices.post('request/close', {
            requestId: id
        }, function (response) {
            that.addAlert("success", response.message);
            that.setState({ requesting: false }
                , () => {
                    that.handleSearch(that.state.currentPage, that.state.searchObject);
                });
        }, function (error) {
            that.addAlert("danger", error.message);
            that.setState({ requesting: false });
        });
    }

    handleToPaid = id => {
        let that = this;
        that.setState({ requesting: true });
        XServices.post('request/pay', {
            requestId: id
        }, function (response) {
            window.location = response.url;
        }, function (error) {
            that.addAlert("danger", error.message);
            that.setState({ requesting: false });
        });
    }

    handleToCheck = id => {
        let that = this;
        that.setState({ requesting: true });
        XServices.post('request/payment/verify', {
            requestId: id
        }, function (response) {
            if (response.url !== undefined) {
                window.location = response.url;
            } else {
                that.addAlert("success", response.message);
                that.handleSearch(that.state.currentPage, that.state.searchObject);
            }
            that.setState({ requesting: false });
        }, function (error) {
            that.addAlert("danger", error.message);
            that.setState({ requesting: false });
        });
    }

    assingRequest = id => {
        let that = this;
        that.setState({ requesting: true });
        XServices.post('request/assign', {
            requestId: id
        }, function (response) {
            that.addAlert("success", response.message, 'assign', id);
            Events.triggerEvent(document.getElementById('root'), 'notifySticky');
            that.setState({ requesting: false }
                , () => {
                    // that.handleRemoveStoreData(id);
                    that.handleSearch(that.state.currentPage, that.state.searchObject);                    
                });
        }, function (error) {
            that.addAlert("danger", error.message);
            that.setState({ requesting: false });
        });
    }

    handleRemoveStoreData(idNew) {
        let strIdDeclined = localStorage.getItem(IDDECLINED);
        if ((strIdDeclined !== null) && (strIdDeclined === idNew.toString())) {
            localStorage.removeItem(IDDECLINED);
        }
    }

    toggleModal(id, rank) {
        const requestIndex = this.state.items.findIndex(item => {
            return item.requestId === id;
        });

        if (rank === undefined) {
            const person = {
                ...this.state.items[requestIndex]
            };
            this.setState({
                showModal: !this.state.showModal,
                person: person
            });
        } else {
            let items = [...this.state.items];
            items[requestIndex].alreadyRanked = 'S';
            items[requestIndex].rank = rank;

            this.setState({
                showModal: !this.state.showModal,
                items: items
            });
        }
    }

    toggleToCloseRequest(id) {
        if (id !== undefined) {

            const requestIndex = this.state.items.findIndex(item => {
                return item.requestId === id;
            });

            const person = {
                ...this.state.items[requestIndex]
            };

            this.setState({
                toCloseRequest: !this.state.toCloseRequest,
                person: person
            });
        } else {
            this.setState({
                toCloseRequest: !this.state.toCloseRequest,
            });
        }
    }

    onChangePage(currentPage) {
        this.setState({
            currentPage: currentPage
        });
        this.handleSearch(currentPage, this.state.searchObject);
    }

    componentDidUpdate(prevProps,prevState){
        if (prevState.searchObject.statusSelect !== this.state.searchObject.statusSelect){
            this.handleSearch(this.state.currentPage, this.state.searchObject);
        }else if (prevState.searchObject.chk_0 !== this.state.searchObject.chk_0){
            this.handleSearch(this.state.currentPage, this.state.searchObject);
        }else if (prevState.searchObject.chk_1 !== this.state.searchObject.chk_1){
            this.handleSearch(this.state.currentPage, this.state.searchObject);
        }
    }

    handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let searchObject = {};
        for (var item in this.state.searchObject){
            searchObject[item] = this.state.searchObject[item];
        }
        searchObject[name] = value;
        this.setState({
            searchObject: searchObject
        });
    };

    handleKeyPress = e => {
        if (e.which === 13 || e.keyCode === 13) {
            this.handleSearch(1,this.state.searchObject);
        }
    }

    handleClick() {
        this.handleSearch(1,this.state.searchObject);
    }

    handleDismissAlert(id) {
        const alertIndex = this.state.alerts.findIndex(f => {
            return f.id === id;
        });
        let alerts = [...this.state.alerts];
        alerts.splice(alertIndex, 1);
        this.setState({ alerts: alerts });
    }

    addAlert(type, message, service, requestId) {
        let alerts = [...this.state.alerts];
        let counter = this.state.counter + 1;
        alerts.push({
            id: counter,
            type: type,
            message: message,
            service: service,
            requestId: requestId
        });

        this.setState({ alerts: alerts, counter: counter });
    }

    checkRemainingTime() {
        if (this.state.ajaxTimeRequest !== null) {
            this.state.ajaxTimeRequest.cancel();
        }

        if (this.state.timeTimeout !== null) {
            clearTimeout(this.state.timeTimeout);
        }

        let ids = [];
        for (const item of this.state.items) {
            if (item.state === 'OPN' || item.state === 'WAT' || item.state === 'RPN') {
                ids.push(item.requestId);
            }
        }

        let ajaxRequest = null;
        if (ids.length > 0) {
            ajaxRequest = XServices.post('request/search/time/remaining', {
                requestIds: JSON.stringify(ids)
            }, response => {
                let items = [...this.state.items];
                for (let time of response) {
                    const requestIndex = items.findIndex(f => {
                        return f.requestId === time.id;
                    });

                    if (requestIndex !== -1) {
                        items[requestIndex].timeRemaining = time.textRemainingTime;
                        items[requestIndex].minutesRemaining = time.totalRemainingMinutes;
                        items[requestIndex].state = time.state;
                        items[requestIndex].stateName = time.statename;
                        items[requestIndex].timeAdditional = time.totalAdditionalMinutes > 0 ? time.textAdditionalTime : null;
                        items[requestIndex].textWarrantyTime = time.hasWarranty ? time.textWarrantyTime.text : null;
                    }
                }

                const timeout = setTimeout(this.checkRemainingTime, REFRESH_TIME);
                this.setState({ items: items, ajaxTimeRequest: null, timeTimeout: timeout });
            }, error => {
                this.addAlert('danger', error.message);
            });
        } else {
            const timeout = setTimeout(this.checkRemainingTime, REFRESH_TIME);
            this.setState({ timeTimeout: timeout });
        }
        this.setState({ ajaxTimeRequest: ajaxRequest });
    }

    render() {
        const { modes, states, starting, searchObject } = this.state;
        return (
            !starting ?
            <div className="maar-item">
                <div className="maar-search">
                    <div className="maar-checks">
                        <FormGroup check inline>
                            <div className="maar-text">
                                {
                                    modes !== null &&
                                    modes.map((item, index) =>
                                        <CustomInput type="checkbox" name={`chk_` + index} id={`chk_` + item.id} label={item.name} value={`chk_` + index} onClick={this.handleChange} key={index} />
                                    )
                                }
                            </div>
                        </FormGroup>
                    </div>
                    {this.state.status !== 'available' &&
                        <div className="maar-select">
                            <FormGroup>
                                <Input type="select" name="statusSelect" id="statusSelect" value={searchObject.statusSelect} onChange={this.handleChange}>
                                    {
                                        states !== null &&
                                        states.map((item, index) =>
                                            <option value={item.code} key={index} >{item.name}</option>
                                        )
                                    }
                                </Input>
                            </FormGroup>
                        </div>
                    }
                    <div className="maar-input">
                        <FormGroup>
                            <InputGroup>
                                <Input name="search" type="text" onChange={this.handleChange} onKeyPress={this.handleKeyPress} value={searchObject.search} placeholder={Lang.get('ACCOUNTALLREQUEST.SEARCH')} />
                                <InputGroupAddon addonType="prepend">
                                    <Button outline color="secondary" onClick={this.handleClick}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div className="maar-alerts">
                    {this.state.alerts.map(item =>
                        (item.service === 'assign' ? 
                            <RequestAlert variant="alert" type={item.type} message={item.message} id={item.requestId}/>
                            :
                            <CustomAlert key={item.id} id={item.id} type={item.type} message={item.message} onDismiss={this.handleDismissAlert} />
                        )
                    )}
                </div>
                {this.state.error !== null ?
                    <CustomAlert type="danger" message={this.state.error} />
                    :
                    this.state.numberOfItems > 0 ?
                        !this.state.loading ?
                            <ListRequest
                                status={this.state.status}
                                numberOfItems={this.state.numberOfItems}
                                onChangePage={this.onChangePage}
                                items={this.state.items}
                                initialPage={this.state.currentPage}
                                currentPage={this.state.currentPage}
                                pageSize={this.state.pageSize}
                                toggleModal={this.toggleModal}
                                toggleToCloseRequest={this.toggleToCloseRequest}
                                assingRequest={this.assingRequest}
                                alert_success={this.state.alert_success}
                                states={this.state.states}
                                handleToPaid={this.handleToPaid}
                                handleToCloseRequest={this.handleToCloseRequest}
                                requesting={this.state.requesting}
                                handleToCheck={this.handleToCheck}
                            />
                            :
                            <Loading variant="fullscreen" />
                        :
                        <CustomAlert type="info" close="none" message={Lang.get('SEARCH.NORESULTS')} />
                }
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal}>
                    <ModalBody>
                        {this.state.person !== null &&
                            <RankModal
                                url={this.state.person.userImageUrl}
                                name={this.state.person.userName}
                                id={this.state.person.requestId}
                                size="sm"
                                toggle={this.toggleModal}
                            />
                        }
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.toCloseRequest} toggle={this.toggleToCloseRequest} className="modal-request-info modal-notify-request">
                    {this.state.person !== null &&
                        <ToCloseRequest
                            person={this.state.person}
                            toggle={this.toggleToCloseRequest}
                        />
                    }
                </Modal>
            </div>
            :
            <Loading variant="fullscreen" />
        );
    };
};

export default MyAccountAllRequest;
