import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Lang from '../../api/Lang';

//ui
import Avatar  from './Avatar';

class AvatarList extends Component {
    constructor(){
        super();
        this.state = {
            tooltipOpen: false
        };
        this.handleClick = this.handleClick.bind(this);
    };
    handleClick = (arg)=> {
        // window.location = "/" + Lang.lancode + "/request/" + arg;
        this.props.history.push(`/${Lang.lancode}/request/${arg}`);
    };

    render(){
        const { data } = this.props;
        return(
            <div className="avatar-list">
                {data.map((item, i) => (
                    <div key={i} id={`tooltip-${i}`}  className="avatar-list-item" onClick={()=> this.handleClick(item.id)}>
                        <Avatar size="md" name={item.username} url={item.img_url}/>
                        <UncontrolledTooltip placement="bottom" target={`tooltip-${i}`}>
                            <p>{item.name}</p>
                        </UncontrolledTooltip>
                    </div>
                ))}
            </div>
        );
    };
};
export default  withRouter(AvatarList);
