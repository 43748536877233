import React from 'react';
import {Row, FormGroup, Label} from 'reactstrap';

//Ui
import Typography from '../Typography/Typography';
import NewRequestField from './NewRequestField';
import NewRequestFieldFile from './NewRequestFieldFile';
import Notaries from '../Notaries/Notaries';
import Lang from '../../api/Lang';
import HelpTooltip from '../HelpTooltip/HelpTooltip';
import InputField from '../InputField/InputField';

const NewRequestBody = props => {
    const {data, handleChangeInput, inProgress, handleRemoveFile, isAuth, handleSendIdNotary, moduleNotary, setNotaryValid, notariesFields, setStateNotary, handleNotary} = props;

    const NOTARY_OPTIONS = {
      selection: "single",
      validations: ["required"],
      options: [
        {key: Lang.get('REQUEST.NOTARY.SELECT.YES'), value: "S"},
        {key: Lang.get('REQUEST.NOTARY.SELECT.NO'), value: "N"}
      ],
      default: data.requirenotary ===  false ? "N" : (moduleNotary === true ? "S" : "N")
    };

	let noteMessage = 'REQUEST.NOTE.' + data.note;
    let containsFile = false;
    let containsInput = false;

    let fieldFile = data.fields.map((item, i) => {
      if (item.type === 'F') {
        containsFile = true;
      }
      return (item.type === 'F' && <NewRequestFieldFile
        key={item.id}
        id={item.id}
        title={item.title}
        type={item.type}
        tooltip={item.tooltip}
        additional={item.additional}
        handleChangeInput={handleChangeInput}
        value={item.value}
        valid={item.valid}
        message={item.message}
        handleRemoveFile={handleRemoveFile}
        inProgress={inProgress}/>);
    });

    let fieldInputs = data.fields.map((item, i) => {
      let isDependant = false;
      if (item.dependency !== undefined) {
        isDependant = data.fields[item.dependency.index].value !== item.dependency.value;
      }
      if (item.type !== 'F') {
        containsInput = true;
      }
      return (item.type !== 'F' && !isDependant && <NewRequestField
        key={item.id}
        id={item.id}
        title={item.title}
        type={item.type}
        tooltip={item.tooltip}
        additional={item.additional}
        mandatory={item.mandatory}
        handleChangeInput={handleChangeInput}
        value={item.value}
        valid={item.valid}
        message={item.message}
        inProgress={inProgress}/>);
    });
    return (
      <div className="modal-request-body">
        <Typography variant="p">{data.description}</Typography>
        {containsFile &&
          <Typography variant="p">
            <Typography variant="strong">{Lang.get('REQUEST.NOTE')}:&nbsp;</Typography>
            {Lang.get('REQUEST.NOTE.FILE')}
          </Typography>}
        <Row>
        {fieldFile}
        </Row>
        {containsFile && !isAuth &&
          <Typography variant="p">
            <Typography variant="strong">{Lang.get('REQUEST.NOTE')}:&nbsp;</Typography>
            {Lang.get('REQUEST.NOTE.FILE.REQUIRELOGIN')}
          </Typography>}
        {containsInput &&
          <Typography variant="p">
          <Typography variant="strong"><span className="background-note">{Lang.get('REQUEST.NOTE')}:&nbsp;{Lang.get(noteMessage)}</span></Typography>
          </Typography>}
        {fieldInputs}
        {/* <FormGroup>
          <Label for='selectNotary'>{Lang.get('REQUEST.NOTARY.SELECT')}&nbsp;<Typography color="danger">&nbsp;*</Typography></Label>
          <div className="btn-tooltip action-control">
              <HelpTooltip id="10" title={Lang.get('REQUEST.NOTARY.SELECT.TOOLTIP.TITLE')} description={Lang.get('REQUEST.NOTARY.SELECT.TOOLTIP.DESCRIPTION')}/>
          </div>
          <InputField
              inputId='selectNotary'
              id='selectNotary'
              type='L'
              additional={NOTARY_OPTIONS}
              mandatory={true}
              handleChangeInput={handleNotary}
              feedback={true}
              valid={true}
              message={""}
              noEmpty={false}
          />
          {data.requirenotary ===  false && <div className="mt-2 grey"><Typography variant="p">{Lang.get('REQUEST.NOTARY.SELECT.NONEEDED')}</Typography></div>}
      </FormGroup>
        {moduleNotary && <Notaries handleSendIdNotary={handleSendIdNotary} handleChangeInput={handleChangeInput} notariesFields={notariesFields} setStateNotary={setStateNotary} />} */}
      </div>
    );
};

export default NewRequestBody;
