import React, { Component, Fragment } from 'react';
import { AuthContext } from '../../config/AuthContext';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import XServices from '../../api/XServices';
import SearchItem from './SearchItem';
import Loading from '../Loading/Loading';
import Lang from '../../api/Lang';
import { isHome } from '../../api/isRoute';
//Ui
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Constants } from '../../config/Constants';


class SearchHero extends Component {
    constructor(){
        super();

        this.state = {
            search: '',
            result: false,
            data: [],
            loading: false,
            error: null,
            ajaxRequest: null,
            firstMount: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleViewAll = this.handleViewAll.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.myRefSearch = React.createRef();
        this.myFocus = React.createRef();
    }

    componentDidMount() {
        this.setState({search: this.context.search.text, data: this.context.search.data});
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        this.context.search.text = this.state.search;
        if(prevState.search !== this.state.search && !this.state.firstMount){
            this.setState({firstMount: false});
            if(this.state.search.length >= Constants.MIN_SEARCH_LENGTH){
                this.setState({loading: true});

                if (this.state.ajaxRequest !== null) {
                    this.state.ajaxRequest.cancel();
                }

                let ajaxRequest = XServices.get('/task/search', {text: this.state.search} , response => {
                    this.context.search.data = response;
                    this.setState({
                        loading: false,
                        data: response,
                        result: true,
                        ajaxRequest: null
                  });
                }, error => {
                  this.setState({
                      loading: false,
                      error: error,
                      result: false
                  });
                });

                this.setState({ajaxRequest: ajaxRequest});
            } else {
              if (this.state.ajaxRequest !== null) {
                  this.state.ajaxRequest.cancel();
                  this.setState({ajaxRequest: null});
              }
              this.context.search.data = [];
              this.setState({result: true, data: []});
            }
        }

        if(this.context.toggleSearchResponsive){
            document.addEventListener("mousedown", this.handleClickOutside);
            this.myFocus.current.focus();
        } else{
            document.removeEventListener("mousedown", this.handleClickOutside);
        }
    }

    componentWillUnmount(){
        document.removeEventListener("mousedown", this.handleClickOutside);
        if (this.state.ajaxRequest !== null) {
            this.state.ajaxRequest.cancel();
        }
    }

    handleClickOutside = (e)=> {
        if(e.target.name === this.myRefSearch.current.props.name){
            return;
        }
        this.context.dispatch({action: 'toggle-search'});
    }

    handleChange = (e)=> {
        this.setState({
            [e.target.name]: e.target.value,
        });
        this.setState({ result: true });
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    handleClose = ()=> {
        this.setState({ result: false, search: '', firstMount: false})
    }

    handleBlur = () => {
      let that = this;
      setTimeout(function () {
        that.setState({ result: false, firstMount: false });
      }, 200);
    }

    handleFocus = () => {
      if (!this.state.firstMount) {
        this.setState({ result: true });
      }
    }

    handleClickDiv = () => {
       document.getElementById("searchInput").focus();
    }

    handleClick  = () => {  
        this.setState({result: true, firstMount: false });
    }

    handleViewAll = () => {
        if (!this.props.compact) {
            this.props.history.push(`/${Lang.lancode}/contract`);
        } else {
            this.handleClickDiv();
        }
    }

    render() {
        const { search, result, data, loading} = this.state;
        return (
            <div
                onClick={this.handleClickSearch}
                className={classNames('hero-search',
                isHome(this.props.match.url) ? 'hero-search-no-home' : 'hero-search-home',
                isHome(this.props.match.url) ? this.props.toggle  ? 'active-search': 'inactive-search': 'null',
                this.props.dark && 'dark', 
                this.props.fixed && 'hero-search-fixed',
                this.props.compact ? 'compact-mode' : 'hero-mode')}>
                <div className="hero-form-wrapper">
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup onClick={this.handleClickDiv}>
                            <Input
                                autoFocus={true}
                                ref={(this.myRefSearch)}
                                innerRef={this.myFocus}
                                autoComplete="off"
                                type="text"
                                name="search"
                                onClick={this.handleClick}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onFocus={this.handleFocus}
                                placeholder={Lang.get("SEARCH.PLACEHOLDER")}
                                maxLength="255"
                                id="searchInput"
                                value={search}/>
                                {result &&
                                    <SearchItem loading={loading} data={data} dispatch={this.props.request} text={search}/>}
                                {loading &&
                                    <div className="loading-icon">
                                        <Loading variant="component"/>
                                    </div>}
                        </FormGroup>
                        <Button color={!this.props.dark ? 'danger': 'dark'} onClick={this.handleViewAll}>
                            {!this.props.compact ? <Fragment>
                            <span>{Lang.get("SEARCH.VIEWALL")}</span>
                            <FontAwesomeIcon icon={faArrowRight} className="button-position"/>
                            </Fragment> :
                            <FontAwesomeIcon icon={faSearch} />}
                        </Button>
                    </Form>
                </div>
                {!this.props.compact &&
                    <div className="hero-help">
                        <NavLink to={`/${Lang.lancode}/contract`} className="btn btn-link">{Lang.get("SEARCH.HELP")}</NavLink>
                </div>}
            </div>
        );
    }
};
SearchHero.contextType = AuthContext;

export default withRouter(SearchHero);
