import React, { Component } from 'react';
import XServices from '../api/XServices';
import Cookie from '../api/Cookies';
import Lang from "../api/Lang";

export const AuthContext = React.createContext();

class AuthProvider extends Component {
    constructor() {
        super();
        this.state = {
            isAuth: false,
            loading: true,
            user: null,
            id: null,
            modal: false,
            isLoginModal: false,
            ctx: '',
            toggleSearchResponsive: false,
            search: {
                text: '',
                data: []
            }
        };
        this.handleLogout = this.handleLogout.bind(this);
        this.handleLoginIn = this.handleLoginIn.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.dispatch = this.dispatch.bind(this);
        this.handleCleanSearch = this.handleCleanSearch.bind(this);
    };

    componentDidMount(){
      this.handleFetch();
    };

    componentDidUpdate(prevProps, prevState){
        if(this.state.isAuth){
            if(this.state.user === null){
                this.handleFetch();
            };
        };
    };

    handleFetch = ()=> {
        const token = Cookie.get('token');
        if (token !== undefined && token !== null && token !== '')  {
          XServices.get('/account/valid', { token }, response => {
              this.setState({ isAuth: true, user: response, loading: false });
          }, err => {
                Cookie.remove('token');
                this.setState({ loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
    };

    handleLoginIn = (arg) => {
        Cookie.set('token', arg.token);
        this.setState({ isAuth: true, user: arg });
    };
    handleLogout = ()=> {
        const token = Cookie.get('token');
        if (token !== undefined && token !== null && token !== '')  {
          XServices.post('/account/logout', {});
        }
        this.handleClear();
    };
    handleClear = () => {
      localStorage.clear();
      Cookie.remove('token');
      this.setState({ isAuth: false, user: null});
    }

    dispatch = (args)=> {
        if(args.action === 'toggle-search'){
            this.setState({
                toggleSearchResponsive: !this.state.toggleSearchResponsive
            });
        };
        if(args.action === 'login'){
            this.setState({
                isLoginModal: !this.state.isLoginModal,
                ctx: args.ctx
            });
        };
        if(args.action === 'close-login'){
            this.setState({
                isLoginModal: false
            });
        };
        if(args.action === 'close-register'){
            this.setState({
                isLoginModal: false
            });
            window.location = `/${Lang.lancode}/welcome`;
        };
        if(args.action === 'add'){
            this.setState({
                modal: !this.state.modal,
                id: args.id
            });
        }
        if(args.action === 'close'){
            this.setState({
                modal: false,
                id: null
            });
        }
    };

    handleCleanSearch = () => {
        this.setState({
            search: {
                text: '',
                data: []
            }
        });
    }
    
    render() {
        const { isAuth, loading, user, id, modal, isLoginModal, ctx, toggleSearchResponsive, search } = this.state;
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        const isEdge = !isIE && !!window.StyleMedia;
        return (
            <AuthContext.Provider value={{loading, isAuth, user, id, modal, isLoginModal, ctx,
                isIE, isEdge, toggleSearchResponsive, search,
                refresh: this.handleFetch,
                dispatch: this.dispatch,
                logout: this.handleLogout,
                isLogin: this.handleLoginIn,
                clear: this.handleClear,
                cleanSearch: this.handleCleanSearch
                }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthProvider;
export const AuthConsumer = AuthContext.Consumer;
