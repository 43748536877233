import React, { Component } from 'react';
import XServices from '../../api/XServices';

import ListNotifications from './ListNotifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
    FormGroup, Input, Alert, Row, Col,
    InputGroup, InputGroupAddon, Button
} from 'reactstrap';
// import { Label, CustomInput } from 'reactstrap';
import Typography from '../../components/Typography/Typography';
import Lang from '../../api/Lang';
import Loading from '../../components/Loading/Loading';
import CustomAlert from '../../components/Alert/CustomAlert';

class MyAccountAllRequest extends Component {
    constructor() {
        super();
        this.state = {
            search: '',
            // chk_event: false,
            // chk_activity: false,
            items: [],
            pageOfItems: [],
            pageSize: 5,
            currentPage: 1,
            numberOfItems: 0,
            loading: false,
            error: null
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.markNotificationAsView = this.markNotificationAsView.bind(this);
    };

    componentDidMount() {
        this.handleSearch(1);
    }

    handleSearch(page) {
        let that = this;
        that.setState({ loading: true });
        XServices.post('account/notification', {
            page: page,
            text: that.state.search,
            perPage: that.state.pageSize
        }, function (response) {
            that.setState({
                numberOfItems: response.totalRecords,
                currentPage: response.page,
                items: response.data,
                loading: false
            });
        }, function (error) {
            that.setState({
                error: error.message,
                loading: false
            });
        });
    }

    onChangePage(currentPage) {
        this.setState({
            currentPage: currentPage
        });
        this.handleSearch(currentPage);
    }

    handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    };

    handleKeyPress = e => {
        if (e.which === 13 || e.keyCode === 13) {
            this.handleSearch(1);
        }
    }

    handleClick() {
        this.handleSearch(1);
    }

    markNotificationAsView() {
        var that = this;
        var listIdNotificatios = []
        that.state.items && that.state.items.map((item, i) => (
          item.requiresAttention === 'S' && listIdNotificatios.push(item.id)
        ))
        if (listIdNotificatios.length > 0) {
          XServices.post('account/notification/view', {
            requestId: that.props.id,
            listId: listIdNotificatios
          }, function (response) {
            let thatsNotifications = [...that.state.items];
            let idx = 0;
            for (let notify of thatsNotifications) {
              for (let notifyNew of listIdNotificatios) {
                if (notify.id === notifyNew) {
                  notify.requiresAttention = response.requiresAttention;
                  thatsNotifications[idx] = notify;
                }
              }
              idx++;
            }
            that.setState({ items: thatsNotifications });
          }, function (error) {
          });
        }
      }

    render() {
        const { search } = this.state;
        // const { chk_event, chk_activity } = this.state;
        this.markNotificationAsView();
        return (
            <div className="maar-item search-without-params">
                <div className="maar-search">
                    {/* <FormGroup check inline>
                        <div className="maar-text">
                            <CustomInput type="checkbox" name='chk_event' id="chk_event" label={Lang.get('ACCOUNTALLNOTIFICATIONS.CHECK.EVENT')} value={chk_event} onClick={this.handleChange} />
                            <CustomInput type="checkbox" name='chk_activity' id="chk_activity" label={Lang.get('ACCOUNTALLNOTIFICATIONS.CHECK.ACTIVITY')} value={chk_activity} onClick={this.handleChange} />
                        </div>
                    </FormGroup> */}
                    <div className="maar-input">
                        <FormGroup>
                            <InputGroup>
                                <Input name="search" type="text" onChange={this.handleChange} onKeyPress={this.handleKeyPress} value={search} placeholder={Lang.get('ACCOUNTALLREQUEST.SEARCH')} />
                                <InputGroupAddon addonType="prepend">
                                    <Button outline color="secondary" onClick={this.handleClick}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
                <div className="maar-list">
                    {this.state.error !== null ?
                        <CustomAlert type="danger" message={this.state.error} />
                        :
                        this.state.numberOfItems > 0 ?
                            !this.state.loading ?
                                <ListNotifications
                                    numberOfItems={this.state.numberOfItems}
                                    onChangePage={this.onChangePage}
                                    items={this.state.items}
                                    initialPage={this.state.currentPage}
                                    currentPage={this.state.currentPage}
                                    pageSize={this.state.pageSize}
                                />
                                :
                                <Loading variant="fullscreen" />
                            :
                            <Alert color="primary">
                                <Row>
                                    <Col xs={{ size: 1, offset: 1 }}>
                                        <Typography variant="h1"><FontAwesomeIcon icon={faInfoCircle} /></Typography>
                                    </Col>
                                    <Col xs={{ size: 10 }}>
                                        <Typography variant="h1">Info</Typography>
                                        <Typography variant="p">{Lang.get('SEARCH.NORESULTS')}</Typography>
                                    </Col>
                                </Row>
                            </Alert>
                    }
                </div>
            </div>
        );
    };
};

export default MyAccountAllRequest;