import React from 'react';

import Lang from '../../api/Lang';
import CustomError from '../Error/CustomError';

const ContentNotFound = () => {
    return (
        <CustomError code={404} message={Lang.get("PAGE.NOTFOUND")} />
    );
};

export default ContentNotFound;