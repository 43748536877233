import React from 'react';
import Typography from '../Typography/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const StepperIcons = props => {
    const { value, message, status } = props;
    return (
        <div className={`stepper-block ${status ? 'active': 'pending'}`}>
            <div className="stepper-number">
                <Typography variant="h2" color="inherit">
                    {value}
                </Typography>
            </div>
            <div className="stepper-text">
                <Typography variant="small" color="muted">
                    {message}
                </Typography>
            </div>
            <div className="stepper-circle">
                <FontAwesomeIcon icon={faCheck}/>
            </div>
        </div>
    );
};
export default StepperIcons;
