import React from 'react';
import Lang from '../../api/Lang';

//Ui
import Typography from '../Typography/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const SloganItems = props => {
    return(
        <div className="item-foot-wrap">
            <div className="item-foot">
                <FontAwesomeIcon icon={faCheck}/>
                <Typography variant="h6">
                    {Lang.get('HOME.FOOT.1')}
                </Typography>
            </div>
            <div className="item-foot">
                <FontAwesomeIcon icon={faCheck}/>
                <Typography variant="h6">
                    {Lang.get('HOME.FOOT.2')}
                </Typography>
            </div>
            <div className="item-foot">
                <FontAwesomeIcon icon={faCheck}/>
                <Typography variant="h6">
                    {Lang.get('HOME.FOOT.3')}
                </Typography>
            </div>
        </div>
    );
};

export default SloganItems;
