import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import XServices from "../../api/XServices";

//Components
import Avatar from '../Avatar/Avatar';
import AvatarList from '../Avatar/AvatarList';
import Typography from '../Typography/Typography';
import BadgeLabel from '../BadgeLabel/BadgeLabel';
import Lang from '../../api/Lang';
import Stepper from '../Stepper/Stepper';

//Ui
import { Container } from 'reactstrap';

class UserInfo extends Component {

  constructor(){
      super();

      this.state = {
        requests: []
      };

      this.handleRedirect = this.handleRedirect.bind(this);
      this.loadRequests = this.loadRequests.bind(this);
  };

  handleRedirect() {
    if (this.props.redirect === true)  {
      this.props.history.push("/" + Lang.lancode + "/profile/" + this.props.data.slug);
    }
  }

  componentDidMount() {
    if (this.props.viewrole === 'WKR') {
        this.loadRequests();
    }
  }

  loadRequests() {
    XServices.get(
      "request/important",
      {
        requestId: this.props.id
      },
      response => {
        this.setState({requests: response});
      },
      error => {
        this.setState({error: error.message});
      }
    );
  }

  render() {
      const { data, viewrole, redirect } = this.props;
      const { requests } = this.state;
      let additionals = null;
      let linkedIn = null;

      if (data !== null) {
        additionals = data.custom_data.map((item, index) => {
          if (item.type === 'I') {
            linkedIn = item.value;
            return null;
          }
          return (<Fragment key={index}>
            {item.type === 'L' ?
            <BadgeLabel type="important" color="primary" text={item.value}/>
            : <Typography variant="p">{item.value}</Typography>}
          </Fragment>);
        });
      }
      let clickeable = redirect === true ? 'clickeable' : '';

      return (
          <section className="user-info">
              {data ? <Container>
                  <div className="user-info-left">
                      <div className={`${clickeable} user-info-avatar`} onClick={this.handleRedirect}>
                          <Avatar size="xlg" name={data.username} url={data.img_url}/>
                      </div>
                      <div className="user-info-content">
                          <Typography variant="strong">
                              {data.name}
                          </Typography>
                          {additionals}
                          {viewrole !== 'WKR' ?
                          <div className="user-area-button">
                              {linkedIn && <a className="btn btn-square btn-linkedin" href={linkedIn} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin}/></a>}
                              <a className="btn btn-square btn-danger" href="http://www.pjud.cl/busqueda-de-abogados" target="_blank" rel="noopener noreferrer">PJ</a>
                          </div>: <AvatarList data={requests}/>
                          }
                      </div>
                  </div>
                  {this.props.stage && 
                    <div className="user-info-right">
                        <Stepper stage={this.props.stage} title="Progreso de tu solicitud" size="medium"/>
                    </div>
                  }
              </Container> : <Container className="align-items-center">
                  <Typography variant="h1">{Lang.get("REQUEST_NOUSER_ASSIGN")}</Typography>
            </Container>}
          </section>
      );
  }
}

export default withRouter(UserInfo);
