import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import logorevu from '../../resources/images/logorevu.png';

//Components
import Avatar from '../../components/Avatar/Avatar';
import Typography from '../../components/Typography/Typography';

const Logo = props => {
    const { variant, size } = props;
    return (
        <div className={`logo-brand ${props.variant} ${props.size}`}>
            {variant === 'icon' ? <Avatar url={logorevu} name="revu" size={size}/> :
            <Fragment>
                <Avatar url={logorevu} name="revu" size={size}/>
                <Typography variant="p">revu</Typography>
            </Fragment>}
       </div>
    );
};

Logo.propTypes = {
    variant: PropTypes.oneOf(["icon", "full", "medium"])
};
export default Logo;