import React from 'react';

//Components
import Logo from '../Logo/Logo';
import Typography from '../Typography/Typography';
import Lang from '../../api/Lang';
import FooterMenuItem from './FooterMenuItem';
import XServices from '../../api/XServices';
import { AuthConsumer } from '../../config/AuthContext';
//Ui
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { Constants } from '../../config/Constants';

const Footer = () => {
    return (
        <AuthConsumer>
            {({isAuth})=> (
                <footer>
                    <Container className="footer-wrapper-menu">
                        <Row>
                            <Col xs="12" sm="3">
                                <Logo variant="full" size="md"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="12" md="6">
                                <Nav vertical className="footer-nav-geo">
                                    <NavItem>
                                        <NavLink className="nav-no-link">
                                            <FontAwesomeIcon icon={faLanguage}/>
                                            <span>{Lang.get('FOOTER.LANGUAGE.' + Lang.lancode.toUpperCase())}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className="nav-no-link">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                            <span>{Lang.get('FOOTER.COUNTRY.' + XServices.country.toUpperCase())}</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            {Constants.FOOTER.MENU.map((item, i) => (
                                <Col xs="12" sm="12" md="3" key={'m'+i}>
                                    <Typography variant="h5">{Lang.get(item.title)}</Typography>
                                    <Nav vertical>
                                        {item.submenu.map((subitem, i) => (
                                            subitem.link ? 
                                            <FooterMenuItem key={i}
                                                type={subitem.type}
                                                link={subitem.link}
                                                text={subitem.text}
                                                icon={subitem.icon}
                                            /> : !isAuth && <FooterMenuItem key={i} text={subitem.text} type={subitem.type}/>
                                        ))}
                                    </Nav>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col xs="12" sm="12" md="6">
                                <Typography variant="p" asHTML={true}>{Constants.FOOTER.COPYRIGHT}</Typography>
                            </Col>
                            <Col xs="12" sm="12" md="6">
                                <Nav className="footer-inline-nav">
                                    {Constants.FOOTER.INLINE_MENU.map((item, i) => (
                                        <FooterMenuItem key={i}
                                            type={item.type}
                                            link={item.link}
                                            text={item.text}
                                            icon={item.icon}
                                        />
                                    ))
                                    }
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            )}
        </AuthConsumer>
        
    );
};

export default Footer;
