import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { AuthConsumer } from '../../config/AuthContext';
import { Nav, NavItem, NavLink, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import Lang from '../../api/Lang';

const SideBarMenu = [
    {
      title: 'SIDEMENU.REQUEST',
      menus: [
        {
          title: "SIDEMENU.REQUESTED",
          url: "/requested",
          rol: ["RQT"]
        },
        {
          title: "SIDEMENU.AVAILABLE",
          url: "/availables",
          rol: ["WKR"]
        },
        {
          title: "SIDEMENU.ASSIGN",
          url: "/assigned",
          rol: ["WKR"]
        },
      ]
    },
    {
      title: 'SIDEMENU.SETTING',
      menus: [
        {
          title: "SIDEMENU.MYACCOUNT",
          url: "/myaccount"
        },
        {
          title: "SIDEMENU.NOTIFICACION",
          url: "/notifications"
        },
        {
          title: "SIDEMENU.LOGOUT",
          islogout: true
        }
      ]
    }
];

function hasRol (requiredRol, userRol) {
  let containsRol = false;
  for (const rol of requiredRol) {
    if (userRol.indexOf(rol) !== -1) {
      containsRol = true;
      break;
    }
  }
  return containsRol;
}

function getMenu (logout, user, match, position) {
  let menu = [];
  let lang = match.params.lang;
  let counter = 0;
  for (const item of SideBarMenu) {
      if(position !== 'NAV'){
        menu.push((
          <div key={counter++} className="nav-stacked-title">
            {Lang.get(item.title)}</div>
        ));
      }
      const subitems = item.menus.map((submenu,i) =>{
        if (submenu.islogout !== undefined && submenu.islogout) {
          return (
            position === 'NAV' ? 
            <DropdownItem key={i} onClick={logout}>
              {Lang.get(submenu.title)}
            </DropdownItem>: 
            <NavItem key={i}>
              <NavLink onClick={logout}>
                {Lang.get(submenu.title)}
              </NavLink>
            </NavItem>
            );
        } else {
          return (submenu.rol === undefined || hasRol(submenu.rol, user.roles)) && (
            position === 'NAV' ? 
            <DropdownItem key={i} tag={RRNavLink} exact to={"/" + lang + submenu.url}>
              {Lang.get(submenu.title)}
            </DropdownItem>:
            <NavItem key={i}>
              <NavLink tag={RRNavLink} exact to={"/" + lang + submenu.url}>
              {Lang.get(submenu.title)}
              </NavLink>
            </NavItem>
            );
        }
      });
      menu.push((<Nav key={counter++} vertical>{subitems}</Nav>));
  }
  return menu;
}

const MyAccountAside = props => {
    const { match, position } = props;
    return (
        <AuthConsumer>
            {({ logout, user })=> (
                position === 'NAV' ? 
                <DropdownMenu right>
                  {getMenu(logout, user, match, position)}
                </DropdownMenu>:
                <div className="nav-wrapper-stacked">
                    {getMenu(logout, user, match, position)}
                </div>
            )}
        </AuthConsumer>
    );
};

export default withRouter(MyAccountAside);
