import React, {Component, Fragment} from 'react';
import { Popper } from 'react-popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faTimes} from '@fortawesome/free-solid-svg-icons';

import Lang from '../../api/Lang';
import { Constants } from '../../config/Constants';

//Ui
import Typography from '../Typography/Typography';
import Logo from '../Logo/Logo';
import Rank from '../Rank/Rank';

class HelpTooltip extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    let previous = this.state.tooltipOpen;
    this.setState({
      tooltipOpen: !previous
    });
  }

  render() {
    const {id, title, description, url} = this.props;
    let toolTipId = "tooltip-" + id;

    return (
      <Fragment>
        {description !== "" && description !== null &&
        <div id={toolTipId} ref={(div) => this.target = div}>
          <button
            className="btn btn-action"
            onClick={this.toggle}>
            <FontAwesomeIcon icon={faQuestionCircle}/></button>
        </div>
        }
          {this.state.tooltipOpen && (
          <Popper className="popper input-tooltip" target={this.target}>
            <div className="tooltip-wrapper">
              <button
                className="btn btn-action tooltip-close"
                onClick={this.toggle}>
                <FontAwesomeIcon icon={faTimes}/></button>
              <div className="tooltip-logo">
                <Logo variant="icon" size="md"/>
              </div>
              <div className="tooltip-content">
                <Typography variant="h4">{title}</Typography>
                <Typography variant="small">{description}</Typography>
                {Constants.HELP_TOOLTIP.RANK && 
                <Fragment>
                  <Typography variant="strong">{Lang.get('HELPTOOLTIP.WORKS')}</Typography>
                  <Rank />
                </Fragment>
                }
              </div>
              {url &&
                <div className="tooltip-footer">
                  <a className="text-muted" href={url}>{Lang.get('HELPTOOLTIP.MOREINFO')}</a>
                  </div>
              }
            </div>
          </Popper>)}
      </Fragment>
    );
  }
};

export default HelpTooltip;
