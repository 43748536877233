import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Typography from "../Typography/Typography";
import Lang from "../../api/Lang";

const Alert = props => {
  const { id, name, date, requiere, waitingForClient } = props;
  return (
    <div className={`alert ${requiere && !waitingForClient  ? "alert-danger" : "alert-info"}`}>
      <div className="alert-header">
        <div className="alert-header-id">
          <Typography variant="p">#{id}</Typography>
        </div>
        <div
          className={requiere  && !waitingForClient ? "alert-header-mix-danger" : "alert-header-mix"}
        >
          <Typography variant="p">
            {waitingForClient
              ? Lang.get("REQUESTSINPROGRESS.WAIT.CLIENT")
              : Lang.get("REQUESTSINPROGRESS.ATENTION")}
          </Typography>
          <FontAwesomeIcon icon={faCircle} />
        </div>
      </div>
      <div className="alert-content">
        <div>
          <Typography variant="small">{name}</Typography>
        </div>
        <div>
          <Typography variant="p">
            <Typography variant="strong">{date}</Typography>
          </Typography>
        </div>
      </div>
    </div>
  );
};
Alert.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};
export default Alert;
