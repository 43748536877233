import React, { Fragment } from 'react';

//Components 
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import { Container, Row, Col } from 'reactstrap';

const LayoutPrivate = props => {
    return (
        <Fragment>
            <Header variant="private"/>
                {props.heading ? props.heading: null}
                {props.aside ? 
                    <section className="section-wrapper">
                        <Container>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="3">
                                    {props.aside}
                                </Col>
                                <Col xs="12" sm="12" md="12" lg="9">
                                    {props.children}
                                </Col>
                            </Row>
                        </Container>
                    </section> : props.children }
                {props.child}
            <Footer />
        </Fragment>
    );
};

export default LayoutPrivate;