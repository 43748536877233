import React, { Component } from 'react';

import Typography from '../Typography/Typography';
import Avatar from '../Avatar/Avatar';
import PropTypes from 'prop-types';

class CommentsReadItem extends Component {
    render() {
        const { url, username, time_ago, content, owner, sameBefore } = this.props;
        const mine = owner === 'mine' && sameBefore === true;
        return (
            <div className="comment-item">
                {owner === 'other' && sameBefore === false &&
                <div className='comment-avatar'>
                    <Avatar name={username} size="md" url={url} variant="primary" />
                </div>
                }
                <div className={sameBefore ? mine ? 'comment-content-right comment-content' : 'comment-content-left comment-content' :  'comment-content' }>
                    <div className="comment-text">
                        <Typography variant="p">{content}</Typography>
                        <div className="comment-text-footer">
                            <Typography variant="small">{time_ago}</Typography>
                        </div>
                    </div>
                </div>
                {owner === 'mine' && sameBefore === false &&
                <div className='comment-avatar'>
                    <Avatar name={username} size="md" url={url} variant="primary" />
                </div>
                }
            </div>
        );
    }
};

CommentsReadItem.propTypes = {
    url: PropTypes.string,
    username: PropTypes.string.isRequired,
    time_ago: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired
}

export default CommentsReadItem;
