import React from 'react';

//Components
import Typography from '../Typography/Typography';

const NewRequestPaymentItem = props => {
    const { data, choice, active } = props;
    let className = 'button-selector';
    if (active === data.id) {
      className += ' active';
    }
    return (
      <button className={className}
          onClick={()=> !props.disabled && choice(data.id)} disabled={props.disabled}>
          <Typography variant="h2">
              {data.settings.time}
          </Typography>
          <Typography variant="h3">
              {data.price}
          </Typography>
          <Typography variant="p">
              <Typography variant="strong">
                  {data.name}
              </Typography>
          </Typography>
      </button>
    );
};

export default NewRequestPaymentItem;
