import React, { Component, Fragment } from 'react';
import Validate from '../../api/Validate';
import XServices from '../../api/XServices';

import Typography from '../../components/Typography/Typography';
import InputField from '../../components/InputField/InputField';
import { Button, FormGroup, Label } from 'reactstrap';
import AccountMessages from './AccountMessages';
import { Alert } from 'reactstrap';
import Lang from '../../api/Lang';

class AccountUpdatePassword extends Component {
    constructor() {
        super();
        this.state = {
            issubmited: false,
            requesting: false,
            error: '',
            fields: [
                {
                    id: 'new-password',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                        "required",
                    {
                        "name": "password",
                        "params": [8,16]
                    }]
                },
                {
                    id: 'new-password-confirm',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [{
                        "name": "equal",
                        "params": [() => {
                            return this.state.fields[0].value;
                        }],
                    }
                    ]
                },
            ]
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    };
    handleChange = (value, id) => {
        const fieldIndex = this.state.fields.findIndex(f => {
            return f.id === id;
        });

        let fields = [...this.state.fields];
        fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);

        this.setState({ fields: fields, error: '' });
    };
    handleSubmit = e => {
        e.preventDefault();
        let fields = this.validateForm();
        if (!this.state.issubmited) {
            this.setState({ issubmited: true, });
        }
        if (!Validate.validForm(fields)) {
            return;
        }
        this.setState({
            requesting: true,
            error: '',
        });
        XServices.post('/account/updatePassword', {
            request: this.props.request, password: this.state.fields[0].value
        }, response => {
            this.setState({ error: '' });
        }, err => {
            this.setState({ error: err.message, requesting: false, issubmited: false });
        })

    };
    validateForm = () => {
        const fields = Validate.validateForm(this.state.fields);
        this.setState({ fields: fields });
        return fields;
    };
    render() {
        const { issubmited, fields, requesting, error } = this.state;

        return (
            !requesting ?
                <Fragment>
                    <div className="account-recover-area">
                        <Typography variant="h4">
                            {Lang.get('FORGOT.RECOVER.TITLE')}
                    </Typography>
                        <Typography variant="p">
                            {Lang.get('FORGOT.INGRESS.NEW.PASS')}
                    </Typography>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label for="new-password">{Lang.get('FORGOT.NEW.PASS')}</Label>
                            <InputField
                                handleChangeInput={this.handleChange}
                                inputId="new-password"
                                id="new-password"
                                type="P"
                                valid={fields[0].valid || !issubmited}
                                size="lg" message={fields[0].message}
                                feedback={issubmited}
                                disabled={requesting}
                                value={this.state.fields[0].value} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="new-password-confirm">
                                {Lang.get('FORGOT.REPEAT.PASS')}
                        </Label>
                            <InputField
                                handleChangeInput={this.handleChange}
                                inputId="new-password-confirm"
                                id="new-password-confirm"
                                type="P"
                                valid={fields[1].valid || !issubmited}
                                size="lg" message={fields[1].message}
                                feedback={issubmited}
                                disabled={requesting}
                                value={this.state.fields[1].value} />
                        </FormGroup>
                        {error !== '' &&
                            <Alert color="warning" className="alert-custom" close="none">
                                <div className="alert-message">
                                    <Typography variant="p">{error}</Typography>
                                </div>
                            </Alert>
                        }
                        <FormGroup>
                            <Button className="btn-block" type="submit" size="lg" color="danger">{Lang.get('FORGOT.BUTTON.SAVE')}</Button>
                        </FormGroup>
                    </form>
                </Fragment>
                : 
                <AccountMessages title={Lang.get('ACOOUNT.UPGRADE.SUCESS')} message={""} />
        );
    };
};

export default AccountUpdatePassword;
