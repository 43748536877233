import React, {Fragment} from 'react';

//Ui
import Logo from '../Logo/Logo';
import Typography from '../Typography/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const NewRequestHeader = props => {
    const { data, toggle } = props;

    let regularPrice = null;
    let regularTime = null;
    let plusPrice = null;
    let plusTime = null;
    let plusName = null;

    for (const item of data.prices) {
      if (item.priority === "0" || item.priority === 0) {
        regularPrice = item.price;
        regularTime = item.settings.time;
      }

      if (item.priority === "1" || item.priority === 1) {
        plusTime = item.settings.time;
        plusName = item.name;
        plusPrice = item.price;
      }
    }

    const totalTags = data.tags.length;

    let tags = data.tags.map((item, i) =>
      (<Fragment key={i}><span className="text-tags">{item}</span>&nbsp;{i < totalTags - 1 && <span>-&nbsp;</span>}</Fragment>)
    );

    return (
        <div className="modal-header modal-request-header">
            <div className="modal-request-ticket">
                <Logo variant="icon" size="sm"/>
                <div className="modal-request-title">
                    <Typography variant="h3">{data.name}</Typography>
                    <Typography variant="p">Etiquetas: {tags}</Typography>
                </div>
            </div>
            <div className="modal-request-info">
                <div>
                    <Typography variant="small">Valor:</Typography>
                    <Typography variant="h3">{regularPrice || plusPrice}</Typography>
                </div>
                <div>
                    <Typography variant="small">Tiempo Estimado:</Typography>
                    <Typography variant="h3">{regularTime || plusTime}</Typography>
                </div>
                {plusTime && regularTime && 
                    <div className="modal-request-plus">
                        <Typography variant="small">{plusName}:</Typography>
                        <Typography variant="h3">{plusTime}</Typography>
                    </div>
                }
            </div>
            <div className="close-modal">
                <button onClick={toggle}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
        </div>
    );
};

export default NewRequestHeader;
