import React from 'react';
import Responsive from '../Responsive/Responsive';

//Ui
import FooterMenuItem from '../Footer/FooterMenuItem';
import { Constants } from '../../config/Constants';

const MenuItems = () => {
    return(
        <Responsive mode="tablet" break="down">
            {Constants.HEADER.MENU.map((item, i) => (
                <FooterMenuItem key={'h'+i}
                    type={item.type}
                    link={item.link}
                    text={item.text}
                    icon={item.icon}
                />
            ))}
        </Responsive>
    );
};

export default MenuItems;