/* eslint-disable */
function Cookies() {
    var expires ="";
    this.set = function(name, value, options = {}) {

        var defaults = {
            days: "",
            path: "/"
        };

        options = Object.assign(options, defaults);

        if (options.days !== "") {
            var date = new Date();
            date.setTime(date.getTime() + (options.days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + "=" + (value || "")  + expires + "; path="+options.path;
    }

    this.get = function(name) {
        name = name + "=";
        var allCookieArray = document.cookie.split(';');
        for(var i=0; i<allCookieArray.length; i++)
        {
            var temp = allCookieArray[i].trim();
            if (temp.indexOf(name)==0) {
                return temp.substring(name.length,temp.length);
            }
        }
        return "";
    }

    this.remove = function(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

}

const Cookie = new Cookies();
export default Cookie;