import axios from 'axios';
import qs from 'qs';

/* eslint-disable */
function RestAPI () {

    /** PUBLIC PROPERTIES */
    this.baseURL = 'http://dev.revu.tuxidev.cl:8980/api/';
    this.token = '12f780468aaccee9e4a66b99bd847457';
    this.onBusinessException = null;
    this.onSecurityException = null;
    this.language = 'es';
    this.country = 'cl';

    /** PUBLIC FUNCTIONS */

    this.post = function (url, params, success, fail) {
        return request(this, url, 'post', params, success, fail, true);
    }

    this.get = function (url, params, success, fail) {
        return request(this, url, 'get', params, success, fail);
    }

    this.upload = function (url, params, success, fail, progress) {
        return request(this, url, 'post', params, success, fail, false, progress);
    }

    /** PRIVATE FUNCTIONS */

    function request(that, url, method, params, success, fail, encodeParams, progress) {
        var config = getParams(that, url, method, params, encodeParams);

        if (typeof progress === 'function') {
          config.onUploadProgress = function (progressEvent) {
            progress(progressEvent);
          }
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        config.cancelToken = source.token;

        axios.request(config)
            .then(function (response) {
                processResponse(that, response, success, fail);
            }).catch(function (error) {
                processError(that, error, fail);
            });
        return source;
    }

    function processErrorCode(that, data) {
        var callback = null;

        switch(data.code) {
            case 104:
                callback = that.onSecurityException;
                break;
            case 101:
                callback = that.onBusinessException;
                break;
        }

        if (callback !== null) {
            callback(data);
        }
    }

    function processResponse(that, response, success, fail) {
        var data = response.data;

        if (typeof data.code !== 'undefined') {
            processErrorCode(that, data);

            if (typeof fail === 'function') {
                fail(data);
            }
        } else {
            if (typeof success === 'function') {
                success(data);
            }
        }
    }

    function processError(that, error, callback) {
        if (axios.isCancel(error)) {
          return;
        }
        if (typeof callback === 'function') {
            callback(error);
        }
    }

    function getParams(that, url, method, params, encodeParams) {
        var config = {
            url: url,
            method: method,
            baseURL: that.baseURL,
            responseType: 'json',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'x-language': that.language,
              'x-country': that.country
            }
        }

        var token = typeof that.token === 'function' ? that.token() : '';
        var data = typeof params !== 'undefined' ? params : {};

        if (method === 'get') {
            config.params = data;
            config.params.token = token;
        } else {
            params.token = token;
            config.data = encodeParams ? qs.stringify(params) : params;
        }
        return config;
    }
}

const XServices = new RestAPI();

export default XServices;
