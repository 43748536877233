import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {
    let { data } = props;
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-custom">
                {data !== undefined && data.map((item, i) => (
                    (<li key={i} className={"breadcrumb-item " + (i === data.length -1 ? "active" : "")}>
                        {i < data.length -1 ? 
                        <Link to={item.link}>{i === 0 && <Fragment><FontAwesomeIcon icon={faChevronLeft}/>&nbsp;&nbsp;</Fragment>}{item.text}</Link>
                        : <span>{item.text}</span>}
                    </li>)
                ))}
            </ol>
        </nav>
    );
};

Breadcrumb.propTypes = {
    data: PropTypes.array.isRequired
};
export default Breadcrumb;