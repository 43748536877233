import React, { Component } from 'react';
import XServices from '../../api/XServices';

import Typography from '../Typography/Typography';
import Avatar from '../Avatar/Avatar';
import Rank from '../Rank/Rank';
import CommentInput from '../CommentInput/CommentInput';
import PropTypes from 'prop-types';
import Lang from '../../api/Lang';
import Validate from '../../api/Validate';


class RankModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            message: '',
            ranking: 0,
            error: '',
            errorEnvio: '',
            issubmited: false,
            requesting: false,
            fields: [
                {
                    id: 'rank-comment',
                    value: '',
                    valid: true,
                    message: '',
                    validations: [
                        {
                            "name": "maxlength",
                            "params": [1024]
                        }
                    ]
                }
            ]
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRatingChange = this.handleRatingChange.bind(this);
    };

    handleChange = (value, id) => {
        const fieldIndex = this.state.fields.findIndex(f => {
            return f.id === id;
        });

        let fields = [...this.state.fields];
        fields[fieldIndex] = Validate.validateField(fields[fieldIndex], value);
        this.setState({ fields: fields });
    };

    handleSubmit = e => {
        e.preventDefault();

        let that = this;
        if (that.state.ranking < 5 && that.state.fields[0].value === '') {
            this.setState({ error: Lang.get("COMMENT.PLACEHOLDER") })
            return
        }
        if (that.state.ranking === 0){
            this.setState({ error: Lang.get("COMMENT.RANKREQUIRED") })
            return
        }
        let fields = that.validateForm();

        if (!that.state.issubmited) {
            that.setState({ issubmited: true });
        }

        if (!Validate.validForm(fields)) {
            return;
        }
        that.setState({ requesting: true, error: '' });

        XServices.post('request/rank', {
            rqt_id: that.state.id,
            rnk_rank: that.state.ranking,
            rnk_observation: that.state.fields[0].value
        }, function (response) {
            that.setState({ requesting: false, error: '' });
            that.props.toggle(that.state.id, that.state.ranking);
        }, function (error) {
            that.setState({
                requesting: false,
                error: error.message
            });
        });
    }

    validateForm = () => {
        const fields = Validate.validateForm(this.state.fields);
        this.setState({ fields: fields });
        return fields;
    }

    handleRatingChange(rating) {
        this.setState({
            ranking: rating
        })
    }

    render() {
        const { url, name, id } = this.props;
        const { fields, issubmited, requesting } = this.state;
        return (
            <div className="rankmodal-item">
                <div className="rankmodal-id">
                    <Typography variant="p">{id}</Typography>
                </div>
                <div className="rankmodal-avatar">
                    <Avatar name={name} size="lg" url={url} variant="primary" />
                </div>
                <div className="rankmodal-rank">
                    <Typography variant="p">{Lang.get("RANKMODAL.QUESTION")}<Typography variant="strong">{name}</Typography>?</Typography>
                    <Rank handleRatingChange={this.handleRatingChange} ranking={this.state.ranking} />
                </div>
                <div className="rankmodal-commitinput">
                    <CommentInput
                        url={url}
                        size="md"
                        color_button="success"
                        handleSubmit={this.handleSubmit}
                        handleChange={this.handleChange}
                        error={this.state.error}
                        inputId="rank-comment"
                        id="rank-comment"
                        type="A"
                        valid={fields[0].valid || !issubmited}
                        message={fields[0].message}
                        feedback={issubmited}
                        disabled={requesting}
                        requesting={requesting}
                        fields={fields[0]}
                        indexField={0}
                        placeholder={Lang.get("COMMENT.PLACEHOLDER")}
                    />
                </div>
                <div className="rankmodal-id">
                    {this.state.errorEnvio !== '' && <Typography variant="strong" color="danger">{this.state.errorEnvio}</Typography>}
                </div>
            </div>
        );
    };
};

RankModal.propTypes = {
    url: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    size: PropTypes.string.isRequired
}

export default RankModal;
