import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '../Typography/Typography';

const ButtonIcon = props => {
    const { icon, text, onClick } = props;
    return (
        <div className="biitem">
            <div className="biitem-info">
                <Typography variant="small">{text}</Typography>
            </div>
            <div className="biitem-icon clickeable" onClick={onClick}>
                <FontAwesomeIcon icon={icon}/>
            </div>
        </div>
    );
};

export default ButtonIcon;
