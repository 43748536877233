import React, { Component, Fragment } from "react";
import XServices from "../../api/XServices";
import ErrorCode from "../../api/ErrorCode";

//Components
import Loading from "../../components/Loading/Loading";
import UserInfo from "../../components/UserInfo/UserInfo";
import Status from "../../components/Status/Status";
import TabsNavs from "../../components/Tabs/TabsNav";
import TabsArea from "../../components/Tabs/TabsArea";
import Notify from "../../components/Notify/Notify";
import RequestsInProgress from "../../components/RequestsInProgress/RequestsInProgress";
import TabField from "../../components/Request/TabField";
import TabComments from "../../components/Request/TabComments";
import TabDocument from "../../components/Request/TabDocument";
import TabDisponibility from "../../components/Request/TabDisponibility";
import TabSign from "../../components/Request/TabSign";
import CustomError from "../../components/Error/CustomError";

//Ui
import { Container, Row, Col } from "reactstrap";
import Typography from "../../components/Typography/Typography";
import Lang from "../../api/Lang";

const REFRESH_TIME = 60000;

class Request extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      loading: true,
      data: null,
      error: null,
      tabCommentsActive: false,
      redirect: false,
      newError: {
        code: "",
        message: ""
      },
      ajaxTimeRequest: null,
      timeTimeout: null,
      fieldsChange: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.checkRemainingTime = this.checkRemainingTime.bind(this);
    this.handleRefreshFields = this.handleRefreshFields.bind(this);
    this.handleFieldHasChange = this.handleFieldHasChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ redirect: true })
  }

  handleRefreshFields = codes => {
    if (this.state.activeTab === "1") {
      for (const code of codes) {
        if (code === "RFD" || code === "RFC" || code === "RFE") {
          this.setState({ fieldsChange: true });
        } else {
          this.setState({ fieldsChange: false });
        }
      }
    }
  };

  handleFieldHasChange() {
    this.setState({ fieldsChange: false });
  }

  handleChangeState = response => {
    const newdata = { ...this.state.data };

    newdata.state = response.state;
    newdata.statename = response.statename;

    if (response.readonly !== undefined) {
      newdata.readonly = response.readonly;
    }

    this.setState({ data: newdata });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    if (tab === "2") {
      this.setState({ tabCommentsActive: true });
    } else {
      this.setState({ tabCommentsActive: false });
    }
  }

  componentDidMount() {
    let that = this;
    this.setState({ fieldsChange: false });
    XServices.get(
      "request/info",
      {
        id: this.props.match.params.id
      },
      function (response) {
        response.loadingTimer = true;
        that.setState({ loading: false, data: response, error: null });
        that.checkRemainingTime();
      },
      function (error) {
        that.setState({
          loading: false,
          error: { code: ErrorCode.get(error.code), message: error.message }
        });
      }
    );
  }

  componentWillUnmount() {
    if (this.state.ajaxTimeRequest !== null) {
      this.state.ajaxTimeRequest.cancel();
    }

    if (this.state.timeTimeout !== null) {
      clearTimeout(this.state.timeTimeout);
    }
  }

  getTab(tabName) {
    const { data } = this.state;
    let tabBody;
    switch (tabName) {
      case "FIELD":
        tabBody = {
          id: "1",
          name: "Antecedentes",
          component: (
            <TabField
              id={data.id}
              role={this.state.data.role}
              readonly={data.readonly}
              fieldsChange={this.state.fieldsChange}
              handleFieldHasChange={this.handleFieldHasChange}
              checkRemainingTime={this.checkRemainingTime}
              serverTime={data.server_time}
            />
          )
        };
        break;
      /*case "COMMENT":
        tabBody = {
          id: "2",
          name: "Chat Cliente",
          component: (
            <TabComments
              id={data.id}
              readonly={data.readonly}
              tabCommentsActive={this.state.tabCommentsActive}
            />
          )
        };
        break;*/
      case "DISPONIBILITY": 
        tabBody = {
          id: "4",
          name: "Notaría",
          component: (
            <TabDisponibility id={data.id} handleChangeState={this.handleChangeState}
            readonly={data.readonly}/>
          ),
        };
        break;
      case "SIGN": 
        tabBody = {
          id: "5",
          name: "Firma digital",
          component: (
            <TabSign id={data.id}/>
          ),
        };
        break;
      default:
        tabBody = null;
    }
    return tabBody;
  }

  checkRemainingTime() {
    if (this.state.ajaxTimeRequest !== null) {
      this.state.ajaxTimeRequest.cancel();
    }

    if (this.state.timeTimeout !== null) {
      clearTimeout(this.state.timeTimeout);
    }

    let ids = [this.props.match.params.id];

    let ajaxRequest = XServices.post(
      "request/search/time/remaining",
      {
        requestIds: JSON.stringify(ids)
      },
      response => {
        let newdata = { ...this.state.data };
        let time = response[0];

        newdata.state = time.state;
        newdata.statename = time.statename;
        newdata.readonly = time.readonly;
        newdata.lastmodify = time.lastModifyDate;
        newdata.timeRemaining = time.textRemainingTime;
        newdata.minutesRemaining = time.totalRemainingMinutes;
        newdata.timeAdditional = time.textAdditionalTime;
        newdata.revisionText = time.revisionText;
        newdata.textWarrantyTime = time.textWarrantyTime;
        newdata.hasWarranty = time.hasWarranty;
        newdata.isInTimeWarranty = time.isInTimeWarranty;
        newdata.hasAdditionalTime = time.hasAdditionalTime;
        newdata.loadingTimer = false;

        const timeout = setTimeout(this.checkRemainingTime, REFRESH_TIME);
        this.setState({
          data: newdata,
          ajaxTimeRequest: null,
          timeTimeout: timeout
        });
      },
      error => { }
    );

    this.setState({ ajaxTimeRequest: ajaxRequest });
  }

  isVisibleWidget(data, tab) {
    return data.indexOf(tab) !== -1;
  }
  render() {
    
    const { activeTab, loading, data, error } = this.state;
    // TODO: REPLACE TEXT WITH ELEMENTS
    let tabItems = [];

    if (!loading && error === null) {
      for (const tab of data.tabs) {
        const content = this.getTab(tab);
        if (content !== null) {
          tabItems.push(content);
        }
      }
    }
    return (
      <Fragment>
        {loading ? (
          <div className="margin-top min-height d-flex flex-column  align-items-center justify-content-center">
            <div>
              <Loading
                variant="default"
                color="danger"
                style={{ width: "3rem", height: "3rem" }}
              />
            </div>
            <div className="mt-2">
              <Typography variant="p">{Lang.get('REQUEST.LOADING', [this.props.match.params.id])}</Typography>
            </div>
          </div>
        ) : error !== null ? (
          <CustomError code={error.code} message={error.message} />
        ) : (
              <Fragment>
                <UserInfo
                    stage={data.stage}
                    data={data.user}
                    slug={data.slug}
                    viewrole={data.role}
                    redirect={data.role === "RQT"}
                />
                <section className="wrapper-area">
                  <Container>
                    <Row>
                      <Col>
                        <Status data={data} id={data.id} type={data.type} role={data.role} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TabsNavs
                          active={activeTab}
                          handleClick={this.toggleTab}
                          items={tabItems}
                        />
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs="12" sm="12" md="6">
                        <TabsArea active={activeTab} items={tabItems} />
                      </Col>
                      <Col xs="12" sm="12" md="6">
                        {this.isVisibleWidget(data.tabs, 'DOCUMENT') && <TabDocument
                          id={data.id}
                          handleChangeState={this.handleChangeState}
                          readonly={data.readonly}
                          role={data.role}
                          state={data.state}
                          revisionText={data.revisionText}
                          hasWarranty={data.hasWarranty}
                          isInTimeWarranty={data.isInTimeWarranty}
                          isWidget={true}
                        />}
                        {data.role === "WKR" && (
                          <RequestsInProgress
                            requestId={this.props.match.params.id}
                          />
                        )}
                        <Notify
                          requestId={this.props.match.params.id}
                          handleRefreshFields={this.handleRefreshFields}
                        />
                        <TabComments
                          role={data.role}
                          id={data.id}
                          readonly={data.readonly}
                          tabCommentsActive={this.state.tabCommentsActive}
                          state={data.state}
                        />
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Fragment>
            )}
      </Fragment>
    );
  }
}

export default Request;
