import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import XServices from '../../api/XServices';
import Lang from '../../api/Lang';

//Ui
import Logo from '../../components/Logo/Logo';
import { Button} from 'reactstrap';
import Typography from '../../components/Typography/Typography';
import AccountMessages from '../Account/AccountMessages';
import Loading from '../../components/Loading/Loading';

class RequestUnsubscribe extends Component {
    constructor(){
        super();
        this.state = {
            requestId: null,
            loading: false,
            error: false,
            unsubscribed: false,
            isCanceled: false,
        }
        this.handleUnsubscribe = this.handleUnsubscribe.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    };

    componentDidMount(){
        this.setState({loading: true});

        XServices.get('/request/unsubscribe/verify', {
            tokenSubscription: this.props.match.params.token
        }, response => {
            this.setState({
                requestId: response.requestId,
                loading: false
            })
        }, err => {
            this.setState({error: true, loading: false});
        });
    }
   
    /**
     * Unsubscribes the user from the given request
     */
    handleUnsubscribe() {

        this.setState({loading: true});
        XServices.post('/request/unsubscribe', {
            tokenSubscription: this.props.match.params.token
        }, response => {
            this.setState({unsubscribed: true, error: false, loading: false});
        }, err => {
            this.setState({error: true, loading: false});
        });
    }

    /**
     * Redirects home after clicking cancel
     */
    handleCancel() {
        this.setState({isCanceled: true});
    }

    render(){
        const { loading, error, requestId, unsubscribed, isCanceled } = this.state;
        return(
        <div className="account-unsubscribe">
            <div className="account-recover-logo">
                <Logo variant="full"/>
            </div>
            {loading ? <Loading variant="component" color="black"/> :
            !error ?
            isCanceled ?
            <AccountMessages title={Lang.get('REQUEST.UNSUBSCRIBE.CANCELED')} message={Lang.get("REQUEST.UNSUBSCRIBE.CANCELEDEXTRA")}/> :
            unsubscribed ?
            <AccountMessages title={Lang.get('REQUEST.UNSUBSCRIBE.SUCCESS')} message={Lang.get("REQUEST.UNSUBSCRIBE.SUCCESSEXTRA")}/> :
            <div className="account-unsunscribe-content">
                <Typography variant="h5">{Lang.get('REQUEST.UNSUBSCRIBE.CONFIRMATION', [requestId])}</Typography>
                <div className="buttons-row">
                    <Button onClick={this.handleUnsubscribe} color="danger">Confirmar</Button>
                    <Button onClick={this.handleCancel} color="secondary">Cancelar</Button>
                </div>
            </div> :
            <AccountMessages title={Lang.get("REQUEST.UNSUBSCRIBE.INVALIDTOKEN")} message={Lang.get("REQUEST.UNSUBSCRIBE.TRYAGAIN")}/>
            }
        </div>
        );
    };
};

export default withRouter(RequestUnsubscribe);