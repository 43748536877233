import React from 'react';

import LawyerNotificationsItem from '../../components/LawyerNotificationsItem/LawyerNotificationsItem';
import PaginationMod from '../../components/Pagination/Pagination';
import PropTypes from 'prop-types';

const ListNotifications = props => {
    const { items, numberOfItems, onChangePage, initialPage, currentPage, pageSize } = props;
    return (
        <div className="maar-list">
            {items.map(item =>
                <LawyerNotificationsItem
                    url={item.imageUrl}
                    entity={ item.type === 'RAS' ? 'Sistema' : item.name}
                    time_remaining={item.createDate}
                    content={item.description}
                    requiresAttention={item.requiresAttention}
                    key={item.id}
                    id={item.id}
                    requestId={item.request_id}
                    type={item.type}
                />
            )}
            <PaginationMod
                onChangePage={onChangePage}
                numberOfItems={numberOfItems}
                initialPage={initialPage}
                currentPage={currentPage}
                pageSize={pageSize}
            />

        </div>
    );
};

ListNotifications.propTypes = {
    status: PropTypes.string,
    numberOfItems: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    alert_success: PropTypes.bool
};

export default ListNotifications;
