import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'reactstrap';
import Typography from '../Typography/Typography';
import Lang from "../../api/Lang";

const Loading = props => {
    const { variant, color, style } = props;

    const fullsize = <div className="loading-fullscreen">
        <Spinner color="danger"/>
        <Typography variant="p">{Lang.get('COMMON.LOADING_PLEASE_WAIT')}</Typography>
    </div>;

    const component = <div className="loading-component">
        <Spinner color={color} style={style}/>
    </div>;

    switch(variant){
        case 'button':
            return <Spinner size="sm" color={color} style={style}/>;
        case 'component':
            return component;
        case 'fullscreen':
            return fullsize;
        default:
            return <Spinner size="sm" style={style} color={color}/>;
    };
};

Loading.propTypes = {
    variant: PropTypes.oneOf(["button", "component", "fullscreen", "default"]).isRequired,
};
export default Loading;
