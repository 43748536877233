import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import Typography from '../Typography/Typography';

class CustomAlert extends Component {
    constructor(props) {
    super(props);
        this.state = {
            visible: true
        };
        this.onDismiss = this.onDismiss.bind(this);
    };
    onDismiss() {
        this.setState({ visible: false });
        if (this.props.onDismiss !== undefined) {
          this.props.onDismiss(this.props.id);
        }
    };
    render(){
        const {type, message, close, asHTML} = this.props;
        return (
            <Alert color={type} className="alert-custom" isOpen={this.state.visible} 
                toggle={close === 'none' ? null : this.onDismiss}>
                <div className="alert-message">
                    <Typography variant="p" asHTML={asHTML}>{message}</Typography>
                </div>
            </Alert>
        );
    };
};

CustomAlert.propTypes = {
    type:  PropTypes.oneOf(["danger", "success", "info"]).isRequired,
    close: PropTypes.oneOf(["none"]),
    message: PropTypes.string.isRequired,
    asHTML: PropTypes.bool
}

export default CustomAlert;
