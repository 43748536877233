import React, { Component } from "react";
import XServices from "../../api/XServices";

import { Button } from "reactstrap";

import NotifyItem from "../NotifyItem/NotifyItem";
import Typography from "../../components/Typography/Typography";
import Lang from "../../api/Lang";
import { AuthContext } from "../../config/AuthContext";

const REFRESH_TIME = 30000;

class Notify extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      itemsNews: [],
      ajaxRequest: null,
      timeout: null,
      lastNotificationId: null,
      firstNotificationId: null,
      hasMore: false
    };
    this.loadNotificationNews = this.loadNotificationNews.bind(this);
    this.loadNotificationOlds = this.loadNotificationOlds.bind(this);
    this.markNotificationAsView = this.markNotificationAsView.bind(this);
    this.handleMergeNewsAndOlds = this.handleMergeNewsAndOlds.bind(this);
  }

  componentDidMount() {
    this.loadNotificationOlds();
  }

  componentWillUnmount() {
    if (this.state.ajaxRequest !== null) {
      this.state.ajaxRequest.cancel();
    }

    if (this.state.timeout !== null) {
      clearTimeout(this.state.timeout);
    }
  }

  handleMergeNewsAndOlds() {
    let that = this;
    const merge = [...that.state.items];
    if (that.state.itemsNews.length > 0) {
      that.state.itemsNews.map((item,index) => {
        merge.splice(0, 0, item);
      })
      merge.sort((a, b) => parseFloat(b.id) - parseFloat(a.id) );
    };
    that.setState({
      items: merge,
      itemsNews: [],
      firstNotificationId:
        merge.length > 0
          ? merge[0].id
          : that.state.firstNotificationId
    });
    that.markNotificationAsView();

  }

  loadNotificationNews() {
    let that = this;

    if (that.state.ajaxRequest !== null) {
      that.state.ajaxRequest.cancel();
    }

    if (that.state.timeout !== null) {
      clearTimeout(that.state.timeout);
    }

    const ajaxRequest = XServices.get(
      "request/notifications/news",
      {
        requestId: that.props.requestId,
        notificationId: that.state.firstNotificationId
      },
      function (response) {
        const timeout = setTimeout(that.loadNotificationNews, REFRESH_TIME);

        let arrayTypes = [];
        if ((response.length > 0)) {
          for (const item of response) {
            if (item.type === "RFD" || item.type === "RFC" || item.type === "RFE") {
              arrayTypes.push(item.type);
            }
          }

          that.setState({
            itemsNews: response,
            ajaxRequest: null,
            timeout: timeout
          });

          arrayTypes.length > 0 && that.props.handleRefreshFields(arrayTypes);
        }
      },
      function (error) {
        that.setState({
          error: error.message
        });
      }
    );

    that.setState({ ajaxRequest: ajaxRequest, timeout: null });
  }

  markNotificationAsView() {
    let that = this;
    let ids = [];
    if (that.state.itemsNews.length > 0){
      that.state.itemsNews.map(item => ids.push(item.id.toString()));
    }else{
      that.state.items.map(item => ids.push(item.id.toString()));
    }
    if (ids.length > 0) {
      XServices.post(
        "request/notification/view",
        {
          requestId: that.props.requestId,
          listId: ids
        },
        function (response) { },
        function (error) {
          that.setState({
            error: error.message
          });
        }
      );
    }
  }

  loadNotificationOlds() {
    let that = this;

    XServices.get(
      "request/notifications/olds",
      {
        requestId: that.props.requestId,
        notificationId: that.state.lastNotificationId
      },
      function (response) {
        const timeout = setTimeout(that.loadNotificationNews, REFRESH_TIME);

        var newsAndOlds = [];
        const actual = [...that.state.items];
        newsAndOlds = actual.concat(response.data);
        newsAndOlds.sort((a, b) => parseFloat(b.id) - parseFloat(a.id) );
        that.setState({
          items: newsAndOlds,
          hasMore: response.data.length > 0 ? response.hasMore : that.state.hasMore,
          firstNotificationId:
            response.data.length > 0 && that.state.firstNotificationId === null
              ? newsAndOlds[0].id
              : that.state.firstNotificationId,
          lastNotificationId:
            response.data.length > 0
              ? newsAndOlds[newsAndOlds.length - 1].id
              : that.state.lastNotificationId,
        });
        that.markNotificationAsView();
      },
      function (error) {
        that.setState({
          error: error.message
        });
      }
    );
  }

  render() {
    return (
      <div className="notify-main">
        <Typography variant="p">
          <Typography variant="strong">{Lang.get("NOTIFY.TITLE")}</Typography>
        </Typography>
        {this.state.itemsNews.length > 0 &&
          <div className="notify">
            <Button
              color="link"
              size="sm"
              onClick={this.handleMergeNewsAndOlds}
              id="button-more"
            >
              {Lang.get("NOTIFICATION.YOUHAVE")} {this.state.itemsNews.length} {this.state.itemsNews.length === 1 ? Lang.get("NOTIFICATION.NOTIFICATION") : Lang.get("NOTIFICATION.NOTIFICATIONS")}
            </Button>
          </div>
        }
        {this.state.items.length > 0 ? (
          <div>
            {this.state.items.map((item, index) => (
              <NotifyItem
                title={Lang.get("NOTIFICATION.TITLE", [
                  Lang.get("NOTIFICATION.TYPE." + item.type),
                  item.id
                ])}
                message={item.description}
                key={index}
              />
            ))}
            {this.state.hasMore === true &&
              <div className="notify-buttonarea">
                <Button
                  color="link"
                  size="sm"
                  onClick={this.loadNotificationOlds}
                  id="button-more"
                >
                  {Lang.get("NOTIFY.MORE")}
                </Button>
              </div>
            }
          </div>
        ) : (
            <div className="alert alert-warning">
              <Typography variant="p">
                  {Lang.get("NOTIFY.NOFOUND")}
              </Typography>
            </div>
          )}
      </div>
    );
  }
}

Notify.contextType = AuthContext;

export default Notify;
