import React, {Component, Fragment} from 'react';

import {Label, Input, FormFeedback} from 'reactstrap';
import Format from '../../api/Format';

class InputField extends Component {

  formatType = null;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleFormat = this.handleFormat.bind(this);


    if (this.props.type === 'R') {
      this.formatType = 'rut';
    } else if (this.props.additional !== undefined && this.props.additional.format !== undefined) {
      this.formatType = this.props.additional.format;
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : this.handleFormat(target.value);

    this.props.handleChangeInput(value, this.props.id);
  }

  handleFormat(value) {
    return this.formatType !== null ? Format.doFormat(this.formatType, value) : value;
  }

  render() {
    const {value, inputId, type, additional, valid, size, message, feedback, disabled, placeholder, noEmpty} = this.props;
    let isValid = valid !== undefined ? valid : true;
    let isDisabled = disabled !== undefined ? disabled : false;
    let field = null;
    let allowEmpty = noEmpty !== undefined ? noEmpty : true;
    switch (type) {
      case "D":
        field = (<Input type="date" invalid={Boolean(!isValid)} id={inputId} name={inputId} bsSize={size} onChange={this.handleChange} disabled={isDisabled}
        placeholder="dd-mm-aaaa"
        value={this.props.value}
        />);
        break;
      case "C":
        field = (<Label check>
            <Input invalid={Boolean(!isValid)} type="checkbox" id={inputId} name={inputId} bsSize={size} onChange={this.handleChange} disabled={isDisabled}/> {additional.text}
            {feedback && <FormFeedback invalid={!isValid ? "invalid": null}>{message}</FormFeedback>}
          </Label>);
        break;
      case "L":
        field = (<Input
          invalid={Boolean(!isValid)}
          type="select"
          id={inputId}
          name={inputId}
          bsSize={size}
          onChange={this.handleChange}
          defaultValue={additional.default}
          value={this.props.value}
          disabled={isDisabled}
          multiple={Boolean(additional.selection === "multiple")}>
          {additional.selection !== "multiple" && allowEmpty && <option value="">Seleccione</option>}
          {additional.options.map((item,i) =>
            <option key={i} value={item.value}>{item.key}</option>
          )}
        </Input>);
        break;
      case "A":
        field = (<Input
          autoComplete="off"
          invalid={Boolean(!isValid)}
          type="textarea"
          id={inputId}
          name={inputId}
          bsSize={size}
          onChange={this.handleChange}
          disabled={isDisabled}
          value={value}
          placeholder={placeholder}>
        </Input>);
        break;
      case "P":
        field = (<Input autoComplete="off" value={this.props.value} invalid={Boolean(!isValid)} id={inputId} name={inputId} bsSize={size} onChange={this.handleChange} disabled={isDisabled} type="password"/>);
        break;
      default:
        field = (<Input autoComplete="off" value={this.props.value} invalid={Boolean(!isValid)} id={inputId} name={inputId} bsSize={size} onChange={this.handleChange} disabled={isDisabled}/>);
    }

    return (
      <Fragment>
        {field}
        {feedback && type !== 'C'  && <FormFeedback invalid={!isValid ? "invalid": null}>{message}</FormFeedback>}
      </Fragment>
    );
  }
};

export default InputField;
