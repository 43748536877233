import React, { Fragment, Component } from "react";
import XServices from "../../api/XServices";

import { Alert, FormGroup, Label } from "reactstrap";

import Typography from "../../components/Typography/Typography";
import HelpTooltip from '../HelpTooltip/HelpTooltip';
import InputField from '../InputField/InputField';
import Validate from '../../api/Validate';
import Lang from "../../api/Lang";
import CustomAlert from "../Alert/CustomAlert";

let empty = { "selection": "single", "validations": ["required"], "options": [], "default": "" };

class Notaries extends Component {
    constructor() {
        super();
        this.state = {
            regions: empty,
            communes: empty,
            notaries: null,
            idNotary: '',
            loading: true,
            issubmited: false,
            titleParent: '',
            titleChild: '',
            error: false
        };
        this.handleChangeNotaries = this.handleChangeNotaries.bind(this);
        this.handleLoadRegions = this.handleLoadRegions.bind(this);
        this.handleLoadCommunes = this.handleLoadCommunes.bind(this);
        this.handleLoadNotaries = this.handleLoadNotaries.bind(this);
        this.getNameLevel = this.getNameLevel.bind(this);
    }

    componentDidMount() {
        this.getNameLevel(1);
        this.getNameLevel(2);
        this.handleCreateDataFieldsNotaries();
        this.handleLoadRegions();
    }

    handleCreateDataFieldsNotaries() {
        let newFields = [
            {
                id: "regions", type: "L", mandatory: true, order: 1, title: this.state.titleParent, message: '', additional: {
                    selection: "single", options: [],
                    validations: ["required"]
                }, tooltip: { title: Lang.get("REQUEST.FIELD.REGION.TITLE"), description: Lang.get("REQUEST.FIELD.REGION.DESCRIPTION") }
            },
            {
                id: "communes", type: "L", mandatory: true, order: 2, title: this.state.titleChild, message: '', additional: {
                    selection: "single", options: [],
                    validations: ["required"]
                }, tooltip: { title: Lang.get("REQUEST.FIELD.COMUNES.TITLE"), description: Lang.get("REQUEST.FIELD.COMUNES.DESCRIPTION") }
            },
            {
                id: "notaries", type: "L", mandatory: true, order: 3, title: Lang.get('NOTARIES.NOTARIES'), message: '', additional: {
                    selection: "single", options: [],
                    validations: ["required"]
                }, tooltip: { title: Lang.get("REQUEST.FIELD.NOTARIES.TITLE"), description: Lang.get("REQUEST.FIELD.NOTARIES.DESCRIPTION") }
            }
        ];
        this.setState({ fields: newFields });
        this.props.setStateNotary(newFields);
    }

    popularData(value, id) {
        const fieldIndex = id;

        const field = { ...this.props.notariesFields[fieldIndex] };
        field.value = value;
        field.message = Validate.validate(field.value, field.additional.validations);
        field.valid = field.message === "";

        const fields = [...this.props.notariesFields];
        fields[fieldIndex] = field;

        this.props.setStateNotary(fields);

    }

    handleLoadRegions() {
        XServices.get('location/parent', {
        }, (response) => {
            let regions = {};
            let options = [];
            response.map(item => {
                options.push({ "key": item.name, "value": item.id });
                return null;
            });
            regions.seleccion = "single";
            regions.validations = ["required"];
            regions.options = options;
            this.setState({ regions: regions, loading: false, error: false });
        }, (error) => {
            this.setState({ loading: false, error: true });
        });
    }

    handleLoadCommunes(idRegion) {
        if (idRegion !== '') {
            this.setState({ communes: empty, notaries: null});
            XServices.get('location/child', {
                parent: idRegion
            }, (response) => {
                let communes = {};
                let options = [];
                response.map(item => {
                    options.push({ "key": item.name, "value": item.id });
                    return null;
                });
                communes.seleccion = "single";
                communes.validations = ["required"];
                communes.options = options;
                this.setState({ communes: communes, idNotary: '', notaries: null, loading: false, error: false });
                this.popularData(idRegion, 0);
            }, (error) => {
                this.setState({ loading: false, error: true });
            });
        } else {
            this.popularData("", 0);
        }
    }

    handleLoadNotaries(idCommune) {
        if (idCommune !== '') {
            XServices.get('location/notaries', {
                id: idCommune
            }, (response) => {
                let notaries = {};
                let options = [];
                response.map(item => {
                    options.push({ "key": item.fullName, "value": item.id });
                    return null;
                });
                notaries.seleccion = "single";
                notaries.validations = ["required"];
                notaries.options = options;
                this.setState({ notaries: notaries, idNotary: '', valueCommunes: idCommune, loading: false, error: false });
                this.popularData(idCommune, 1);
            }, (error) => {
                this.setState({ loading: false, error: true });
            });
        } else {
            this.setState({ idNotary: '', valueCommunes: '' });
            this.popularData("", 1)
        }
    }

    getNameLevel(level) {
        XServices.get('location/level/name', {
            level: level
        }, (response) => {
            if (level === 1) {
                this.setState({ titleParent: response.name, error: false });
            } else if (level === 2) {
                this.setState({ titleChild: response.name, error: false });
            }
        }, (error) => {
            this.setState({ error: true });
        });
    }

    handleChangeNotaries(value) {
        if (value !== '') {
            this.setState({ idNotary: value });
            this.props.handleSendIdNotary(value);
            this.popularData(value, 2)
        } else {
            this.setState({ idNotary: '' });
            this.popularData('', 2)
        }
    }

    render() {
        const {  loading, titleParent, titleChild, regions, communes, notaries, error } = this.state;
        const {notariesFields} = this.props;
        return (
            <Fragment>
                {!loading && (
                    <div>
                        <Typography variant="p">
                            <Typography variant="strong">{Lang.get('NOTARIES.NOTARY')}&nbsp;</Typography>
                            {Lang.get('NOTARIES.TITLE')}
                        </Typography>
                        <FormGroup>
                            <Label for='regions'>{titleParent}{notariesFields[0].mandatory ? (<Typography color="danger">&nbsp;*</Typography>) : null}</Label>
                            <div className="btn-tooltip action-control">
                                <HelpTooltip id="10" title={notariesFields[0].tooltip.title} description={notariesFields[0].tooltip.description} />
                            </div>
                            <InputField
                                inputId='regions'
                                id='regions'
                                type='L'
                                additional={regions}
                                mandatory={notariesFields[0].mandatory}
                                handleChangeInput={this.handleLoadCommunes}
                                feedback={true}
                                valid={notariesFields[0].valid}
                                message={notariesFields[0].message}
                                disabled={error}
                            />
                        </FormGroup>
                        {communes.options.length > 0 && <FormGroup>
                            <Label for='communes'>{titleChild}{notariesFields[1].mandatory ? (<Typography color="danger">&nbsp;*</Typography>) : null}</Label>
                            <div className="btn-tooltip action-control">
                                <HelpTooltip id="11" title={notariesFields[1].tooltip.title} description={notariesFields[0].tooltip.description} />
                            </div>
                            <InputField
                                inputId='communes'
                                id='communes'
                                type='L'
                                additional={communes}
                                mandatory={notariesFields[1].mandatory}
                                handleChangeInput={this.handleLoadNotaries}
                                feedback={true}
                                valid={notariesFields[1].valid}
                                message={notariesFields[1].message}
                                disabled={error}
                            />
                        </FormGroup>}
                        {notaries !== null && (notaries.options.length > 0 ? <FormGroup>
                            <Label for='notaries'>Notarias{notariesFields[2].mandatory ? (<Typography color="danger">&nbsp;*</Typography>) : null}</Label>
                            <div className="btn-tooltip action-control">
                                <HelpTooltip id="12" title={notariesFields[2].tooltip.title} description={notariesFields[2].tooltip.description}/>
                            </div>
                            <InputField
                                inputId='notaries'
                                id='notaries'
                                type='L'
                                additional={notaries}
                                mandatory={notariesFields[2].mandatory}
                                handleChangeInput={this.handleChangeNotaries}
                                feedback={true}
                                valid={notariesFields[2].valid}
                                message={notariesFields[2].message}
                                disabled={error}
                            />
                        </FormGroup>: 
                        <Alert color='danger' className="alert-form">
                            <Typography variant="p">{Lang.get('NOTARIES.EMPTY')}</Typography>
                        </Alert>)}
                    </div>
                )}
            </Fragment>
        );
    }
}

export default Notaries;