import React, {Component} from 'react';

import MyAccountAllRequest from './MyAccountAllRequest';

class AllRequestAssigned extends Component { 
    render(){
        return <MyAccountAllRequest status="assigned"/>;
    }
}

export default AllRequestAssigned;