import React, { Component } from "react";
import XServices from "../../api/XServices";

import Alert from "../Alert/Alert";
import Typography from "../../components/Typography/Typography";
import Lang from "../../api/Lang";

class RequestsInProgress extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      redirect: false,
      id: null
    };
    this.loadRequests = this.loadRequests.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.loadRequests();
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      const url = "/" + Lang.lancode + "/request/" + this.state.id;
      window.location = url;
      return null;
    }
  };

  handleClick = id => {
    this.setState({ redirect: true, id: id });
  };

  loadRequests() {
    let that = this;
    XServices.get(
      "request/events",
      {
        requestId: that.props.requestId
      },
      function(response) {
        that.setState({
          items: response
        });
      },
      function(error) {
        that.setState({
          error: error.message
        });
      }
    );
  }

  render() {
    return (
      <div className="alert-main">
        {this.renderRedirect()}
        <Typography variant="p">
          <Typography variant="strong">
            {Lang.get("REQUESTSINPROGRESS.TITLE")}
          </Typography>
        </Typography>
        {this.state.items.length > 0 ? (
          this.state.items.map((item, index) => (
            <div
              onClick={() => this.handleClick(item.requestId)}
              id={item.requestId}
              key={index}
            >
              <Alert
                id={item.requestId.toString()}
                name={item.taskName}
                date={item.createDate}
                requiere={item.requiresAttention === "S" ? true : false}
                waitingForClient={item.waitingForClient === "S" ? true : false}
              />
            </div>
          ))
        ) : (
          <div className="alert-main no-alert">
            <Typography variant="p">
                {Lang.get("REQUESTSINPROGRESS.NOFOUND")}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

export default RequestsInProgress;